<template>
  <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_473_21642)">
      <path
        d="M0.487032 9.55448C0.798416 9.98666 1.05141 10.4581 1.22657 10.9885C1.40172 11.4993 1.49903 12.0493 1.49903 12.6387C1.49903 13.228 1.40172 13.778 1.22657 14.2888C1.07088 14.7996 0.817877 15.2907 0.487032 15.7229C0.0588796 16.3122 0.175648 17.1373 0.759493 17.5694C1.34334 18.0016 2.16072 17.8837 2.58887 17.2944C3.05595 16.6461 3.44518 15.9193 3.71764 15.1335C3.97064 14.3477 4.10687 13.503 4.10687 12.6387C4.10687 11.7743 3.97064 10.9296 3.71764 10.1438C3.44518 9.35803 3.07541 8.63119 2.58887 7.98292C2.16072 7.39358 1.34334 7.27572 0.759493 7.7079C0.175648 8.14008 0.0588796 8.96514 0.487032 9.55448Z"
        fill="white"
      />
      <path
        d="M4.96318 7.0794C5.95572 8.69024 6.5201 10.5761 6.5201 12.6191C6.5201 14.6622 5.95572 16.548 4.96318 18.1589C4.59341 18.7875 4.78803 19.5929 5.39133 19.9662C6.0141 20.3394 6.81202 20.1626 7.20125 19.534C8.42732 17.5303 9.1474 15.1533 9.1474 12.6191C9.1474 10.085 8.44679 7.70802 7.20125 5.70428C6.81202 5.07566 6.0141 4.89886 5.39133 5.2721C4.78803 5.64535 4.59341 6.47042 4.96318 7.0794Z"
        fill="white"
      />
      <path
        d="M9.7312 4.58439C11.0935 6.94173 11.8914 9.69196 11.8914 12.619C11.8914 15.546 11.113 18.2963 9.7312 20.6536C9.36143 21.2822 9.57551 22.0876 10.1983 22.4609C10.821 22.8341 11.619 22.618 11.9887 21.9894C13.5846 19.2392 14.4993 16.0371 14.4993 12.619C14.4993 9.20085 13.5846 5.9988 11.9887 3.24857C11.619 2.61994 10.821 2.40385 10.1983 2.7771C9.57551 3.15034 9.36143 3.95577 9.7312 4.58439Z"
        fill="white"
      />
      <path
        d="M14.4604 2.05022C16.2119 5.1737 17.2239 8.76864 17.2239 12.5993C17.2239 16.4496 16.2119 20.0446 14.4604 23.1484C14.1101 23.777 14.3241 24.5825 14.9469 24.9361C15.5697 25.2897 16.3676 25.0736 16.7374 24.4449C18.703 20.9482 19.8317 16.9015 19.8317 12.5797C19.8317 8.25788 18.703 4.21112 16.7374 0.714396C16.3871 0.0857724 15.5697 -0.149962 14.9469 0.223284C14.3047 0.576885 14.0906 1.38231 14.4604 2.03058"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_473_21642">
        <rect
          width="19.6172"
          height="25.0664"
          fill="white"
          transform="translate(0.234039 0.0859375)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
