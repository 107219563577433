<script setup lang="ts">
import { watchEffect } from 'vue';
import { useUiStore } from '@/store/ui.store';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';

const uiState = useUiStore();
const breakpoints = useBreakpoints(breakpointsTailwind);
const isMdOrUp = breakpoints.greaterOrEqual('md');

watchEffect(() => {
  if (uiState.sideNavOpen && isMdOrUp.value) {
    uiState.toggleSideNavOpen();
  }
});
</script>

<template>
  <main
    class="px-4 transition-all"
    :class="{
      'md:ml-24': uiState.sideNavCollapsed,
      'md:ml-60': !uiState.sideNavCollapsed
    }"
  >
    <slot />
  </main>
</template>
