<template>
  <svg width="73" height="44" viewBox="0 0 73 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="MC-mark">
      <path
        id="Fill 14"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.9619 38.6568H46.1029V4.84961H24.9619V38.6568Z"
        fill="#FF5F00"
      />
      <path
        id="Fill 16"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.1445 21.7513C27.1445 14.8925 30.4241 8.78488 35.5321 4.84765C31.7972 1.96835 27.0833 0.251953 21.9599 0.251953C9.83174 0.251953 0 9.87689 0 21.7513C0 33.6257 9.83174 43.252 21.9599 43.252C27.0833 43.252 31.7972 41.5342 35.5321 38.6549C30.4241 34.7177 27.1445 28.6101 27.1445 21.7513Z"
        fill="#EB001B"
      />
      <path
        id="Fill 17"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M73.0004 36.9835V34.2402H72.4621L71.8431 36.1273L71.2241 34.2402H70.6892V36.9835H71.066V34.9126L71.648 36.6966H72.0416L72.6202 34.9081V36.9835H73.0004ZM69.5957 36.9837V34.7067H70.2854V34.245H68.5293V34.7067H69.2189V36.9837H69.5957Z"
        fill="#F79E1B"
      />
      <path
        id="Fill 18"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.0658 21.7513C71.0658 33.6257 61.234 43.252 49.1059 43.252C43.9825 43.252 39.2686 41.5342 35.5322 38.6549C40.6403 34.7177 43.9212 28.6101 43.9212 21.7513C43.9212 14.8925 40.6403 8.78488 35.5322 4.84765C39.2686 1.96835 43.9825 0.251953 49.1059 0.251953C61.234 0.251953 71.0658 9.87689 71.0658 21.7513Z"
        fill="#F79E1B"
      />
    </g>
  </svg>
</template>
