<script setup lang="ts">
import { useTimeout } from '@vueuse/core';
import { computed, ref } from 'vue';

defineOptions({
  inheritAttrs: false
});

const props = defineProps<{
  name: string;
  autofocus?: boolean;
  modelValue: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'write', element: HTMLInputElement): void;
  (e: 'delete', element: HTMLInputElement): void;
}>();

const inputRef = ref<HTMLInputElement | null>();

const { ready: inputVisible, start: startVisibleTimeout } = useTimeout(700, {
  controls: true,
  immediate: false
});

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
    if (value) {
      emit('write', inputRef.value as HTMLInputElement);
    }
  }
});

function handleKeydown(e: KeyboardEvent) {
  // Allowing only digits and e.key === 'Backspace' || e.key === 'Delete'
  const { key } = e;
  const target = e.target as { value?: string; select?: () => void };
  const v = target.value;
  if (key === 'Backspace' || key === 'Delete') {
    target?.select?.();
    // Emit if field had no value before keystroke
    if (!v) {
      emit('delete', target as HTMLInputElement);
    }
    // Don't type value if non numeric key was pressed. Preserve special keys behaviour
  } else if (!/^\d{0,1}$/.test(key) && key.length <= 1) {
    return e.preventDefault();
  }
  startVisibleTimeout();
}

function handleFocus(e: FocusEvent) {
  const target = e.target as { select?: () => void };
  target?.select?.();
}

// Exposing ref so it can be accessed from parent
defineExpose({
  inputRef
});
</script>

<template>
  <div class="relative w-fit my-1 mr-2 sm:mr-6 last:mr-0">
    <input
      v-bind="$attrs"
      :name="name"
      class="flex rounded-xl h-16 w-16 bg-charcoal-900 outline-1 outline-white text-xl text-center text-charcoal-200 font-semibold transition-all"
      :class="{ 'text-transparent': inputVisible }"
      maxlength="1"
      ref="inputRef"
      :autofocus="autofocus"
      v-model="value"
      @keydown="handleKeydown"
      @focus="handleFocus"
    />

    <!-- Circles -->
    <div
      class="absolute top-0 w-full h-full flex justify-center items-center pointer-events-none transition-all"
    >
      <div
        class="h-3 w-3 rounded-full bg-primary-purple-100"
        :class="{ 'bg-transparent': !inputVisible || !value }"
      />
    </div>
  </div>
</template>
