import { defineStore } from 'pinia';

export interface UiState {
  sideNavOpen: boolean;
  sideNavCollapsed: boolean;
  onboardingShow?: boolean;
  marketingBarVisible: boolean;
}

export const useUiStore = defineStore<
  'ui',
  UiState,
  {},
  {
    toggleSideNavCollapsed: () => void;
    toggleSideNavOpen: (value?: boolean) => void;
    toggleMarketingBarVisible: () => void;
    setOnboardingShow: (isShow: boolean) => void;
  }
>('ui', {
  state() {
    return {
      sideNavCollapsed: false,
      sideNavOpen: false,
      onboardingShow: undefined,
      marketingBarVisible: true
    };
  },

  actions: {
    toggleSideNavCollapsed() {
      this.sideNavCollapsed = !this.sideNavCollapsed;
    },
    toggleSideNavOpen(value) {
      if (value !== undefined) {
        this.sideNavOpen = value;
        return;
      }
      this.sideNavOpen = !this.sideNavOpen;
    },
    toggleMarketingBarVisible() {
      this.marketingBarVisible = !this.marketingBarVisible;
    },
    setOnboardingShow(isShow: boolean) {
      this.onboardingShow = isShow;
    }
  },
  persist: true
});
