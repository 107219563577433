<script setup lang="ts">
import LoginBox from '../components/LoginBox.vue';
const backgroundImage = new URL('@/assets/images/bg_swoosh.png', import.meta.url).href;
const immersveLogo = new URL('@/assets/icons/ImmersveLogo.svg', import.meta.url).href;
</script>

<template>
  <div
    class="flex h-full w-full flex-col items-center overflow-auto bg-cover bg-no-repeat"
    :style="{
      'background-image': `url('${backgroundImage}')`
    }"
  >
    <div
      class="px-4 lg:container md:w-[30rem] lg:flex lg:w-auto lg:flex-row lg:items-start lg:justify-center"
    >
      <div>
        <img class="mt-10 h-10 md:mt-28 lg:mt-20" :src="immersveLogo" alt="Immersve logo" />
        <p class="mt-10 select-none text-4xl md:text-7xl lg:max-w-md">Sign in to Immersve</p>
      </div>

      <LoginBox />
    </div>
  </div>
</template>
