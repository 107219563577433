<script setup lang="ts">
import { reactive } from 'vue';
import { config, appConfigBuilder, AppConfigLayer } from '@/config';
import { AppConfigSchema } from '@/config/defaults';
import InputConfigValue from '@/components/InputConfigValue.vue';
import ButtonBase from '@/components/ButtonBase.vue';
import { JsonStorageProvider } from '../config/providers/JsonStorageProvider';

// To give visibility on values coming from server
const serverLayer = appConfigBuilder.getLayer(AppConfigLayer.WINDOW);
const localLayer = appConfigBuilder.getLayer(AppConfigLayer.LOCAL_STORAGE);
const localLayerProvider = localLayer?.provider as JsonStorageProvider<AppConfigSchema> | undefined;

// Form values
const formValues = reactive<AppConfigSchema>({ ...config });

function resetConfig() {
  localLayerProvider?.reset();
  location.reload();
}

function persistConfig() {
  Object.entries(formValues).forEach(([key, value]) => {
    if (value !== config[key]) {
      localLayerProvider?.saveValue(key, value);
    }
  });
  location.reload();
}
function prettifyConfigKey(configKey: string): string {
  return configKey
    .toLowerCase()
    .split('_')
    .map((s) => s.replace(/^./, (c) => c.toUpperCase()))
    .join(' ');
}
</script>

<template>
  <div class="w-[800px] max-w-full mx-auto px-4">
    <p class="text-xl font-bold text-charcoal-200 py-5 text-center">👷 Developer config 🏗️</p>

    <div class="flex flex-col items-center mb-5 md:flex-row md:justify-between">
      <ButtonBase text="Reset" @click="resetConfig" class="my-2" data-testid="btn-reset-top" />
      <ButtonBase text="Save" @click="persistConfig" class="my-2" data-testid="btn-save-top" />
    </div>

    <ul>
      <li v-for="(_, key) in config" :key="key">
        <InputConfigValue
          :item-key="key"
          :display-value="prettifyConfigKey(key)"
          v-model="formValues[key]"
          :currentValue="config[key]"
          :baseValue="
            serverLayer?.provider.getValue(key) !== undefined
              ? (serverLayer?.provider.getValue(key) as string | boolean)
              : ''
          "
        />
      </li>
    </ul>

    <div class="flex flex-col items-center my-5 md:flex-row md:justify-between">
      <ButtonBase text="Reset" @click="resetConfig" class="my-2" data-testid="btn-reset-bottom" />
      <ButtonBase text="Save" @click="persistConfig" class="my-2" data-testid="btn-save-bottom" />
    </div>
  </div>
</template>
