<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 1.75C2.30964 1.75 1.75 2.30964 1.75 3V6.25H18.25V3C18.25 2.30964 17.6904 1.75 17 1.75H3ZM19.75 3C19.75 1.48122 18.5188 0.25 17 0.25H3C1.48122 0.25 0.25 1.48122 0.25 3V17C0.25 18.5188 1.48122 19.75 3 19.75H17C18.5188 19.75 19.75 18.5188 19.75 17V3ZM18.25 7.75H7.75V18.25H17C17.6904 18.25 18.25 17.6904 18.25 17V7.75ZM6.25 18.25V7.75H1.75V17C1.75 17.6904 2.30964 18.25 3 18.25H6.25Z"
      fill="#A4A4AA"
    />
  </svg>
</template>
