import { createWalletClient, http, fallback } from 'viem';
import { mnemonicToAccount } from 'viem/accounts';
import { config } from '@/config';
import { polygonAmoy } from '@/web3/client.ts';

const { MOCK_WEB3_WALLET_MNEMONIC } = config;

export function createMockWalletClient() {
  return createWalletClient({
    key: 'immersve-wallet-client',
    name: 'immersve-wallet-client',
    account: mnemonicToAccount(MOCK_WEB3_WALLET_MNEMONIC),
    chain: polygonAmoy,
    transport: fallback([http(polygonAmoy.rpcUrls.default[0])])
  });
}
