import { PageInfo } from './transaction';

export enum InteractionType {
  Deposit = 'Deposit',
  Withdrawal = 'Withdrawal',
  Refund = 'Refund',
  Payment = 'Payment'
}

export enum InteractionStatus {
  Pending = 'Pending',
  ProcessingAttempted = 'ProcessingAttempted',
  ProcessingSuccessful = 'ProcessingSuccessful',
  Confirmed = 'Confirmed',
  Discarded = 'Discarded'
}

export enum InteractionContextType {
  SmartContractEvent = 'smart-contract-event',
  RtfTransaction = 'card-transaction'
}

export interface FundingSourceInteractionResponseItem {
  id: string;
  createdAt: Date;
  modifiedAt: Date;
  fundingSourceId: string;
  accountId: string;
  type: InteractionType;
  status: InteractionStatus;
  token: string;
  amount: number;
  creditDebitIndicator: string;
  description: string;
  context: {
    ref: string;
    type: InteractionContextType;
    blockNumber?: string;
    transactionHash?: string;
  };
  channel: {
    id: string;
    type: string;
    strategy: string;
  };
}

export interface ListFundingSourceInteractionsResponse {
  items: FundingSourceInteractionResponseItem[];
  pageInfo: PageInfo;
}
