import { useAccountActivationStore } from '@/store/accountActivation.store';
import { assignLocation } from '@/utils/navigation';
import { config } from '@/config';

export const useRedirectToKyc = () => {
  const accountActivationStore = useAccountActivationStore();

  function redirectToKyc() {
    try {
      let kycUrl = accountActivationStore.currentActivationDetails?.kycUrl;

      if (!kycUrl) {
        throw new Error();
      }

      if (config.KYC_BASE_URL_OVERRIDE_ENABLED) {
        const originalUrl = new URL(kycUrl);
        const newBaseUrl = new URL(config.KYC_BASE_URL_OVERRIDE_URL);
        const allowedDomains = [/^localhost$/, /^.*\.8dev.lol$/, /^.*\.immersve.com$/];

        if (allowedDomains.some((domain) => newBaseUrl.hostname.match(domain))) {
          const originalUrlRelativeUrl = `${originalUrl.pathname}?${originalUrl.searchParams}`;
          kycUrl = new URL(originalUrlRelativeUrl, newBaseUrl).toString();
        }
      }

      assignLocation(kycUrl);
    } catch (error) {
      assignLocation('/');
    }
  }

  return {
    redirectToKyc
  };
};
