<script setup lang="ts">
import ArrowDownIcon from '@heroicons/vue/24/solid/ArrowDownIcon';
import ArrowUpIcon from '@heroicons/vue/24/solid/ArrowUpIcon';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';
import { useFundingStore } from '@/store/funding.store';

const fundingSourceStore = useFundingStore();
</script>

<template>
  <div>
    <div class="flex flex-col sm:flex-row">
      <p class="text-3xl">
        Your available balance is
        <LoadingSpinner
          v-if="!fundingSourceStore.fundingSource && fundingSourceStore.isFundingSourceFetching"
          class="inline-block"
        />
        <span v-else class="text-primary-purple-100">
          {{ fundingSourceStore.fundingSource?.balanceMajor }}
          {{ fundingSourceStore.fundingSource?.balanceCurrency }}
        </span>
      </p>
    </div>

    <div class="flex mt-8">
      <RouterLink to="/deposit" data-testid="deposit-link">
        <div
          class="flex items-center rounded-full py-2 px-4 bg-primary-purple-100 text-sm text-charcoal-1000 w-fit mr-3"
        >
          <ArrowDownIcon class="h-4 mr-2" />
          <span>Deposit</span>
        </div>
      </RouterLink>

      <RouterLink to="/withdraw" data-testid="withdraw-link">
        <div
          class="flex items-center rounded-full py-2 px-4 border border-primary-purple-100 text-sm text-primary-purple-100 w-fit"
        >
          <ArrowUpIcon class="h-4 mr-2" />
          <span>Withdraw</span>
        </div>
      </RouterLink>
    </div>
  </div>
</template>
