<script setup lang="ts">
import { computed } from 'vue';
import LayoutContent from '@/components/LayoutContent.vue';
import BalanceWidget from '@/components/BalanceWidget.vue';
import TransactionsWidget from '@/components/TransactionsWidget.vue';
import OnboardingDialog from '@/components/OnboardingDialog.vue';
import CardsWidget from '@/components/CardsWidget.vue';
import ButtonCreateCardFixed from '@/components/ButtonCreateCardFixed.vue';
import KycStatusBar from '@/components/KycStatusBar.vue';
import WarnerStatusBar from '@/components/WarningStatusBar.vue';
import { useAccountActivationStore } from '@/store/accountActivation.store';

const accountActivationStore = useAccountActivationStore();

const isKycFailed = computed(
  () => accountActivationStore.currentActivationDetails?.kycStatus === 'failed'
);
const isKycPending = computed(
  () => accountActivationStore.currentActivationDetails?.kycStatus === 'pending'
);

const isCreateCardDisabled = computed(() => isKycFailed.value || isKycPending.value);
</script>

<template>
  <LayoutContent>
    <KycStatusBar />
    <WarnerStatusBar />
    <p class="mt-6">Welcome back,</p>

    <BalanceWidget class="mt-5 max-w-2xl" />

    <CardsWidget class="mt-14" />

    <TransactionsWidget :displayLimit="5" widgetTitle="Recent transactions" />

    <OnboardingDialog />

    <ButtonCreateCardFixed v-if="!isCreateCardDisabled" />
  </LayoutContent>
</template>
