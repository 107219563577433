interface UserProps {
  id: string;
  walletAddress: string;
  accountId: string;
  createdAt?: string;
}

export class User {
  id: string;
  walletAddress: string;
  accountId: string;
  createdAt?: string;

  constructor(props: UserProps) {
    this.id = props.id;
    this.walletAddress = props.walletAddress;
    this.accountId = props.accountId;
    this.createdAt = props.createdAt;
  }
}
