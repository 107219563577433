<script setup lang="ts">
import { onMounted, reactive, ref, computed } from 'vue';
import SingleDigitInput from '@/components/inputs/SingleDigitInput.vue';
import ButtonRounded from '@/components/ButtonRounded.vue';
import { ArrowLeftIcon } from '@heroicons/vue/24/solid';
import LoadingSpinner from '../icons/LoadingSpinner.vue';

const emit = defineEmits<{
  (e: 'back'): void;
  (e: 'submit', value: string): void;
  (e: 'change'): void;
}>();

defineProps<{
  showBackButton?: boolean;
  submitButtonText: string;
  errorMessage?: string;
  loading?: boolean;
}>();

const inputRefs = ref<Array<HTMLInputElement & { inputRef?: HTMLInputElement }>>([]);

const inputState = reactive({
  pin1: '',
  pin2: '',
  pin3: '',
  pin4: ''
});

const inputsDisabled = ref<Boolean>(true);

const submitDisabled = computed<Boolean>(() => {
  const pin = Object.values(inputState)
    .map((char) => char)
    .join('');
  const inputsFilled = pin.length === 4;

  return !inputsFilled;
});

onMounted(() => {
  inputsDisabled.value = false;
});

function clearForm() {
  inputState.pin1 = '';
  inputState.pin2 = '';
  inputState.pin3 = '';
  inputState.pin4 = '';
}

function focusInput(index: number) {
  if (index < 0 || index >= inputRefs.value.length) {
    return;
  }
  inputRefs.value[index].inputRef?.focus();
}

function submit() {
  emit('submit', Object.values(inputState).join(''));
}

defineExpose({ clearForm });
</script>

<template>
  <form autocomplete="off" @submit.prevent="submit">
    <div ref="inputHolder" class="flex w-fit mx-auto flex-wrap">
      <SingleDigitInput
        v-for="(_, key, index) in inputState"
        :key="key"
        ref="inputRefs"
        inputmode="numeric"
        :name="key"
        :autofocus="index === 0"
        :disabled="inputsDisabled"
        v-model="inputState[key]"
        :aria-label="`pin-character-${index + 1}`"
        @write="
          () => {
            focusInput(index + 1);
            $emit('change');
          }
        "
        @delete="
          () => {
            focusInput(index - 1);
            $emit('change');
          }
        "
      />
    </div>
    <p class="text-center h-7 mt-4 text-utility-error-red-200">
      {{ errorMessage }}
    </p>
    <div class="mt-11 flex justify-between">
      <button
        v-if="showBackButton"
        type="button"
        class="h-12 px-2 flex justify-center items-center"
        @click="$emit('back')"
      >
        <div class="h-5 w-5 mr-2"><ArrowLeftIcon /></div>
        <p class="text-charcoal-200 font-semibold">Back</p>
      </button>
      <ButtonRounded
        type="submit"
        :disabled="inputsDisabled || submitDisabled || loading"
        :class="{
          'w-48': showBackButton,
          'w-full': !showBackButton,
          'cursor-wait': loading
        }"
      >
        <div v-if="loading" class="flex justify-center">
          <LoadingSpinner />
        </div>
        <p v-else>{{ submitButtonText }}</p>
      </ButtonRounded>
    </div>
  </form>
</template>
