<template>
  <svg
    width="73"
    height="61"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath id="clip0_328_47314">
        <rect fill="white" height="999" id="svg_1" width="1440" />
      </clipPath>
    </defs>
    <g class="layer">
      <title>Layer 1</title>
      <path
        d="m48,22.5c0.82,0 1.5,0.68 1.5,1.5l0,3l-45,0l0,-3c0,-0.82 0.68,-1.5 1.5,-1.5l42,0zm1.5,13.5l0,18c0,0.82 -0.68,1.5 -1.5,1.5l-42,0c-0.82,0 -1.5,-0.68 -1.5,-1.5l0,-18l45,0zm-43.5,-18c-3.31,0 -6,2.69 -6,6l0,30c0,3.31 2.69,6 6,6l42,0c3.31,0 6,-2.69 6,-6l0,-30c0,-3.31 -2.69,-6 -6,-6l-42,0zm5.25,28.5c-1.25,0 -2.25,1 -2.25,2.25c0,1.25 1,2.25 2.25,2.25l4.5,0c1.25,0 2.25,-1 2.25,-2.25c0,-1.25 -1,-2.25 -2.25,-2.25l-4.5,0zm12,0c-1.25,0 -2.25,1 -2.25,2.25c0,1.25 1,2.25 2.25,2.25l10.5,0c1.25,0 2.25,-1 2.25,-2.25c0,-1.25 -1,-2.25 -2.25,-2.25l-10.5,0z"
        fill="#B9ACFF"
        id="svg_3"
        stroke-width="0"
      />
      <path
        d="m43.82,20.32l6.18,-2.32l2.32,-6.18c0.18,-0.49 0.66,-0.82 1.18,-0.82c0.52,0 1,0.33 1.18,0.82l2.32,6.18l6.18,2.32c0.49,0.18 0.82,0.65 0.82,1.18c0,0.53 -0.33,1 -0.82,1.18l-6.18,2.32l-2.32,6.18c-0.18,0.49 -0.66,0.82 -1.18,0.82c-0.52,0 -1,-0.33 -1.18,-0.82l-2.32,-6.18l-6.18,-2.32c-0.49,-0.18 -0.82,-0.65 -0.82,-1.18c0,-0.53 0.33,-1 0.82,-1.18z"
        fill="#917DFF"
        id="svg_4"
        stroke-width="0"
      />
      <path
        d="m57.55,6.21l4.12,-1.54l1.54,-4.12c0.12,-0.33 0.44,-0.55 0.79,-0.55c0.35,0 0.67,0.22 0.79,0.55l1.54,4.12l4.12,1.54c0.33,0.12 0.55,0.44 0.55,0.79c0,0.35 -0.22,0.67 -0.55,0.79l-4.12,1.54l-1.54,4.12c-0.12,0.33 -0.44,0.55 -0.79,0.55c-0.35,0 -0.67,-0.22 -0.79,-0.55l-1.54,-4.12l-4.12,-1.54c-0.33,-0.12 -0.55,-0.44 -0.55,-0.79c0,-0.35 0.22,-0.67 0.55,-0.79z"
        fill="#917DFF"
        id="svg_5"
        stroke-width="0"
      />
      <path
        d="m62.43,30.88l3.24,-1.21l1.21,-3.24c0.1,-0.26 0.35,-0.43 0.62,-0.43c0.27,0 0.52,0.17 0.62,0.43l1.21,3.24l3.24,1.21c0.26,0.1 0.43,0.34 0.43,0.62c0,0.28 -0.17,0.52 -0.43,0.62l-3.24,1.21l-1.21,3.24c-0.1,0.26 -0.35,0.43 -0.62,0.43c-0.27,0 -0.52,-0.17 -0.62,-0.43l-1.21,-3.24l-3.24,-1.21c-0.26,-0.1 -0.43,-0.34 -0.43,-0.62c0,-0.28 0.17,-0.52 0.43,-0.62z"
        fill="#917DFF"
        id="svg_6"
        stroke-width="0"
      />
    </g>
  </svg>
</template>
