<template>
  <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.2812 4.9375H2.71875C2.26291 4.9375 1.82574 5.11858 1.50341 5.44091C1.18108 5.76324 1 6.20041 1 6.65625L1 18.3438C1 18.7996 1.18108 19.2368 1.50341 19.5591C1.82574 19.8814 2.26291 20.0625 2.71875 20.0625H21.2812C21.7371 20.0625 22.1743 19.8814 22.4966 19.5591C22.8189 19.2368 23 18.7996 23 18.3438V6.65625C23 6.20041 22.8189 5.76324 22.4966 5.44091C22.1743 5.11858 21.7371 4.9375 21.2812 4.9375ZM2.71875 6.65625H5.46875C5.44963 6.99603 5.36289 7.32857 5.21361 7.63439C5.06434 7.94022 4.85553 8.21318 4.59942 8.43728C4.3433 8.66138 4.04504 8.83211 3.72211 8.93947C3.39917 9.04682 3.05806 9.08865 2.71875 9.0625V6.65625ZM2.71875 18.3438V15.9375C3.05806 15.9113 3.39917 15.9532 3.72211 16.0605C4.04504 16.1679 4.3433 16.3386 4.59942 16.5627C4.85553 16.7868 5.06434 17.0598 5.21361 17.3656C5.36289 17.6714 5.44963 18.004 5.46875 18.3438H2.71875ZM21.2812 18.3438H18.6C18.6391 17.6701 18.9428 17.0391 19.445 16.5884C19.9473 16.1377 20.6073 15.9037 21.2812 15.9375V18.3438ZM21.2812 14.2188C20.7214 14.2004 20.1635 14.2926 19.6393 14.49C19.1151 14.6874 18.6349 14.9862 18.2263 15.3693C17.8177 15.7524 17.4885 16.2123 17.2577 16.7227C17.027 17.233 16.899 17.7839 16.8813 18.3438H7.11875C7.10098 17.7839 6.97304 17.233 6.74225 16.7227C6.51146 16.2123 6.18233 15.7524 5.77369 15.3693C5.36506 14.9862 4.88492 14.6874 4.36073 14.49C3.83654 14.2926 3.27858 14.2004 2.71875 14.2188V10.7812C3.27858 10.7996 3.83654 10.7074 4.36073 10.51C4.88492 10.3126 5.36506 10.0138 5.77369 9.63069C6.18233 9.24759 6.51146 8.78771 6.74225 8.27734C6.97304 7.76696 7.10098 7.2161 7.11875 6.65625H16.8813C16.899 7.2161 17.027 7.76696 17.2577 8.27734C17.4885 8.78771 17.8177 9.24759 18.2263 9.63069C18.6349 10.0138 19.1151 10.3126 19.6393 10.51C20.1635 10.7074 20.7214 10.7996 21.2812 10.7812V14.2188ZM21.2812 9.0625C20.6073 9.09628 19.9473 8.86233 19.445 8.41161C18.9428 7.96089 18.6391 7.32994 18.6 6.65625H21.2812V9.0625Z"
      fill="#A4A4AA"
    />
    <path
      d="M11.9996 8.37534C11.4398 8.35698 10.8818 8.44915 10.3576 8.64658C9.83344 8.844 9.3533 9.1428 8.94467 9.52589C8.53603 9.90899 8.2069 10.3689 7.97611 10.8792C7.74532 11.3896 7.61738 11.9405 7.59961 12.5003C7.61738 13.0602 7.74532 13.611 7.97611 14.1214C8.2069 14.6318 8.53603 15.0917 8.94467 15.4748C9.3533 15.8579 9.83344 16.1567 10.3576 16.3541C10.8818 16.5515 11.4398 16.6437 11.9996 16.6253C12.5594 16.6437 13.1174 16.5515 13.6416 16.3541C14.1658 16.1567 14.6459 15.8579 15.0546 15.4748C15.4632 15.0917 15.7923 14.6318 16.0231 14.1214C16.2539 13.611 16.3818 13.0602 16.3996 12.5003C16.3818 11.9405 16.2539 11.3896 16.0231 10.8792C15.7923 10.3689 15.4632 9.90899 15.0546 9.52589C14.6459 9.1428 14.1658 8.844 13.6416 8.64658C13.1174 8.44915 12.5594 8.35698 11.9996 8.37534ZM11.9996 14.9066C11.3256 14.9404 10.6656 14.7064 10.1634 14.2557C9.66116 13.805 9.35742 13.174 9.31836 12.5003C9.35742 11.8266 9.66116 11.1957 10.1634 10.745C10.6656 10.2943 11.3256 10.0603 11.9996 10.0941C12.3389 10.0679 12.68 10.1098 13.003 10.2171C13.3259 10.3245 13.6242 10.4952 13.8803 10.7193C14.1364 10.9434 14.3452 11.2164 14.4945 11.5222C14.6438 11.828 14.7305 12.1606 14.7496 12.5003C14.7305 12.8401 14.6438 13.1727 14.4945 13.4785C14.3452 13.7843 14.1364 14.0573 13.8803 14.2814C13.6242 14.5055 13.3259 14.6762 13.003 14.7835C12.68 14.8909 12.3389 14.9327 11.9996 14.9066Z"
      fill="#A4A4AA"
    />
  </svg>
</template>
