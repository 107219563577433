export const defaultConfig = {
  TEST_MODE_ENABLED: false,
  KYC_BASE_URL_OVERRIDE_ENABLED: false,
  KYC_BASE_URL_OVERRIDE_URL: '',
  KYC_REGION: '',
  CUSTOM_CARD_REGION: '',
  CUSTOM_CARD_EXPIRATION: '',
  ENV: 'local',
  API_BASE_URL: '',
  TEST_MODE_API_BASE_URL: '',
  API_SECURE_BASE_URL: '',
  TEST_MODE_API_SECURE_BASE_URL: '',
  API_CLIENT_APPLICATION_ID_TEST: '',
  API_CLIENT_APPLICATION_ID_LIVE: '',
  USDC_SMART_CONTRACT_ADDRESS_TEST: '',
  USDC_SMART_CONTRACT_ADDRESS_LIVE: '',
  FUNDING_CHANNEL_ID_POLYGON_USDC_LIVE: '',
  FUNDING_CHANNEL_ID_POLYGON_USDC_TEST: '',
  WALLET_CONNECT_PROJECT_ID: '',
  SENTRY_DSN: '',
  SENTRY_ENABLED: false,
  CHAIN_ID: '80002',
  TEST_MODE_CHAIN_ID: '80002',
  CARD_PROGRAM_ID_LIVE: '',
  CARD_PROGRAM_ID_TEST: '',
  MOCK_WEB3_CONNECTOR_ENABLED: false,
  MOCK_WEB3_WALLET_MNEMONIC: '',
  FETCH_CARD_ON_CREATION_ENABLED: true,
  FAST_DEPOSIT_ENABLED: false
};

export type AppConfigSchema = typeof defaultConfig;
