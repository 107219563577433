<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import OnboardingDialogContentSlide from './OnboardingDialogContentSlide.vue';
import ButtonBase from './ButtonBase.vue';
import { ref } from 'vue';
import { Swiper as SwiperCode } from 'swiper';
defineProps({
  onSlideToEnd: { type: Function, required: true }
});

const { href: arrowRight } = new URL('@/assets/icons/arrow-right.svg', import.meta.url);
const { href: OnboardPic1 } = new URL('@/assets/icons/OnboardMastercard.svg', import.meta.url);
const { href: OnboardPic2 } = new URL('@/assets/icons/OnboardMastercard2.svg', import.meta.url);
const { href: OnboardPic3 } = new URL('@/assets/icons/OnboardMastercard3.svg', import.meta.url);
const swiperInstance = ref<SwiperCode>();

function onSwiper(swiper: SwiperCode) {
  swiperInstance.value = swiper;
}

function nextSlide(onSlideToEnd: Function) {
  if (swiperInstance.value?.isEnd) {
    onSlideToEnd();
    return;
  }
  swiperInstance.value?.slideNext();
}
</script>

<template>
  <div
    class="flex w-[343px] sm:w-[352px] h-[529px] px-6 py-10 flex-col items-center gap-8 flex-shrink-0 bg-secondary-blue-1000 shadow-onboarding"
  >
    <p
      class="flex flex-col content-center flex-shrink-0 self-stretch text-center text-xl text-white"
    >
      Welcome to Immersve
    </p>
    <div class="flex flex-col items-center gap-6 self-stretch h-[316px]">
      <swiper @swiper="onSwiper" class="w-full h-full" :pagination="false">
        <swiper-slide>
          <OnboardingDialogContentSlide
            :pic-url="OnboardPic1"
            description="Create your own virtual Mastercard™"
            :active-index="1"
          >
          </OnboardingDialogContentSlide>
        </swiper-slide>
        <swiper-slide>
          <OnboardingDialogContentSlide
            :pic-url="OnboardPic2"
            description="Spend your crypto anywhere Mastercard™is accepted"
            :active-index="2"
          >
          </OnboardingDialogContentSlide>
        </swiper-slide>
        <swiper-slide>
          <OnboardingDialogContentSlide
            :pic-url="OnboardPic3"
            description="Track and manage all your transactions in one place"
            :active-index="3"
          >
          </OnboardingDialogContentSlide>
        </swiper-slide>
      </swiper>
    </div>
    <div>
      <ButtonBase
        text="Get Started"
        :trailingIconSrc="arrowRight"
        @click="() => nextSlide(onSlideToEnd)"
      />
    </div>
  </div>
</template>
