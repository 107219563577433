<script setup lang="ts">
import { useUiStore } from '@/store/ui.store';

const uiState = useUiStore();

defineProps<{ to: string; title: string; collapsed?: boolean }>();
function closeSideNavOnMobile() {
  if (uiState.sideNavOpen) {
    uiState.toggleSideNavOpen();
  }
}
</script>

<template>
  <router-link
    :to="to"
    :class="`my-3 flex h-10 items-center rounded-lg ${collapsed ? 'w-fit' : 'w-full'}`"
    active-class="bg-gradient-primary"
    @click="closeSideNavOnMobile"
  >
    <div class="flex cursor-pointer">
      <div class="flex w-10 items-center justify-center">
        <slot />
      </div>
      <p
        v-if="!collapsed"
        class="select-none text-base font-normal text-slate-300 overflow-hidden whitespace-nowrap"
      >
        {{ title }}
      </p>
    </div>
  </router-link>
</template>
