<template>
  <!-- Gradient border div -->
  <div
    class="box-border aspect-[1.586] w-80 rounded-lg bg-gradient-to-br from-zinc-100 to-gray-700 p-px"
  >
    <!-- Plain background div -->
    <div class="relative h-full w-full overflow-hidden rounded-lg bg-charcoal-1000">
      <!-- Gradient zone -->
      <div class="custom-gradient-blue absolute left-0 top-0 z-10 h-full w-full">
        <div class="custom-gradient-purple h-full w-full" />
      </div>
      <div class="custom-gradient-radial absolute left-0 top-0 z-20 h-full w-full" />
      <!-- Gradient zone end-->
      <!-- <MastercardLogo class="absolute bottom-[7%] right-[3.5%] z-30 w-[25%] h-[25%]" /> -->
      <div class="absolute left-0 top-0 z-40 h-full w-full">
        <slot />
      </div>
    </div>
  </div>
</template>

<!-- Defining style here just a bit cleaner  -->
<style scoped>
.custom-gradient-blue {
  background: linear-gradient(117deg, rgba(5, 11, 31, 0) 48%, rgba(85, 61, 179, 1) 100%);
}

.custom-gradient-purple {
  background: linear-gradient(180deg, rgba(5, 11, 31, 0) 48%, rgba(98, 55, 135, 1) 100%);
}

.custom-gradient-radial {
  background: radial-gradient(
    ellipse at -50% -50%,
    rgba(5, 12, 30, 1) 50%,
    rgba(5, 11, 31, 0) 100%
  );
}
</style>
