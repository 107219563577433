import { FundingSourceInteraction } from '@/models/FundingSourceInteraction';
import { useSessionsStore } from '@/store/sessions.store';
import { defineStore } from 'pinia';

interface FundingInteractionsState {
  interactions: Record<string, FundingSourceInteraction[]>;
}

export const useFundingInteractionsStore = defineStore<
  'funding-interactions',
  FundingInteractionsState,
  {
    currentInteractions: (state: FundingInteractionsState) => FundingSourceInteraction[];
  },
  {
    clear: () => void;
    addPendingFundingSourceInteraction: (interaction: FundingSourceInteraction) => void;
    updatePendingFundingSourceInteraction: (
      interactionId: string,
      interaction: FundingSourceInteraction
    ) => void;
    completePendingFundingSourceInteraction: (interactionId: string) => void;
  }
>('funding-interactions', {
  state() {
    return { interactions: {}, pendingToastsShown: [] };
  },
  getters: {
    currentInteractions(state) {
      const address = useSessionsStore().currentAccount;
      if (!address) return [];
      return (state.interactions[address] || []).map(FundingSourceInteraction.fromApiResponse);
    }
  },

  actions: {
    addPendingFundingSourceInteraction(interaction: FundingSourceInteraction) {
      const address = useSessionsStore().currentAccount;
      if (!address) return;
      this.interactions[address] = [interaction, ...this.currentInteractions];
    },

    updatePendingFundingSourceInteraction(id: string, interaction: FundingSourceInteraction) {
      const address = useSessionsStore().currentAccount;
      if (!address) return;

      const index = this.interactions[address].findIndex((interaction) => interaction.id == id);

      if (index > -1) {
        this.interactions[address][index] = interaction;
      }
    },

    completePendingFundingSourceInteraction(id: string) {
      const address = useSessionsStore().currentAccount;
      if (!address) return;

      const index = this.interactions[address].findIndex((interaction) => interaction.id == id);

      if (index > -1) {
        this.interactions[address].splice(index, 1);
      }
    },
    clear() {
      this.$reset();
    }
  },
  persist: true
});
