export function formatPan(pan: string) {
  return pan.replace(/(.{4})/g, '$1 ').trim();
}

export function formatExpiry(expiry: string, yearFormat: 'short-year' | 'full-year' = 'full-year') {
  const fullYear = expiry.slice(0, 4);
  const year = yearFormat === 'short-year' ? fullYear.slice(2, 4) : fullYear;
  const month = expiry.slice(4, 6);

  return `${month}/${year}`;
}

export function formatEthAddress(address: string = ''): string {
  if (address.length < 8) {
    return address;
  }
  const firstFour = address.slice(0, 4);
  const lastFour = address.slice(-4);

  return `${firstFour}...${lastFour}`;
}

export function upperFirstChar(str: string) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}
