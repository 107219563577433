<script setup lang="ts">
import { Dialog, DialogPanel, TransitionRoot } from '@headlessui/vue';
import OnboardingDialogContent from '@/components/OnboardingDialogContent.vue';
import { useOnboardingShow } from '@/composables/onboardingHook';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';

const { loading, onboardingShow, setOnboardingShow } = useOnboardingShow();
</script>

<template>
  <LoadingSpinner v-if="loading" class="ml-4" />
  <TransitionRoot
    v-else
    :show="onboardingShow"
    as="div"
    enter="duration-300 ease-out"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="duration-200 ease-in"
    leave-from="opacity-100"
    leave-to="opacity-0"
  >
    <Dialog
      :open="onboardingShow"
      class="absolute left-0 top-0 z-10 h-screen w-screen bg-inherit shadow-onboarding backdrop-blur-lg"
    >
      <DialogPanel class="flex h-screen items-center justify-center">
        <OnboardingDialogContent :on-slide-to-end="() => setOnboardingShow(false)" />
      </DialogPanel>
    </Dialog>
  </TransitionRoot>
</template>
