import { useRedirectToKyc } from '@/composables/redirectToKycHook';
import { useSessionsStore } from '@/store/sessions.store';
import { useAccountActivationStore } from '@/store/accountActivation.store';
import { KycStatus } from '@/types/kycStatus';

export async function beforeEnterNewCard() {
  const sessionsStore = useSessionsStore();
  const accountActivationStore = useAccountActivationStore();
  const { redirectToKyc } = useRedirectToKyc();

  await accountActivationStore.refetchPrerequisites();

  const fundingSourceId = sessionsStore.currentSession?.fundingSourceId;
  const currentActivationDetails = accountActivationStore.currentActivationDetails;

  if (!fundingSourceId || !currentActivationDetails) {
    return '/';
  }

  const { kycStatus, kycUrl } = currentActivationDetails;

  switch (kycStatus) {
    case KycStatus.PENDING:
      return '/';
    case KycStatus.FAILED:
    case KycStatus.REQUIRED:
      if (kycUrl) {
        redirectToKyc();
      } else {
        return '/';
      }
      return false;
    default:
      break;
  }
}
