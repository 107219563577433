
export enum TransactionStatus {
  HOLDING = 'holding',
  CLEARED = 'cleared',
}
export interface TransactionResponse {
  id: string;
  paymentId: string;
  accountId: string;
  cardId: string;
  description: string;
  amount: number;
  currency: string;
  feeAmount: number;
  status: TransactionStatus;
  transactionDate: string;
  processedDate?: string;
  cardAcceptor: {
    city: string;
    name: string;
    countryCode: string;
  };
  reference: string;
  acquirerAmount: string;
  acquirerCurrency: string;
  creditDebitIndicator: string;
}

export interface PageInfo {
  nextCursor?: string;
  previousCursor?: string;
}

