<script setup lang="ts">
import { ref } from 'vue';
import { AxiosError } from 'axios';
import { useRouteParams } from '@vueuse/router';
import { useMutation, useQuery } from '@tanstack/vue-query';
import apiClient from '@/apiClient';
import PinInput from '@/components/inputs/PinInput.vue';
import { toast } from 'vue3-toastify';
import { useLink } from 'vue-router';
import CardOnGradient from '@/components/CardOnGradient.vue';

const cardId = useRouteParams('cardId', '', { transform: String });

const linkToCardDetails = useLink({ to: `/card/${cardId.value}` });

const formSection = ref<'pin' | 'confirm-pin'>('pin');

const pin = ref<string>('');

const errorMessage = ref<string>('');

function navigateToSetPin() {
  formSection.value = 'pin';
}

const { data: cardDetails } = useQuery({
  queryKey: ['get-card', cardId],
  queryFn: () => {
    return apiClient.getCard(cardId.value);
  }
});

function navigateToConfirmPin() {
  formSection.value = 'confirm-pin';
}

const { mutate: setPin, isLoading: isPinSetting } = useMutation({
  mutationFn: (props: { cardId: string; newPin: string }) => {
    return apiClient.setCardPin(props);
  },
  onError(err) {
    if (err instanceof AxiosError && err.response?.data?.errorCode === 'INVALID_PIN_FORMAT') {
      toast.error('Invalid PIN format. Try again with different PIN');
      navigateToSetPin();
      return;
    }
    errorMessage.value = 'Unable to set PIN. Please try again later';
  },
  onSuccess() {
    linkToCardDetails.navigate();
  }
});

function handlePinSubmit(value: string) {
  pin.value = value;
  navigateToConfirmPin();
}

const confirmPinComponentRef = ref(null);

function handlePinConfirmSubmit(value: string) {
  if (pin.value !== value) {
    errorMessage.value = "PIN doesn't match. Try again.";
    // Clear confirm pin form
    (confirmPinComponentRef.value as { clearForm: () => void } | null)?.clearForm();
    return;
  }
  setPin({ cardId: cardId.value, newPin: value });
}

function handleBackButtonClick() {
  navigateToSetPin();
  errorMessage.value = '';
}

function handleConfirmFormChange() {
  errorMessage.value = '';
}
</script>

<template>
  <div class="container mx-auto px-4 mt-6 flex justify-center lg:h-full">
    <CardOnGradient class="hidden lg:flex flex-1" :cardDetails="cardDetails" />

    <div class="px-0 lg:px-16 flex items-center md:w-[40rem]">
      <div class="w-full">
        <p class="text-charcoal-200 py-4 text-base font-bold">CARD SECURITY</p>

        <p v-if="formSection === 'pin'" class="text-3xl py-2 text-charcoal-200 select-none">
          Set a 4-digit PIN for your card
        </p>

        <p v-else class="text-3xl py-2 text-charcoal-200 select-none">Confirm your 4-digit PIN</p>

        <p v-if="formSection === 'pin'" class="text-charcoal-400 text-base mt-2">
          Avoid using obvious or easily guessable numbers as your PIN code
        </p>

        <p v-else class="text-charcoal-400 text-base mt-2">
          Repeat the number you entered in the previous step
        </p>

        <PinInput
          v-if="formSection === 'pin'"
          class="mt-8"
          submitButtonText="Next"
          @submit="handlePinSubmit"
        />
        <PinInput
          ref="confirmPinComponentRef"
          v-else-if="formSection === 'confirm-pin'"
          class="mt-8"
          submitButtonText="Set PIN"
          @submit="handlePinConfirmSubmit"
          @back="handleBackButtonClick"
          @change="handleConfirmFormChange"
          :pinToMatch="pin"
          showBackButton
          :errorMessage="errorMessage"
          :loading="isPinSetting"
        />
      </div>
    </div>
  </div>
</template>
