import { intervalToDuration, formatDuration, differenceInSeconds, isBefore } from 'date-fns';
import { CardResponseItem } from '@/apiClient/types/card';
import { CardStatus } from '@/types/card';
import { formatExpiry } from '../utils/formatString';

export interface CardProps {
  id: string;
  expiry: string;
  immersveExpiration: Date;
  expiresAt: Date;
  accountId: string;
  createdAt: Date;
  modifiedAt: Date;
  type: string;
  panFirst6: string;
  panLast4: string;
  status: CardStatus;
  isBlocked: boolean;
  fundingSourceIds: Array<string>;
}

export class Card implements CardProps {
  id: string;
  expiry: string;
  immersveExpiration: Date;
  expiresAt: Date;
  accountId: string;
  createdAt: Date;
  modifiedAt: Date;
  type: string;
  panFirst6: string;
  panLast4: string;
  status: CardStatus;
  isBlocked: boolean;
  fundingSourceIds: Array<string>;

  constructor(props: CardProps) {
    this.id = props.id;
    this.expiry = props.expiry;
    this.immersveExpiration = props.immersveExpiration;
    this.expiresAt = props.expiresAt;
    this.accountId = props.accountId;
    this.createdAt = props.createdAt;
    this.modifiedAt = props.modifiedAt;
    this.type = props.type;
    this.panFirst6 = props.panFirst6;
    this.panLast4 = props.panLast4;
    this.status = props.status;
    this.isBlocked = props.isBlocked;
    this.fundingSourceIds = props.fundingSourceIds;
  }

  get isExpired() {
    const now = new Date();
    return isBefore(this.immersveExpiration, now);
  }

  get timeRemaining() {
    const now = new Date();
    if (isBefore(this.immersveExpiration, now)) {
      return '00:00';
    }
    const seconds = differenceInSeconds(this.immersveExpiration, new Date());
    const duration = intervalToDuration({ start: seconds * 1000, end: 0 });

    const formatted = formatDuration(duration, {
      format: ['minutes', 'seconds'],
      zero: true,
      delimiter: ':',
      locale: {
        formatDistance(_, count) {
          return String(count).padStart(2, '0');
        }
      }
    });
    return formatted;
  }

  get formattedExpiry() {
    return formatExpiry(this.expiry, 'short-year');
  }

  static fromResponse(props: CardResponseItem) {
    return new Card({
      ...props,
      immersveExpiration: new Date(props.immersveExpiration),
      expiresAt: new Date(props.expiresAt),
      createdAt: new Date(props.createdAt),
      modifiedAt: new Date(props.modifiedAt)
    });
  }
}
