<script setup lang="ts">
import { Card } from '@/models/Card';
import ImmersveCardLogo from '@/components/icons/ImmersveCardLogo.vue';
import MastercardLogo from './icons/MastercardLogo.vue';
import PayPassIcon from './icons/PayPassIcon.vue';

const backgroundImage = new URL('@/assets/images/card_swoosh.png', import.meta.url).href;

defineProps<{
  card?: Card;
}>();

defineEmits<{
  (e: 'click'): () => void;
}>();
</script>

<template>
  <div
    class="box-border aspect-[1.586] w-80 rounded-lg p-4 bg-contain"
    :style="{
      'background-image': `url('${backgroundImage}')`
    }"
  >
    <div class="relative w-full h-full">
      <div class="absolute top-0 left-0">
        <ImmersveCardLogo />
      </div>

      <div v-if="card" class="absolute top-24">
        <p class="text-charcoal-500 font-semibold text-xxs">VALID UNTIL</p>
        <p class="text-charcoal-200 text-xl">{{ card?.formattedExpiry }}</p>
      </div>

      <div v-if="card" class="absolute bottom-0">
        <span class="mr-3 text-base font-normal">• • • •</span>
        <span class="text-base font-normal">{{ card?.panLast4 }}</span>
      </div>

      <div class="absolute top-0 right-0 h-full flex items-center">
        <PayPassIcon />
      </div>

      <div class="absolute bottom-0 right-0">
        <MastercardLogo />
      </div>
    </div>
  </div>
</template>
