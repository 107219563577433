export enum CardStatus {
  CREATED = 'created',
  SHIPPED = 'shipped',
  ACTIVE = 'active',
  CANCELLED = 'cancelled'
}

export interface Card {
  id: string;
  externalId: string;
  expiry: string;
  immersveExpiration: string;
  type?: string;
  accountId: string;
  status: CardStatus;
  currency?: string;
  createdAt: string;
  modifiedAt: string;
  version: number;
  panFirst6: string;
  panLast4: string;
  lockedFundId?: string;
  lockedFundAmount?: string;
  lockedFundCurrency?: string;
}
