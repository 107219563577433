import type { Layer } from '@/config/types';
import { ObjectProvider } from '@/config/providers/ObjectProvider';

function buildProperty<ConfigSchema>(props: {
  keyName: keyof ConfigSchema;
  layers: Layer<Partial<ConfigSchema>>[];
}): [keyof ConfigSchema, ConfigSchema[keyof ConfigSchema]] {
  const { keyName, layers } = props;

  const value = layers
    .map((l) => l.provider.getValue(keyName))
    .find((v) => v !== null && v !== undefined);

  // This should never happen because if default layer is used
  if (value === null || value === undefined) {
    // eslint-disable-next-line no-console
    console.warn(`Config property missing: "${String(keyName)}"`);
    return [keyName, '' as ConfigSchema[keyof ConfigSchema]];
  }

  return [keyName, value];
}

class ConfigBuilder<ConfigSchema extends object> {
  layers: Layer<Partial<ConfigSchema>>[];
  readonly defaultValues: ConfigSchema;

  constructor(defaultValues: ConfigSchema) {
    this.defaultValues = defaultValues;
    const defaultLayer = { name: 'default', provider: new ObjectProvider(defaultValues) };
    this.layers = [defaultLayer];
  }

  addLayer(layer: Layer<Partial<ConfigSchema>>) {
    this.layers.unshift(layer);
    return this;
  }

  getLayer(name: string) {
    return this.layers.find((l) => l.name === name);
  }

  build(): ConfigSchema {
    const propertiesArray = Object.keys(this.defaultValues).map((key) => {
      const keyName = key as keyof ConfigSchema;
      return buildProperty({ keyName, layers: this.layers });
    });

    return propertiesArray.reduce((a, c) => ({ ...a, [c[0]]: c[1] }), {} as ConfigSchema);
  }
}

export { ConfigBuilder };
