<script setup lang="ts">
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue';
import { computed, ref, watch } from 'vue';
import { vMaska } from 'maska';
import { CURRENCY_DETAILS } from '../constants';
import ChevronRightIcon from './icons/ChevronRightIcon.vue';

interface DropdownOption {
  icon?: string;
  label: string;
  value: string;
}

const props = defineProps<{
  inputValue: string;
  dropdownValue: DropdownOption;
  dropdownOptions: Array<DropdownOption>;
  disabled?: boolean;
}>();
const emit = defineEmits<{
  (e: 'update:inputValue', value: string): void;
  (e: 'update:dropdownValue', value: DropdownOption): void;
}>();

const inputValue = computed({
  get() {
    return props.inputValue;
  },
  set(value) {
    emit('update:inputValue', value);
  }
});

const dropdownValue = computed({
  get() {
    return props.dropdownValue;
  },
  set(value) {
    emit('update:dropdownValue', value);
  }
});

const currencySymbol = ref<string>('');

watch(
  dropdownValue,
  () => {
    currencySymbol.value =
      CURRENCY_DETAILS[dropdownValue.value.value as keyof typeof CURRENCY_DETAILS].symbol;
  },
  { immediate: true }
);
</script>

<template>
  <div
    class="flex h-12 items-center rounded-lg border border-divider-transparent-white bg-charcoal-900 pl-4 pr-2"
  >
    <input
      name="card-value"
      type="string"
      class="box-border flex h-full w-full bg-transparent text-base outline-none"
      placeholder="0.00"
      v-model="inputValue"
      v-maska
      data-maska="0.99"
      data-maska-tokens="0:\d:multiple|9:\d:optional"
      :disabled="disabled"
      autocomplete="off"
    />
    <!-- Dropdown -->

    <Listbox v-model="dropdownValue" v-if="dropdownValue" class="flex" :disabled="disabled">
      <div class="relative block">
        <ListboxButton
          class="relative flex w-full cursor-pointer items-center rounded-md py-2 pl-3 pr-10 text-left shadow-md transition-all hover:bg-charcoal-1000 focus:outline-none disabled:bg-transparent disabled:cursor-default"
          name="card-currency"
        >
          <img
            v-if="dropdownValue.icon"
            :alt="`${dropdownValue.value}-icon`"
            :src="dropdownValue.icon"
            class="pr-1 h-5"
          />
          <span class="block text-sm font-semibold mr-3">{{ dropdownValue.value }}</span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronRightIcon class="w-4 rotate-90" />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="absolute top-11 max-h-60 w-full overflow-auto rounded-md bg-charcoal-900 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <ListboxOption
              v-slot="{ active, selected }"
              v-for="option in dropdownOptions"
              :key="option.value"
              :value="option"
            >
              <li
                :class="[
                  active ? 'bg-charcoal-1000 text-white' : 'text-gray-200',
                  'relative cursor-pointer select-none py-2 pl-4 pr-4 rounded flex '
                ]"
              >
                <img
                  v-if="dropdownValue.icon"
                  :alt="`${option.value}-icon`"
                  :src="option.icon"
                  class="pr-1 h-5"
                />
                <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">
                  {{ option.value }}
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>

    <!-- Dropdown end -->
  </div>
</template>
