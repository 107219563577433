<script setup lang="ts">
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Navigation } from 'swiper/modules';
import ImmersveCardUnbranded from '@/components/ImmersveCardUnbranded.vue';
import ChevronRightIcon from '@/components/icons/ChevronRightIcon.vue';
import { Card } from '@/models/Card';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import CreateCardIcon from '@/components/icons/CreateCardIcon.vue';
import router from '@/router';
import { computed } from 'vue';
import { useAccountActivationStore } from '@/store/accountActivation.store';

const accountActivationStore = useAccountActivationStore();

defineProps<{ cards: Array<Card> }>();

const modules = [Pagination, Navigation];

const breakpoints = useBreakpoints(breakpointsTailwind);
const isSmOrUp = breakpoints.greaterOrEqual('sm');

const isKycFailed = computed(
  () => accountActivationStore.currentActivationDetails?.kycStatus === 'failed'
);
const isKycPending = computed(
  () => accountActivationStore.currentActivationDetails?.kycStatus === 'pending'
);

const isCreateCardDisabled = computed(() => isKycFailed.value || isKycPending.value);

function onCardClick(cardId: string) {
  router.push(`/card/${cardId}`);
}
</script>

<template>
  <!-- Negative margin right to compensate container padding -ml-4 -mr-4 sm:ml-0 sm:mr-0 -->
  <div class="relative -ml-4 -mr-4 flex items-center sm:ml-0 sm:mr-0 lg:w-fit lg:max-w-full">
    <Swiper
      v-if="cards.length"
      :slidesPerView="'auto'"
      :spaceBetween="12"
      :pagination="{
        clickable: true,
        el: '.c-pagination'
      }"
      :navigation="{
        prevEl: '.c-nav-prev',
        nextEl: '.c-nav-next',
        enabled: true
      }"
      :modules="modules"
      :slidesOffsetBefore="isSmOrUp ? 0 : 16"
      :slidesOffsetAfter="isSmOrUp ? 0 : 16"
    >
      <SwiperSlide v-for="card in cards" :key="card.id">
        <ImmersveCardUnbranded
          :data-testid="`dashboard-carousel-card-${card.id}`"
          :card="card"
          @click="() => onCardClick(card.id)"
          class="cursor-pointer"
        />
      </SwiperSlide>

      <button
        type="button"
        class="c-nav-prev absolute left-0 top-0 z-50 hidden h-full w-12 items-center justify-center bg-secondary-blue-1000 opacity-90 lg:flex"
      >
        <ChevronRightIcon class="rotate-180" />
      </button>
      <button
        type="button"
        class="c-nav-next absolute right-0 top-0 z-50 hidden h-full w-12 items-center justify-center bg-secondary-blue-1000 opacity-90 lg:flex"
      >
        <ChevronRightIcon />
      </button>
    </Swiper>

    <RouterLink
      v-if="!isCreateCardDisabled"
      role="link"
      to="/new-card"
      class="hidden h-48 w-32 flex-col items-center justify-center rounded-lg border border-divider-transparent-white lg:flex"
    >
      <div class="flex h-10 w-10 items-center justify-center rounded-full bg-primary-purple-100">
        <div class="w-6">
          <CreateCardIcon fill="#000" />
        </div>
      </div>
      <p class="mt-4 text-base font-normal">Create card</p>
    </RouterLink>
  </div>
  <div class="c-pagination" />
</template>

<style>
.swiper {
  @apply flex-1 lg:mr-4;
}

.swiper-slide {
  @apply w-fit;
}

.swiper-pagination-bullet {
  @apply border border-solid border-primary-purple-100 bg-transparent transition-all;
}

.swiper-pagination-bullet-active {
  @apply bg-primary-purple-100;
}

.c-pagination {
  @apply flex h-10 items-center justify-center;
}

.swiper-button-disabled {
  @apply hidden;
}
</style>
