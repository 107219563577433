<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon/XMark">
      <path
        id="Vector"
        d="M17.4163 5.87648L16.1238 4.58398L10.9997 9.70815L5.87551 4.58398L4.58301 5.87648L9.70717 11.0007L4.58301 16.1248L5.87551 17.4173L10.9997 12.2931L16.1238 17.4173L17.4163 16.1248L12.2922 11.0007L17.4163 5.87648Z"
        fill="#BDBDC4"
      />
    </g>
  </svg>
</template>
