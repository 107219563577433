<script setup lang="ts">
import ButtonRounded from '@/components/ButtonRounded.vue';
import { config, getConfigCardProgramId } from '@/config';
import apiClient from '@/apiClient';
import { addDays } from 'date-fns';
import { useMutation } from '@tanstack/vue-query';
import { AxiosError } from 'axios';
import { toast } from 'vue3-toastify';
import { useSessionsStore } from '@/store/sessions.store';
import LoadingSpinner from './icons/LoadingSpinner.vue';
import { storeToRefs } from 'pinia';
import CardOnGradient from '@/components/CardOnGradient.vue';
import ImmersveIcon from './icons/ImmersveIcon.vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useAccountActivationStore } from '../store/accountActivation.store';

const router = useRouter();

const customCardExpiration = parseInt(config.CUSTOM_CARD_EXPIRATION) || 0;
const customCardRegion = config.CUSTOM_CARD_REGION;

const sessionsStore = useSessionsStore();
const accountActivationStore = useAccountActivationStore();

const { currentSession } = storeToRefs(sessionsStore);
const { currentActivationDetails } = storeToRefs(accountActivationStore);

const cardProgramId = getConfigCardProgramId();

const { mutate: createCard, isLoading: isCreatingCard } = useMutation({
  mutationFn: async () => {
    const expiresAt =
      customCardExpiration > 0
        ? addDays(new Date(), customCardExpiration).toISOString()
        : undefined;
    const cardRegionOverride = customCardRegion || undefined;
    const card = await apiClient.createCard({
      cardProgramId,
      fundingSourceId: currentSession.value?.fundingSourceId || '',
      expiresAt,
    },
    { cardRegionOverride }
    );

    return card;
  },

  async onSuccess(card) {
    const cardId = 'id' in card ? card.id : card.cardId;
    router.push(`/card/${cardId}/created`);
  },

  onError(error) {
    if (error instanceof AxiosError && error.response?.data?.errorCode === 'AML_REVIEW_REQ') {
      return toast.error(
        "Sorry, we're unable to create a card at this time. Please contact support for help."
      );
    }

    return toast.error('Something went wrong. Please try again later');
  }
});

const isBusy = computed(
  () => currentActivationDetails.value?.kycStatus === 'loading' || isCreatingCard.value
);
</script>

<template>
  <div
    class="flex flex-col w-full mt-8 mx-auto items-center md:flex-row md:h-full md:items-stretch md:justify-center"
  >
    <div v-if="isBusy" class="flex flex-col justify-center h-full">
      <ImmersveIcon />
    </div>
    <CardOnGradient v-if="!isBusy" class="w-full md:w-1/2 md:ml-40 md:max-w-lg" />
    <div v-if="!isBusy" class="container max-w-md mx-14 flex flex-col justify-evenly md:w-1/2 px-3">
      <div>
        <p class="text-sm py-2 text-charcoal-200 select-none font-bold">CREATE CARD</p>
        <p class="text-3xl py-2 text-charcoal-200 select-none font-bold">
          You're seconds away from owning an Immersve Mastercard
        </p>
        <ButtonRounded class="w-full mt-5" @click="createCard()" :disabled="isCreatingCard">
          <LoadingSpinner class="mx-auto" v-if="isCreatingCard" />
          <span v-else class="text-base font-medium">Create card</span>
        </ButtonRounded>
      </div>
    </div>
  </div>
</template>

<style scoped>
.custom-gradient-radial {
  background: radial-gradient(circle at top, rgba(8, 23, 47, 0) 25%, rgba(20, 46, 138, 0.7) 100%);
}
</style>
