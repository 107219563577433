<script setup lang="ts">
import ImmersveTranslucentIcon from '@/components/icons/ImmersveTranslucentIcon.vue';
import ButtonX from '@/components/ButtonX.vue';
import { useUiStore } from '@/store/ui.store';

const uiState = useUiStore();
</script>

<template>
  <div
    class="relative flex h-10 min-w-0 flex-1 items-center justify-between rounded-lg bg-gradient-primary pl-6 pr-2"
  >
    <p class="overflow-hidden text-ellipsis whitespace-nowrap text-base font-normal">
      Spend your crypto at over 70+ million locations globally
    </p>
    <div class="flex">
      <ImmersveTranslucentIcon class="mr-2" />
      <ButtonX @click="uiState.toggleMarketingBarVisible" />
    </div>
  </div>
</template>
