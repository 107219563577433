<script setup lang="ts">
import { Card } from '@/models/Card';
import { upperFirstChar } from '@/utils/formatString';

defineProps<{
  card: Card;
}>();

const showDot = (card) => {
  return ['active', 'cancelled'].includes(card.status) || card.isExpired || card.isBlocked;
};

const getCardStatusText = (card) => {
  if (card.isBlocked) {
    return 'Frozen';
  }
  if (card.isExpired) {
    return 'Expired';
  }
  return upperFirstChar(card.status);
};
</script>

<template>
  <div class="flex gap-2 items-center">
    <div
      v-if="showDot(card)"
      class="w-2 h-2 rounded-full"
      :class="{
        'bg-utility-success-green-300': card.status === 'active',
        'bg-utility-error-red-200': card.status === 'cancelled' || card.isExpired,
        'bg-charcoal-500': card.isBlocked
      }"
    ></div>
    <p class="text-sm text-charcoal-200">{{ getCardStatusText(card) }}</p>
  </div>
</template>
