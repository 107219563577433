<script setup lang="ts">
import { computed } from 'vue';
import ProgressStep from '@/components/ProgressStep.vue';
import { ArrowLeftIcon, ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/solid';

import NavigationList from '@/components/NavigationList.vue';
import { config } from '@/config';

const params = defineProps<{
  stage: 'sign' | 'deposit' | 'success';
  transactionHash?: string;
}>();
defineEmits<{ (e: 'back'): void }>();

const isSignStepActive = computed(() => params.stage === 'sign');
const isSignStepComplete = computed(() => params.stage !== 'sign');
const isDepositStepActive = computed(() => params.stage === 'deposit');
const isDepositStepComplete = computed(() => params.stage === 'success');

function openTransactionUrl() {
  let explorerUrl = `https://${config.ENV === 'prod' ? '' : 'mumbai.'}polygonscan.com/tx/${params.transactionHash}`;
  if (config.CHAIN_ID === '80002') {
    explorerUrl = `https://www.oklink.com/amoy/tx/${params.transactionHash}`;
  }
  window.open(explorerUrl, '_blank');
}
</script>

<template>
  <div class="mt-12 max-w-xl mx-auto px-4">
    <p class="text-3xl py-2 text-charcoal-200 select-none">Deposit progress</p>
    <div class="mt-4">
      <ProgressStep number="1" :active="isSignStepActive" :complete="isSignStepComplete">
        Sign transaction with your wallet
        <template v-if="!!params.transactionHash" #rightButton>
          <button
            class="flex items-center justify-center select-none px-2 h-12 text-base w-fit"
            @click="openTransactionUrl"
          >
            <p class="mr-1 text-center text-base font-bold text-charcoal-400">View</p>
            <ArrowTopRightOnSquareIcon class="h-5" />
          </button>
        </template>
      </ProgressStep>

      <ProgressStep
        v-if="!config.FAST_DEPOSIT_ENABLED"
        number="2"
        :active="isDepositStepActive"
        :complete="isDepositStepComplete"
        >Deposit in progress</ProgressStep
      >
      <ProgressStep
        v-if="!config.FAST_DEPOSIT_ENABLED"
        number="3"
        :active="false"
        :complete="isDepositStepComplete"
        >Deposit successful</ProgressStep
      >
    </div>
    <NavigationList v-if="isDepositStepComplete" />
    <button
      v-else
      class="flex items-center mt-8 select-none h-12 px-2 text-base font-semibold w-fit"
      @click="$emit('back')"
    >
      <ArrowLeftIcon class="h-5" />
      <p class="ml-2 text-charcoal-200 font-semibold">Back</p>
    </button>
  </div>
</template>
