import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import piniaPersistPlugin from 'pinia-plugin-persistedstate';
import store from '@/store';
import '@/web3/client';
import { web3Plugin } from '@/plugins/web3.plugin';
import { Buffer } from 'buffer';
import { VueQueryPlugin } from '@tanstack/vue-query';
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import * as Sentry from '@sentry/vue';
import { config } from '@/config';

window.Buffer = window.Buffer || Buffer;

store.use(piniaPersistPlugin);

const app = createApp(App);

const ENVIRONMENT = config.ENV || 'local';
const ENABLED = config.SENTRY_ENABLED;
const BASE_URL = config.API_BASE_URL;
const release =
  document?.querySelector('meta[name="release"]')?.getAttribute('content') || undefined;

Sentry.init({
  app,
  enabled: ENABLED,
  environment: ENVIRONMENT,
  dsn: config.SENTRY_DSN,
  tunnel: `${BASE_URL}/sentry-tunnel`,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay({
      maskAllInputs: true
    })
  ],
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,
  normalizeDepth: 10,
  release
});

app.use(store);
app.use(router);
app.use(web3Plugin);
app.use(VueQueryPlugin, {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false
      }
    }
  }
});
app.use(Vue3Toastify, {
  autoClose: 3000,
  clearOnUrlChange: false
} as ToastContainerOptions);

app.mount('#app');
