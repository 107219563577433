<script setup lang="ts">
import apiClient from '@/apiClient';
import CardManageWidget from '@/components/CardManageWidget.vue';
import LayoutContent from '@/components/LayoutContent.vue';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';
import { useMutation, useQuery } from '@tanstack/vue-query';
import { useRoute, useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';
import Transaction from '@/models/Transaction';
import { useSessionsStore } from '@/store/sessions.store';
import { storeToRefs } from 'pinia';
import TransactionTableLegacy from '@/components/TransactionTableLegacy.vue';
const sessions = useSessionsStore();
const { currentSession } = storeToRefs(sessions);
const route = useRoute();

const {
  data: cardDetail,
  isFetching,
  refetch: refetchCardDetail
} = useQuery({
  queryKey: ['get-card', route.params.cardId],
  queryFn: () => {
    return apiClient.getCard(route.params.cardId as string);
  }
});

async function fetchAllTransactions(accountId: string, cardId: string) {
  let cursor: string | undefined = undefined;
  let items: Transaction[] = [];
  do {
    const response = await apiClient.listTransactions(accountId, { limit: 1000, cursor, cardId });
    items = items.concat(response.items);
    cursor = response.pageInfo?.nextCursor;
  } while (cursor);
  return items;
}

const { data: transactions, isFetching: isLoadingTransactions } = useQuery({
  queryKey: ['all-transactions-per-card', currentSession, route.params.cardId],
  queryFn: () => {
    if (!currentSession.value?.user.accountId) {
      return [];
    }

    return fetchAllTransactions(
      currentSession.value?.user.accountId!,
      route.params.cardId as string
    );
  }
});

const { mutate: cancelCard, isLoading: isCardCancelling } = useMutation({
  mutationFn: async (cardId: string) => {
    await apiClient.cancelCard(cardId);
  },
  onSuccess() {
    toast.success('Card cancelled');
    refetchCardDetail();
  },
  onError(error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return toast.error('Something went wrong. Please try again later');
  }
});

const onCancelClicked = (cardId: string) => {
  cancelCard(cardId);
};
const router = useRouter();
const onSetPinClicked = (cardId: string) => {
  router.push(`/card/${cardId}/set-pin`);
};
</script>

<template>
  <LayoutContent class="md:mx-2 !px-0 md:px-4 flex flex-col items-center">
    <LoadingSpinner v-if="isFetching || isCardCancelling" class="mt-10"></LoadingSpinner>
    <CardManageWidget
      v-if="!isFetching && cardDetail && !isCardCancelling"
      :card="cardDetail"
      @on-cancel-clicked="onCancelClicked"
      @on-set-pin-clicked="onSetPinClicked"
    />
    <div class="pt-4 flex flex-col w-full md:w-10/12" v-if="!isFetching && !isCardCancelling">
      <p class="p-2 text-base text-charcoal-200">Transactions</p>
      <div class="w-full flex flex-col">
        <LoadingSpinner v-if="isLoadingTransactions" class="mt-10"></LoadingSpinner>
        <!-- Use this old component since we can't discriminate funding source interactiosn by card currently -->
        <TransactionTableLegacy v-else :transactions="transactions ?? []" class="w-full" />
      </div>
    </div>
  </LayoutContent>
</template>
