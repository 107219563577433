<script setup lang="ts">
import { format } from 'date-fns';
import {
  breakpointsTailwind,
  useBreakpoints,
  useIntersectionObserver,
  whenever
} from '@vueuse/core';
import { FundingSourceInteraction } from '@/models/FundingSourceInteraction';
import { computed, ref } from 'vue';
import LoadingSpinner from './icons/LoadingSpinner.vue';
import { InteractionStatus } from '@/apiClient/types/fundingSourceInteraction';

const props = defineProps<{
  fundingSourceInteractions: FundingSourceInteraction[];
  loading?: boolean;
  pageSize?: number;
  displayLimit?: number;
}>();

const emit = defineEmits<{
  (e: 'reachBottom'): void;
}>();
const observed = ref<HTMLElement | null>(null);
const targetIsVisible = ref(false);
const readyToLoad = computed(() => {
  return targetIsVisible.value && !props.loading;
});

useIntersectionObserver(observed, ([{ isIntersecting }]) => {
  targetIsVisible.value = isIntersecting;
});

whenever(readyToLoad, () => {
  emit('reachBottom');
});

const breakpoints = useBreakpoints(breakpointsTailwind);
const isLgOrUp = breakpoints.greaterOrEqual('lg');
const { href: polygonLogo } = new URL('@/assets/icons/polygon.svg', import.meta.url);

const shortenedDescription = (description: String) => {
  if (description.length < 18) {
    return description;
  }
  return description.slice(0, 16) + '...';
};
</script>

<template>
  <div class="w-full">
    <div v-if="!fundingSourceInteractions.length && loading" class="flex justify-center mt-2">
      <LoadingSpinner aria-label="loading transactions" />
    </div>
    <div v-else-if="!fundingSourceInteractions.length" class="pt-8 lg:pl-4">
      There are no transactions.
    </div>
    <table role="table" aria-label="transactions-list" class="w-full" v-else>
      <thead class="hidden lg:table-header-group border-b border-gray-800 h-full">
        <tr class="text-left text-sm">
          <th class="p-4 font-bold">Transaction Date</th>
          <th class="font-bold">Description</th>
          <th class="font-bold">Amount</th>
          <th class="font-bold">Chain</th>
        </tr>
      </thead>
      <tbody v-if="isLgOrUp">
        <tr
          v-for="fundingSourceInteraction in props.fundingSourceInteractions.slice(
            0,
            props.displayLimit
          )"
          :key="fundingSourceInteraction.id"
          class="border-b border-gray-800 text-sm"
          :data-test-id="`fundingSourceInteraction-${fundingSourceInteraction.id}`"
        >
          <td class="p-4">
            {{ format(fundingSourceInteraction.createdAt, 'MMM dd, yyyy') }}
          </td>
          <td>
            {{ fundingSourceInteraction.description || fundingSourceInteraction.type }}
            <span v-if="fundingSourceInteraction.status === InteractionStatus.Pending">
              (Pending)</span
            >
          </td>
          <td>
            {{ fundingSourceInteraction.formattedAmount }}
            {{ fundingSourceInteraction.token }}
          </td>
          <td>
            <!-- TODO: hard coded to Polygon logo for now, need to display other logos as we add support for more chains -->
            <div class="flex flex-row items-center">
              <img class="h-8 min-w-fit" :src="polygonLogo" alt="Polygon Logo" />
              <p class="px-4">Polygon</p>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr
          v-for="fundingSourceInteraction in props.fundingSourceInteractions.slice(
            0,
            props.displayLimit
          )"
          :key="fundingSourceInteraction.id"
          class="border-b border-gray-800"
          :data-test-id="`fundingSourceInteraction-${fundingSourceInteraction.id}`"
        >
          <td class="p-4">
            <div class="text-sm text-stone-400">
              {{ format(fundingSourceInteraction.createdAt, 'MMM dd, yyyy') }}
            </div>
            <div>
              {{
                shortenedDescription(
                  fundingSourceInteraction.description || fundingSourceInteraction.type
                )
              }}
              <span v-if="fundingSourceInteraction.status === InteractionStatus.Pending">
                (Pending)</span
              >
            </div>
          </td>
          <td class="text-right text-base">
            {{ fundingSourceInteraction.formattedAmount }} {{ fundingSourceInteraction.token }}
          </td>
          <td>
            <!-- TODO: hard coded to Polygon logo for now, need to display other logos as we add support for more chains -->
            <img class="h-4 min-w-fit ps-2" :src="polygonLogo" alt="Polygon Logo" />
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr v-if="loading">
          <td colspan="5">
            <div class="flex justify-center mt-2">
              <LoadingSpinner aria-label="loading transactions" />
            </div>
          </td>
        </tr>
        <tr>
          <td ref="observed"></td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
