import { useQuery } from '@tanstack/vue-query';
import { useSessionsStore } from '@/store/sessions.store';
import apiClient from '@/apiClient';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { getConfigFundingChannelId } from '@/config';

export const useFundingSource = () => {
  const sessionsStore = useSessionsStore();
  const { currentSession } = storeToRefs(sessionsStore);

  const fundingChannelId = getConfigFundingChannelId();

  const fundingSourceId = ref<string>('');

  function setFundingSourceId(value: string) {
    fundingSourceId.value = value;
    sessionsStore.setFundingSourceId(value);
  }

  async function listFundingSources() {
    if (!sessionsStore.currentSession?.user.accountId) {
      return [];
    }
    return await apiClient.listFundingSources(sessionsStore.currentSession.user.accountId);
  }

  async function createFundingSource() {
    return await apiClient.createFundingSource({
      accountId: sessionsStore.currentSession!.user.accountId,
      fundingAddress: sessionsStore.currentSession!.user.walletAddress,
      fundingChannelId
    });
  }

  const {
    data: fundingSource,
    isLoading,
    refetch
  } = useQuery({
    queryKey: ['get-or-create-funding-source', currentSession?.value],
    queryFn: async () => {
      const fundingSourcesResponse = await listFundingSources();
      const fundingSources = fundingSourcesResponse.filter(
        (fs) => fs.fundingChannelId === fundingChannelId
      );
      if (!fundingSources.length) {
        return await createFundingSource();
      }
      return fundingSources[0];
    },
    enabled: false
  });

  watch(
    [() => currentSession.value?.user.accountId, () => currentSession.value?.fundingSourceId],
    ([accountId, fundingSourceId], [oldAccountId]) => {
      if (!accountId) {
        return;
      }
      if (oldAccountId === accountId && !!fundingSourceId) {
        return;
      }
      refetch();
    },
    { immediate: true }
  );

  watch(fundingSource, (fundingSource) => {
    if (!fundingSource) {
      return;
    }
    fundingSourceId.value = fundingSource.id;
    setFundingSourceId(fundingSource.id);
  });

  return {
    isLoading,
    fundingSourceId
  };
};
