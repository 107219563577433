<script setup lang="ts">
import { watch } from 'vue';
import { RouterView, useRouter } from 'vue-router';
import * as Sentry from '@sentry/vue';
import { useSessionsStore } from '@/store/sessions.store';
import { usePrerequisites } from '@/composables/prerequisitesHook';
import { VueQueryDevtools } from '@tanstack/vue-query-devtools';
import { useAccountActivationStore } from './store/accountActivation.store';

const sessions = useSessionsStore();
const accountActivationStore = useAccountActivationStore();
const router = useRouter();

const { refetchPrerequisites } = usePrerequisites();
accountActivationStore.setRefetchPrerequisites(refetchPrerequisites);

watch(sessions, () => {
  if (!sessions.currentSession || !sessions.currentSession.token) {
    router.push('/login');
  }
});

watch(
  sessions,
  () => {
    const user = sessions.currentSession?.user;
    if (user) {
      Sentry.setUser({
        id: user.id,
        accountId: user.accountId,
        walletAddress: user.walletAddress
      });
    }
  },
  { immediate: true }
);
</script>

<template>
  <RouterView name="sidenav" />
  <RouterView name="header" />
  <RouterView />
  <VueQueryDevtools />
</template>
