<script setup lang="ts">
import WithdrawWidget from '@/components/WithdrawWidget.vue';
import { reactive, watch, h, computed } from 'vue';
import { useMutation } from '@tanstack/vue-query';
import apiClient from '@/apiClient';
import { ToastOptions, toast } from 'vue3-toastify';
import { AxiosError } from 'axios';
import { executeWithdrawalIntent } from '@/web3/interactions';
import { useRouter } from 'vue-router';
import { useFundingStore } from '@/store/funding.store';
import ImmersveCustomToast from '@/components/ImmersveCustomToast.vue';
import { toastStyle } from '@/constants';
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';

const params = reactive<{
  stage: 'signed' | '';
  token: string;
  amount: string;
}>({
  stage: '',
  token: '',
  amount: ''
});
function onWithdraw(amount: string, token: string) {
  if (!fundingStore.fundingSource) {
    return;
  }
  if (params.stage !== '') {
    return;
  }
  params.stage = 'signed';
  params.token = token;
  params.amount = amount;
  withdrawFundingSource();
}
const breakpoints = useBreakpoints(breakpointsTailwind);
const isLgOrUp = breakpoints.greaterOrEqual('lg');
const fundingStore = useFundingStore();
const tryAgainClicked = () => {
  router.push('/withdraw');
};

const toastOptions = computed(() => ({
  autoClose: 300000,
  closeOnClick: false,
  position: isLgOrUp.value ? 'top-right' : 'top-center',
  hideProgressBar: true,
  toastStyle,
  theme: 'dark'
}));

const { mutate: withdrawFundingSource } = useMutation({
  mutationFn: async () => {
    const response = await apiClient.createWithdrawIntent({
      fundingSourceId: fundingStore.fundingSource?.id!,
      amount: Number(params.amount)
    });
    await executeWithdrawalIntent(
      {
        ...response.execution
      },
      () => {
        toast(
          () =>
            h(ImmersveCustomToast, {
              toastName: 'Withdrawal',
              status: 'pending',
              msg: 'It can take a few moments for the withdrawal to process.'
            }),
          { ...toastOptions.value, toastId: 'toast-in-progress' } as ToastOptions
        );
      }
    );
  },
  onSuccess() {
    fundingStore.refetchFundingSource();
    fundingStore.triggerFundingSourceInteractionRefetch();
    toast(
      () =>
        h(ImmersveCustomToast, {
          toastName: 'Withdrawal',
          status: 'successful',
          msg: 'The withdrawal was successful.'
        }),
      toastOptions.value as ToastOptions
    );
    toast.update('toast-in-progress', {
      autoClose: 0
    });
  },
  onError(error) {
    /*
     * The withdraw intent API does not currently return these error codes
     * This will be implemented with:
     * https://www.notion.so/immersve/Crypto-AML-CFT-Freeze-Account-3956a418577e4908924090fa2bc4ee5b?pvs=4
     */
    if (error instanceof AxiosError && error.response?.data?.errorCode === 'AML_REVIEW_REQ') {
      const customToast = h(ImmersveCustomToast, {
        toastName: 'Withdrawal',
        status: 'failed',
        msg: "Sorry, we're unable to make a withdrawal at this time. Please contact support for help.",
        tryAgainClicked
      });
      return toast(customToast, toastOptions.value as ToastOptions);
    }

    const customToast = h(ImmersveCustomToast, {
      toastName: 'Withdrawal',
      status: 'failed',
      msg: 'We were unable to process the withdrawal.',
      tryAgainClicked
    });

    return toast(customToast, toastOptions.value as ToastOptions);
  }
});
const router = useRouter();

watch(
  () => params.stage,
  (newStage) => {
    if (newStage === 'signed') {
      router.push('/dashboard');
    }
  }
);
</script>

<template>
  <div class="mt-12 max-w-xl mx-auto px-4">
    <WithdrawWidget v-if="params.stage === ''" @on-withdraw="onWithdraw" />
  </div>
</template>
