import { formatUnits } from 'viem';
import { FundingSourceResponseItem, FundingSourceType } from '@/apiClient/types/fundingSource';

export interface FundingSourceProps {
  id: string;
  createdAt: Date;
  balance: string;
  balanceCurrency: string;
  fundingSourceType?: FundingSourceType;
  accountId: string;
  timeoutBlockNumber: number;
  externalId?: string;
  fundingChannelId?: string;
}

class FundingSource implements FundingSourceProps {
  id: string;
  createdAt: Date;
  balance: string;
  balanceCurrency: string;
  fundingSourceType?: FundingSourceType;
  accountId: string;
  timeoutBlockNumber: number;
  externalId?: string;
  fundingChannelId?: string;

  constructor(props: FundingSourceProps) {
    this.id = props.id;
    this.createdAt = props.createdAt;
    this.balance = props.balance;
    this.balanceCurrency = props.balanceCurrency;
    this.fundingSourceType = props.fundingSourceType;
    this.accountId = props.accountId;
    this.timeoutBlockNumber = props.timeoutBlockNumber;
    this.externalId = props.externalId;
    this.fundingChannelId = props.fundingChannelId;
  }

  get balanceMajor() {
    const balance = formatUnits(BigInt(this.balance), 6);
    return Number(balance).toFixed(2);
  }

  static fromApiResponse(props: FundingSourceResponseItem) {
    return new FundingSource({
      ...props,
      createdAt: new Date(props.createdAt)
    });
  }
}

export { FundingSource };
