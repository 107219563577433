<script setup lang="ts">
import apiClient from '@/apiClient';
import NavigationList from '@/components/NavigationList.vue';
import NewCard from '@/components/icons/NewCard.vue';
import { useQuery } from '@tanstack/vue-query';
import { useRoute } from 'vue-router';
import { config } from '@/config';

const fetchCardOnCreationEnabled = config.FETCH_CARD_ON_CREATION_ENABLED;

const route = useRoute();
const cardId = route.params.cardId.toString();

useQuery({
  queryKey: ['get-card', cardId],
  queryFn: () => apiClient.getCard(cardId),
  enabled: fetchCardOnCreationEnabled && !!cardId
});
</script>

<template>
  <div class="flex flex-col mt-12 max-w-xl mx-auto px-4 pt-4">
    <NewCard />
    <p class="text-4xl py-8 text-charcoal-200 select-none">Your card has been created</p>
    <p class="text-charcoal-200 text-md">
      You can now start spending your digital cash anywhere that accepts Mastercard
    </p>

    <NavigationList :activate-account-hidden="true" :set-pin-url="'/card/' + cardId + '/set-pin'" />
  </div>
</template>
