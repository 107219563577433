<script setup lang="ts">
import ImmersveTranslucentIcon from '@/components/icons/ImmersveTranslucentIcon.vue';
import { computed } from 'vue';

const isVisible = computed(() => {
  // TODO use config
  return false;
});
</script>

<template>
  <div
    v-if="isVisible"
    class="relative flex items-center justify-between rounded-lg bg-gradient-primary pl-6 pr-2 my-5"
  >
    <p class="my-1 text-base font-normal">
      ⚠️ We are currently experiencing significant delays with balance updates.
    </p>

    <div class="flex">
      <ImmersveTranslucentIcon class="mr-2" />
    </div>
  </div>
</template>
