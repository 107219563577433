<script setup lang="ts">
import LayoutContent from '@/components/LayoutContent.vue';
import { useSessionsStore } from '@/store/sessions.store';
import PlusCircleIcon from '@heroicons/vue/24/outline/PlusCircleIcon';
import { useQuery } from '@tanstack/vue-query';
import { storeToRefs } from 'pinia';
import apiClient from '@/apiClient';
import { CardStatus } from '@/types/card';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';
import CardTable from '@/components/CardTable.vue';
import { computed } from 'vue';
import KycStatusBar from '@/components/KycStatusBar.vue';
import router from '@/router';
import { useAccountActivationStore } from '@/store/accountActivation.store';

const sessions = useSessionsStore();
const { currentSession } = storeToRefs(sessions);

const accountActivationStore = useAccountActivationStore();

const isKycFailed = computed(
  () => accountActivationStore.currentActivationDetails?.kycStatus === 'failed'
);
const isKycPending = computed(
  () => accountActivationStore.currentActivationDetails?.kycStatus === 'pending'
);

const isCreateCardDisabled = computed(() => isKycFailed.value || isKycPending.value);

const { data: cards, isFetching } = useQuery({
  queryKey: ['list-active-cards', currentSession],
  queryFn: async () => {
    if (!currentSession.value?.user.accountId) {
      return {
        items: []
      };
    }

    const activeCards = await apiClient.listCards(currentSession.value?.user.accountId!, {
      status: CardStatus.ACTIVE,
      excludeExpired: true,
    });
    const cancelledCards = await apiClient.listCards(currentSession.value?.user.accountId!, {
      status: CardStatus.CANCELLED,
      excludeExpired: true,
    });
    return {
      items: [...activeCards.items, ...cancelledCards.items]
    };
  },
  initialData: {
    items: []
  }
});

function onCardSelected(cardId: string) {
  router.push(`/card/${cardId}`);
}
</script>

<template>
  <LayoutContent class="mx-2">
    <KycStatusBar />
    <div
      data-testid="cards-page-header"
      class="flex flex-col md:flex-row item-start md:items-center gap-y-4 p-y-4 gap-x-16"
    >
      <p class="font-semibold text-2xl text-charcoal-200">Manage cards</p>
      <RouterLink
        v-if="!isCreateCardDisabled"
        to="/new-card"
        class="h-9 w-fit px-4 rounded-full bg-primary-purple-100 flex items-center justify-center"
      >
        <PlusCircleIcon class="w-6 text-charcoal-900 mr-2" />
        <p class="text-base text-charcoal-900">Create new card</p>
      </RouterLink>
    </div>
    <div v-if="isFetching" class="flex w-full justify-center">
      <LoadingSpinner class="mt-20"></LoadingSpinner>
    </div>
    <CardTable v-else :cards="cards.items" @select-card="onCardSelected" />
  </LayoutContent>
</template>
