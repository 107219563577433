import type { Provider } from '@/config/types';

class JsonStorageProvider<ConfigSchema> implements Provider<ConfigSchema> {
  private storageKey: string;
  private storage: Storage;
  private config: ConfigSchema;

  constructor(props: { storageKey: string; storage: Storage }) {
    const { storage, storageKey } = props;
    this.storage = storage;
    this.storageKey = storageKey;
    this.config = JSON.parse(this.storage.getItem(storageKey) || '{}');
  }

  getValue(name: keyof ConfigSchema) {
    return this.config[name];
  }

  saveValue(name: string, value: ConfigSchema[keyof ConfigSchema]) {
    if (value === undefined) {
      delete this.config[name];
    } else {
      this.config[name] = value;
    }
    this.storage.setItem(this.storageKey, JSON.stringify(this.config));
  }

  reset() {
    this.config = {} as ConfigSchema;
    this.storage.removeItem(this.storageKey);
  }
}

export { JsonStorageProvider };
