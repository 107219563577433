import { Plugin } from 'vue';

import { watchAccount, watchNetwork } from '@wagmi/core';
import { useSessionsStore } from '@/store/sessions.store';

const web3Plugin: Plugin = {
  install() {
    const sessionsState = useSessionsStore();

    watchNetwork((network) => {
      // eslint-disable-next-line no-console
      console.debug('network changed', network.chain);
      sessionsState.currentChainId = network.chain?.id || null;
    });

    watchAccount((account) => {
      // eslint-disable-next-line no-console
      console.debug('account changed', account);
      sessionsState.currentAccount = account.address || null;
    });
  }
};

export { web3Plugin };
