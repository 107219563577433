import apiClient from '@/apiClient';
import { useQuery } from '@tanstack/vue-query';
import { defineStore, storeToRefs } from 'pinia';
import { useSessionsStore } from './sessions.store';
import { getConfigFundingChannelId } from '@/config';

export const useFundingStore = defineStore('funding', () => {
  const sessions = useSessionsStore();
  const { currentSession } = storeToRefs(sessions);
  let refetchFundingSourceInteractions: () => void;

  const {
    data: fundingSource,
    isFetching: isFundingSourceFetching,
    refetch: refetchFundingSource
  } = useQuery({
    queryKey: ['list-funding-sources', currentSession],
    queryFn: async () => {
      if (!currentSession.value?.user.accountId) {
        return [];
      }

      return apiClient.listFundingSources(currentSession.value?.user.accountId);
    },
    select(data) {
      return data.find((fs) => fs.fundingChannelId === getConfigFundingChannelId());
    },
    refetchInterval: 10000
  });

  function setFundingSourceInteractionRefetch(refetch) {
    refetchFundingSourceInteractions = refetch;
  }

  function triggerFundingSourceInteractionRefetch() {
    if (refetchFundingSourceInteractions) {
      refetchFundingSourceInteractions();
    }
  }

  return {
    fundingSource,
    isFundingSourceFetching,
    refetchFundingSource,
    setFundingSourceInteractionRefetch,
    triggerFundingSourceInteractionRefetch
  };
});
