import { formatUnits } from 'ethers';
import { CURRENCY_DETAILS } from '@/constants';

export function convertMinorToMajorUnit(minorAmount: number, currencyCode: string): number {
  const currencyDetails = CURRENCY_DETAILS[currencyCode as keyof typeof CURRENCY_DETAILS];

  if (!currencyDetails) {
    return minorAmount;
  }

  const decimalPlaces = parseInt(currencyDetails.decimals);
  return parseFloat(formatUnits(minorAmount, decimalPlaces));
}

export function convertMajorToMinorUnit(majorAmount: number, currencyCode: string): number {
  const currencyDetails = CURRENCY_DETAILS[currencyCode as keyof typeof CURRENCY_DETAILS];

  if (!currencyDetails) {
    return majorAmount;
  }

  const decimalPlaces = parseInt(currencyDetails.decimals);
  const minorAmount = Math.round(majorAmount * 10 ** decimalPlaces);
  return minorAmount;
}

export const convertFiatToToken = ({
  amount,
  fiatCurrency,
  tokenCurrency
}: {
  amount: number;
  fiatCurrency: string;
  tokenCurrency: string;
}): number => {
  const fiatAmountMajorUnits = convertMinorToMajorUnit(amount, fiatCurrency);
  const tokenAmountMajorUnits = fiatAmountMajorUnits; //right now it's 1:1 ratio between USD and USDC
  return convertMajorToMinorUnit(tokenAmountMajorUnits, tokenCurrency);
};

export const convertUsdcToUsd = ({ amountMinorUnits }: { amountMinorUnits: number }): number => {
  const tokenAmountMajorUnits = convertMinorToMajorUnit(amountMinorUnits, 'USDC');
  const fiatAmountMajorUnits = tokenAmountMajorUnits; //right now it's 1:1 ratio between USD and USDC
  return convertMajorToMinorUnit(fiatAmountMajorUnits, 'USD');
};

export function formatAmount({
  value,
  currency,
  isMinorUnits = true
}: {
  value: number;
  currency: string;
  isMinorUnits?: boolean;
}): string {
  if (isMinorUnits) {
    value = convertMinorToMajorUnit(value, currency);
  }
  const formatter = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 6,
    minimumIntegerDigits: 1,
    style: 'decimal'
  });

  return formatter.format(value);
}
