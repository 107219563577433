<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M9.59421 3.94C9.68421 3.398 10.1542 3 10.7042 3H13.2972C13.8472 3 14.3172 3.398 14.4072 3.94L14.6202 5.221C14.6832 5.595 14.9332 5.907 15.2652 6.091C15.3392 6.131 15.4122 6.174 15.4852 6.218C15.8092 6.414 16.2052 6.475 16.5602 6.342L17.7772 5.886C18.0266 5.79221 18.3012 5.78998 18.5521 5.87971C18.803 5.96945 19.0139 6.14531 19.1472 6.376L20.4432 8.623C20.5763 8.8537 20.6232 9.12413 20.5756 9.38617C20.5279 9.6482 20.3889 9.88485 20.1832 10.054L19.1802 10.881C18.8872 11.121 18.7422 11.494 18.7492 11.873C18.7508 11.958 18.7508 12.043 18.7492 12.128C18.7422 12.506 18.8872 12.878 19.1792 13.118L20.1842 13.946C20.6082 14.296 20.7182 14.9 20.4442 15.376L19.1462 17.623C19.0131 17.8536 18.8024 18.0296 18.5517 18.1195C18.301 18.2094 18.0266 18.2074 17.7772 18.114L16.5602 17.658C16.2052 17.525 15.8102 17.586 15.4842 17.782C15.4117 17.8261 15.3384 17.8688 15.2642 17.91C14.9332 18.093 14.6832 18.405 14.6202 18.779L14.4072 20.059C14.3172 20.602 13.8472 21 13.2972 21H10.7032C10.1532 21 9.68321 20.602 9.59321 20.06L9.38021 18.779C9.31821 18.405 9.06821 18.093 8.73621 17.909C8.66205 17.8681 8.58871 17.8258 8.51621 17.782C8.19121 17.586 7.79621 17.525 7.44021 17.658L6.22321 18.114C5.97393 18.2075 5.69957 18.2096 5.4489 18.1199C5.19824 18.0302 4.98752 17.8545 4.85421 17.624L3.55721 15.377C3.42415 15.1463 3.37725 14.8759 3.42486 14.6138C3.47247 14.3518 3.6115 14.1152 3.81721 13.946L4.82121 13.119C5.11321 12.879 5.25821 12.506 5.25121 12.127C5.24964 12.042 5.24964 11.957 5.25121 11.872C5.25821 11.494 5.11321 11.122 4.82121 10.882L3.81721 10.054C3.61175 9.88489 3.47288 9.64843 3.42528 9.38662C3.37768 9.12481 3.42442 8.8546 3.55721 8.624L4.85421 6.377C4.98739 6.14614 5.19822 5.97006 5.44912 5.88014C5.70002 5.79021 5.97469 5.79229 6.22421 5.886L7.44021 6.342C7.79621 6.475 8.19121 6.414 8.51621 6.218C8.58821 6.174 8.66221 6.131 8.73621 6.09C9.06821 5.907 9.31821 5.595 9.38021 5.221L9.59421 3.94Z"
      stroke="#BDBDC4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 9.75C11.4033 9.75 10.831 9.98705 10.409 10.409C9.98705 10.831 9.75 11.4033 9.75 12C9.75 12.5967 9.98705 13.169 10.409 13.591C10.831 14.0129 11.4033 14.25 12 14.25C12.5967 14.25 13.169 14.0129 13.591 13.591C14.0129 13.169 14.25 12.5967 14.25 12C14.25 11.4033 14.0129 10.831 13.591 10.409C13.169 9.98705 12.5967 9.75 12 9.75ZM9.34835 9.34835C10.0516 8.64509 11.0054 8.25 12 8.25C12.9946 8.25 13.9484 8.64509 14.6517 9.34835C15.3549 10.0516 15.75 11.0054 15.75 12C15.75 12.9946 15.3549 13.9484 14.6517 14.6517C13.9484 15.3549 12.9946 15.75 12 15.75C11.0054 15.75 10.0516 15.3549 9.34835 14.6517C8.64509 13.9484 8.25 12.9946 8.25 12C8.25 11.0054 8.64509 10.0516 9.34835 9.34835Z"
      fill="#BDBDC4"
    />
  </svg>
</template>
