<script setup lang="ts">
import ImmersveTranslucentIcon from '@/components/icons/ImmersveTranslucentIcon.vue';
import { computed } from 'vue';
import { useAccountActivationStore } from '@/store/accountActivation.store';
import { useRedirectToKyc } from '@/composables/redirectToKycHook';

const accountActivationStore = useAccountActivationStore();
const { redirectToKyc } = useRedirectToKyc();

const isKycFailedWithUrl = computed(
  () =>
    accountActivationStore.currentActivationDetails?.kycStatus === 'failed' &&
    accountActivationStore.currentActivationDetails?.kycUrl
);
const isKycFailedWithoutUrl = computed(
  () =>
    accountActivationStore.currentActivationDetails?.kycStatus === 'failed' &&
    !accountActivationStore.currentActivationDetails?.kycUrl
);
const isKycPending = computed(
  () => accountActivationStore.currentActivationDetails?.kycStatus === 'pending'
);

const isVisible = computed(
  () => isKycFailedWithUrl.value || isKycFailedWithoutUrl.value || isKycPending.value
);
</script>

<template>
  <div
    v-if="isVisible"
    class="relative flex items-center justify-between rounded-lg bg-gradient-primary pl-6 pr-2 my-5"
    data-testid="kyc-status-bar"
  >
    <p
      v-if="isKycFailedWithoutUrl"
      class="my-1 text-base font-normal"
      data-testid="kyc-failed-no-url"
    >
      Your KYC check has failed. Creating cards has been disabled. Please contact
      support@immersve.com to resolve.
    </p>
    <p v-if="isKycFailedWithUrl" class="my-1 text-base font-normal" data-testid="kyc-failed">
      Your KYC check has failed. Creating cards has been disabled.
      <button @click="redirectToKyc" class="underline" data-testid="kyc-failed-try-again">
        Click here
      </button>
      to try again.
    </p>
    <p v-if="isKycPending" class="my-1 text-base font-normal" data-testid="kyc-pending">
      Your KYC check is still pending. Creating cards has been temporarily disabled. Please try
      again later.
    </p>
    <div class="flex">
      <ImmersveTranslucentIcon class="mr-2" />
    </div>
  </div>
</template>
