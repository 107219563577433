<script setup lang="ts">
import CardIcon from '@/components/icons/CardIcon.vue';
import SideNavLink from '@/components/SideNavLink.vue';
import AssetIcon from '@/components/icons/AssetIcon.vue';
import DashboardIcon from '@/components/icons/DashboardIcon.vue';
import ArrowCollapseLeft from '@/components/icons/ArrowCollapseLeft.vue';

const immersveLogo = new URL('@/assets/icons/ImmersveLogo.svg', import.meta.url).href;
const immersveIcon = new URL('@/assets/icons/ImmersveIcon.svg', import.meta.url).href;

defineEmits<{ (e: 'close'): void; (e: 'toggle-collapse'): void }>();
defineProps<{ collapsed?: boolean }>();
</script>

<template>
  <nav class="flex h-full flex-col overflow-y-auto bg-secondary-blue-1000 px-6 overflow-hidden">
    <div class="h-20 items-center flex">
      <button type="button" @click="$emit('close')">
        <ArrowCollapseLeft class="w-6 md:hidden" />
      </button>
      <router-link to="/">
        <img
          v-if="!collapsed"
          :class="`h-9 hidden md:block min-w-fit`"
          :src="immersveLogo"
          alt="Immersve logo"
        />
        <img
          v-if="collapsed"
          :class="`h-9 hidden md:block`"
          :src="immersveIcon"
          alt="Immersve logo"
        />
      </router-link>
    </div>
    <div class="flex flex-col mt-5 flex-1">
      <SideNavLink to="/" title="Dashboard" :collapsed="collapsed">
        <DashboardIcon class="w-5 h-5" />
      </SideNavLink>

      <SideNavLink to="/cards" title="Manage cards" :collapsed="collapsed">
        <CardIcon class="w-6" />
      </SideNavLink>

      <SideNavLink to="/transactions" title="Transactions" :collapsed="collapsed">
        <AssetIcon class="w-6" />
      </SideNavLink>
    </div>
    <button type="button" class="hidden md:block ml-2 mb-9">
      <ArrowCollapseLeft
        :class="`w-6  transition-all ${collapsed ? '-scale-x-100' : ''}`"
        @click="$emit('toggle-collapse')"
      />
    </button>
  </nav>
</template>
