import type { Provider } from '@/config/types';

class ObjectProvider<ConfigSchema> implements Provider<ConfigSchema> {
  constructor(private values: ConfigSchema) {}

  getValue(name: keyof ConfigSchema) {
    return this.values[name];
  }
}

export { ObjectProvider };
