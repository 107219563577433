import { formatAmount } from '@/utils/formatAmount';
import { TransactionResponse, TransactionStatus } from '@/apiClient/types/transaction';

interface TransactionProps {
  id: string;
  amount: number;
  creditDebitIndicator: string;
  currency: string;
  description: string;
  processedDate?: Date;
  status: TransactionStatus;
  transactionDate: Date;
  type: 'deposit' | 'withdrawal' | 'spend';
}

export default class Transaction implements TransactionProps {
  id: string;
  amount: number;
  creditDebitIndicator: string;
  currency: string;
  description: string;
  processedDate?: Date;
  status: TransactionStatus;
  transactionDate: Date;
  type: 'deposit' | 'withdrawal' | 'spend';

  constructor(props: TransactionProps) {
    this.id = props.id;
    this.amount = props.amount;
    this.creditDebitIndicator = props.creditDebitIndicator;
    this.currency = props.currency;
    this.description = props.description;
    this.processedDate = props.processedDate;
    this.status = props.status;
    this.transactionDate = props.transactionDate;
    this.type = props.type;
  }

  static fromTransaction(props: TransactionResponse) {
    return new Transaction({
      id: props.id,
      type: 'spend',
      amount: props.amount,
      creditDebitIndicator: props.creditDebitIndicator,
      description: props.description,
      currency: props.currency,
      processedDate: props.processedDate ? new Date(props.processedDate) : undefined,
      status: props.status,
      transactionDate: new Date(props.transactionDate),
    });
  }

  get formattedAmount() {
    const prefix = this.creditDebitIndicator === 'credit' ? '+' : '-';
    const fmtAmount = formatAmount({
      value: this.amount,
      currency: this.currency,
    });
    return [prefix, fmtAmount].join('');
  }
}
