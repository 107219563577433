<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  itemKey: string;
  displayValue: string;
  modelValue: string | boolean;
  // Value currently used by config
  currentValue: string | boolean;
  // Value from base config layer
  baseValue: string | boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | boolean): void;
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  }
});

const inputType = typeof props.modelValue === 'boolean' ? 'checkbox' : 'text';
</script>

<template>
  <div class="py-2">
    <div class="mb-3">
      <label class="text-xs font-bold text-charcoal-200" :for="`${itemKey}-input`"
        >{{ displayValue }}
        <span
          class="text-utility-blue-300 select-none"
          v-if="modelValue !== baseValue && modelValue === currentValue"
          >Non-default</span
        >
        <span class="text-utility-blue-300 select-none" v-if="modelValue !== currentValue"
          >Not saved</span
        >
      </label>
      <p class="text-xs font-bold text-charcoal-600 break-words">
        <span class="select-none">default: </span>{{ baseValue }}
      </p>
    </div>
    <input
      :name="`${itemKey}-input`"
      :data-testid="`${itemKey}-input`"
      class="block bg-charcoal-900 border border-divider-transparent-white rounded-lg text-charcoal-200 outline-none right-0"
      autocomplete="false"
      :class="{
        'w-full h-12 px-4 text-sm': inputType === 'text',
        'w-6 h-6 px-0 cursor-pointer ': inputType === 'checkbox'
      }"
      :type="inputType"
      v-model="value"
    />
  </div>
</template>
