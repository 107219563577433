<script setup lang="ts">
import { Card } from '@/models/Card';
import CardStatusBadge from '@/components/CardStatusBadge.vue';
import CardItemWidget from './CardItemWidget.vue';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { ChevronRightIcon } from '@heroicons/vue/24/solid';
import ImmersveCardBaseUnbranded from '@/components/ImmersveCardBaseUnbranded.vue';
import { format } from 'date-fns';
import Pan4Badge from './Pan4Badge.vue';

defineProps<{
  cards: Card[];
}>();

defineEmits<{
  (e: 'selectCard', cardId: string): void;
}>();

const breakpoints = useBreakpoints(breakpointsTailwind);
const isLgOrUp = breakpoints.greaterOrEqual('lg');
const headers = ['Card Type', 'Card #', 'Expiry', 'Status'];
const formatExpiry = (expiresAt: Date) => format(expiresAt, 'MMM dd, yyyy');
</script>
<template>
  <div v-if="!cards.length" class="mt-10 lg:ml-4">There are no active cards.</div>
  <table v-else-if="isLgOrUp" class="mt-10">
    <thead class="hidden lg:table-header-group border-b border-divider-transparent-white">
      <tr class="text-left text-sm font-bold text-charcoal-200">
        <th v-for="header in headers" class="p-4 w-48" :key="header">{{ header }}</th>
      </tr>
    </thead>
    <tbody v-if="isLgOrUp">
      <tr
        v-for="card in cards"
        :key="card.id"
        class="border-b border-divider-transparent-white text-sm text-charcoal-200 cursor-pointer"
        @click="$emit('selectCard', card.id)"
      >
        <td class="px-4 py-3" valign="middle">
          <ImmersveCardBaseUnbranded class="!w-28" />
        </td>
        <td class="px-4 py-3" valign="middle">
          <Pan4Badge class="text-base font-bold" :pan-last4="card.panLast4" />
        </td>
        <td class="px-4 py-3" valign="middle">
          {{ formatExpiry(card.expiresAt) }}
        </td>
        <td class="" valign="middle">
          <div class="pl-4 py-3 w-full flex item-center justify-between">
            <CardStatusBadge :card="card" />
            <ChevronRightIcon class="w-4 ml-2 flex"></ChevronRightIcon>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <ul v-else class="mt-10">
    <li
      v-for="card in cards"
      :key="card.id"
      class="border-b border-divider-transparent-white"
      @click="$emit('selectCard', card.id)"
    >
      <CardItemWidget :card="card" />
    </li>
  </ul>
</template>
