import type { AppConfigSchema } from '@/config/defaults';
import { ConfigBuilder } from '@/config/ConfigBuilder';
import { ObjectProvider } from '@/config/providers/ObjectProvider';
import { JsonStorageProvider } from '@/config/providers/JsonStorageProvider';
import { defaultConfig } from '@/config/defaults';
import { Address } from '@wagmi/core';

export enum AppConfigLayer {
  WINDOW = 'window',
  LOCAL_STORAGE = 'storage'
}

export const appConfigBuilder = new ConfigBuilder<AppConfigSchema>(defaultConfig)
  .addLayer({ name: AppConfigLayer.WINDOW, provider: new ObjectProvider(window.config) })
  .addLayer({
    name: AppConfigLayer.LOCAL_STORAGE,
    provider: new JsonStorageProvider({
      storageKey: 'borax.config',
      storage: window.localStorage
    })
  });

export const config = appConfigBuilder.build();

export const getConfigTokenAddress = () => {
  return (
    config.TEST_MODE_ENABLED
      ? config.USDC_SMART_CONTRACT_ADDRESS_TEST
      : config.USDC_SMART_CONTRACT_ADDRESS_LIVE
  ) as Address;
};

export const getConfigChainId = () => {
  return config.TEST_MODE_ENABLED ? config.TEST_MODE_CHAIN_ID : config.CHAIN_ID;
};

export const getConfigCardProgramId = () => {
  return config.TEST_MODE_ENABLED ? config.CARD_PROGRAM_ID_TEST : config.CARD_PROGRAM_ID_LIVE;
};

export const getConfigFundingChannelId = () => {
  return config.TEST_MODE_ENABLED
    ? config.FUNDING_CHANNEL_ID_POLYGON_USDC_TEST
    : config.FUNDING_CHANNEL_ID_POLYGON_USDC_LIVE;
};
