import {
  InteractionContextType,
  InteractionStatus,
  InteractionType
} from '@/apiClient/types/fundingSourceInteraction';
import { formatUnits } from 'viem';

export interface FundingSourceInteractionProps {
  id: string;
  createdAt: Date;
  modifiedAt: Date;
  fundingSourceId: string;
  accountId: string;
  type: InteractionType;
  status: InteractionStatus;
  token: string;
  amount: number;
  description: string;
  creditDebitIndicator: string;
  context: {
    ref: string;
    type: InteractionContextType;
    blockNumber?: string;
    transactionHash?: string;
  };
  channel: {
    id: string;
    type: string;
    strategy: string;
  };
}
export class FundingSourceInteraction implements FundingSourceInteractionProps {
  id: string;
  createdAt: Date;
  modifiedAt: Date;
  fundingSourceId: string;
  accountId: string;
  type: InteractionType;
  status: InteractionStatus;
  token: string;
  amount: number;
  description: string;
  creditDebitIndicator: string;
  context: {
    ref: string;
    type: InteractionContextType;
    blockNumber?: string;
    transactionHash?: string;
  };
  channel: {
    id: string;
    type: string;
    strategy: string;
  };

  constructor(props: FundingSourceInteractionProps) {
    this.id = props.id;
    this.createdAt = props.createdAt;
    this.modifiedAt = props.modifiedAt;
    this.fundingSourceId = props.fundingSourceId;
    this.creditDebitIndicator = props.creditDebitIndicator;
    this.accountId = props.accountId;
    this.type = props.type;
    this.status = props.status;
    this.token = props.token;
    this.amount = props.amount;
    this.context = props.context;
    this.channel = props.channel;
    this.description = props.description;
  }

  get formattedAmount() {
    let amountMajor = formatUnits(BigInt(this.amount), 6);
    amountMajor = parseFloat(amountMajor).toFixed(2);
    const prefix = this.creditDebitIndicator === 'credit' ? '+' : '-';
    return `${prefix}${amountMajor}`;
  }

  static fromApiResponse(props: FundingSourceInteractionProps) {
    return new FundingSourceInteraction({
      ...props,
      createdAt: new Date(props.createdAt),
      modifiedAt: new Date(props.modifiedAt)
    });
  }
}
