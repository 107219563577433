<script setup lang="ts">
import { computed, reactive, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useSessionsStore } from '@/store/sessions.store';
import { formatEthAddress } from '@/utils/formatString';
import { Address, fetchBalance } from '@wagmi/core';
import { formatUnits, parseUnits } from 'viem';
import { useQuery } from '@tanstack/vue-query';
import JazzIcon from '@/components/icons/JazzIcon.vue';
import InputMonetaryWithDropdown from '@/components/InputMonetaryWithDropdown.vue';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';
import ButtonRounded from '@/components/ButtonRounded.vue';
import { getConfigTokenAddress } from '@/config';

const { href: iconUSDC } = new URL('@/assets/icons/USDC.svg', import.meta.url);
const emits = defineEmits<{ (e: 'onDeposit', amount: string, token: string): void }>();

const sessions = useSessionsStore();
const { currentAccount } = storeToRefs(sessions);

const { data: walletBalance, isFetching: walletBalanceFetching } = useQuery({
  queryKey: ['fetch-wallet-balance', currentAccount],
  queryFn: async () =>
    fetchBalance({
      address: currentAccount.value as Address,
      token: getConfigTokenAddress()
    })
});

const walletBalanceFormatted = computed(() => {
  if (!walletBalance.value || walletBalanceFetching.value) {
    return undefined;
  }

  const formatter = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 6,
    minimumIntegerDigits: 1,
    style: 'decimal'
  });

  const majorUnits = Number(formatUnits(walletBalance.value.value, walletBalance.value.decimals));

  const formattedValue = formatter.format(majorUnits);

  return {
    complete: `${formattedValue} ${walletBalance.value.symbol}`,
    symbol: walletBalance.value.symbol,
    majorUnits,
    formattedValue
  };
});

const form = reactive({
  amount: '',
  currency: { label: 'USDC', value: 'USDC', icon: iconUSDC },
  errorMessage: ''
});

watch([() => form.amount, walletBalanceFormatted], ([amount, bal]) => {
  form.errorMessage =
    bal?.majorUnits && Number(amount) && Number(amount) > bal?.majorUnits
      ? `Not enough ${bal.symbol}`
      : '';
});

function setAmountMax() {
  if (walletBalanceFormatted.value?.majorUnits) {
    form.amount = walletBalanceFormatted.value.majorUnits.toString();
  }
}

function submit() {
  const amountMinor = parseUnits(form.amount, 6);
  emits('onDeposit', amountMinor.toString(), form.currency.value);
}
</script>

<template>
  <div>
    <p class="text-3xl py-2 text-charcoal-200 select-none">Deposit</p>
    <p class="text-charcoal-200 text-sm py-2 mt-2">
      Deposit funds into your Immersve account and use them for making purchases with your web3
      Mastercard
    </p>
    <!-- From -->
    <div class="mt-6">
      <p class="font-bold text-base">From</p>
      <div
        name="deposit-from-wallet"
        class="border border-divider-transparent-white flex items-center px-4 h-12 bg-charcoal-900 rounded-lg mt-2"
      >
        <JazzIcon :address="currentAccount || ''" :size="24" class="mr-2" />
        <p class="text-charcoal-200">
          <span class="select-none">Your wallet: </span>
          {{ formatEthAddress(currentAccount || '') }}
        </p>
      </div>
    </div>

    <!-- Amount -->
    <div class="mt-6">
      <label for="deposit-amount" class="font-bold text-base">Amount </label>

      <button
        data-testid="deposit-amount-max"
        class="text-sm text-primary-purple-100 font-semibold float-right px-2 py-1 disabled:text-charcoal-700"
        type="button"
        @click="setAmountMax"
        :disabled="!walletBalanceFormatted?.majorUnits"
      >
        Max
      </button>

      <InputMonetaryWithDropdown
        class="mt-2"
        :dropdownOptions="[{ label: 'USDC', value: 'USDC', icon: iconUSDC }]"
        v-model:dropdownValue="form.currency"
        v-model:inputValue="form.amount"
        :class="{
          'border-utility-error-red-200': form.errorMessage
        }"
      />

      <p v-if="form.errorMessage" class="text-sm text-utility-error-red-200 mt-3">
        {{ form.errorMessage }}
      </p>

      <p v-else class="text-sm text-charcoal-500 mt-3">
        Wallet balance:
        <span v-if="walletBalanceFormatted?.complete && !walletBalanceFetching">
          {{ walletBalanceFormatted.complete }}
        </span>

        <LoadingSpinner v-else-if="walletBalanceFetching" class="inline-block ml-1 h-3" />
      </p>
    </div>

    <!-- Deposit button -->
    <ButtonRounded
      type="button"
      class="w-full mt-10"
      @click="submit"
      :disabled="!form.amount || !!form.errorMessage"
    >
      Deposit
    </ButtonRounded>
  </div>
</template>
