import { defineStore } from 'pinia';
import { useSessionsStore } from './sessions.store';
import { KycStatus } from '@/types/kycStatus';

export interface AccountActivationDetails {
  kycStatus: KycStatus;
  kycUrl?: string;
}

export interface AccountActivationState {
  s: { [key: string]: AccountActivationDetails };
}

export const useAccountActivationStore = defineStore<
  'account-activation',
  AccountActivationState,
  {
    currentActivationDetails: (
      state: AccountActivationState
    ) => AccountActivationDetails | undefined;
  },
  {
    setKycStatus: (value: KycStatus) => void;
    setKycUrl: (value: string) => void;
    refetchPrerequisites: () => Promise<unknown>;
    setRefetchPrerequisites: (value: () => Promise<unknown>) => void;
  }
>('account-activation', {
  state() {
    return { s: {} };
  },

  getters: {
    currentActivationDetails(state) {
      const sessionsStore = useSessionsStore();
      const result = sessionsStore.currentAccount
        ? state.s[sessionsStore.currentAccount]
        : undefined;
      return result;
    }
  },

  actions: {
    setKycStatus(value) {
      const sessionsStore = useSessionsStore();

      if (!sessionsStore.currentAccount) {
        return;
      }

      this.s = {
        ...this.s,
        [sessionsStore.currentAccount]: {
          ...this.s[sessionsStore.currentAccount],
          kycStatus: value
        }
      };
    },
    setKycUrl(value) {
      const sessionsStore = useSessionsStore();
      if (!sessionsStore.currentAccount) {
        return;
      }

      this.s = {
        ...this.s,
        [sessionsStore.currentAccount]: {
          ...this.s[sessionsStore.currentAccount],
          kycUrl: value
        }
      };
    },
    setRefetchPrerequisites(refetch: () => Promise<unknown>) {
      this.refetchPrerequisites = refetch;
    },
    async refetchPrerequisites() {}
  }
});
