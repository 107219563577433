<script setup lang="ts">
import { ChevronRightIcon } from '@heroicons/vue/24/solid';
import SetPinIcon from '@/components/icons/SetPinIcon.vue';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
const { href: cardInWallet } = new URL('@/assets/icons/card-in-wallet.svg', import.meta.url);

defineProps<{
  activateAccountHidden?: boolean;
  setPinUrl?: string;
}>();

const breakpoints = useBreakpoints(breakpointsTailwind);
const isSmOrUp = breakpoints.greaterOrEqual('sm');
</script>

<template>
  <div class="mt-8">
    <p class="text-lg text-charcoal-200 select-none">What would you like to do now?</p>
    <RouterLink
      class="flex border border-divider-transparent-white items-center mt-1 select-none h-12 px-2 text-base font-semibold w-full rounded-l bg-charcoal-900"
      to="/contact-details"
      v-if="!activateAccountHidden"
    >
      <img :src="cardInWallet" :alt="`${cardInWallet}-icon`" class="h-5 m-2 color-purple-100" />
      <p class="text-sm text-charcoal-200 font-normal w-full">Activate account</p>
      <ChevronRightIcon class="h-5" />
    </RouterLink>

    <RouterLink
      class="flex border border-divider-transparent-white items-center mt-5 select-none h-12 px-2 text-base font-semibold w-full rounded-l bg-charcoal-900"
      to="/deposit"
      @click="$router.currentRoute.value.path === '/deposit' ? $router.go(0) : undefined"
    >
      <img :src="cardInWallet" :alt="`${cardInWallet}-icon`" class="h-5 m-2 color-purple-100" />
      <p class="text-sm text-charcoal-200 font-normal w-full">Deposit funds</p>
      <ChevronRightIcon class="h-5" />
    </RouterLink>

    <RouterLink
      class="flex border border-divider-transparent-white items-center mt-5 select-none h-12 px-2 text-base font-semibold w-full rounded-l bg-charcoal-900"
      v-if="setPinUrl"
      :to="setPinUrl ? setPinUrl : ''"
      @click="$router.currentRoute.value.path === '/deposit' ? $router.go(0) : undefined"
    >
      <SetPinIcon class="mx-1" />
      <p v-if="isSmOrUp" class="text-sm text-charcoal-200 font-normal w-full">
        Set PIN (some in-person transactions require a PIN)
      </p>
      <p v-else class="text-sm text-charcoal-200 font-normal w-full">Set PIN</p>
      <ChevronRightIcon class="h-5" />
    </RouterLink>

    <RouterLink
      class="flex justify-center items-center mt-8 select-none h-12 px-8 text-base font-semibold w-full"
      to="/"
    >
      <p class="text-charcoal-200 font-semibold">Go back to dashboard</p>
    </RouterLink>
  </div>
</template>
