<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon/arrow-right">
      <path
        id="Vector"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.64611 2.98043C7.84138 2.78517 8.15796 2.78517 8.35322 2.98043L13.0199 7.64718C13.1137 7.74095 13.1663 7.86813 13.1663 8.00074C13.1663 8.13335 13.1137 8.26052 13.0199 8.35429L8.35321 13.0209C8.15795 13.2161 7.84137 13.2161 7.64611 13.0209C7.45085 12.8256 7.45085 12.509 7.64611 12.3138L11.4592 8.50073H3.33301C3.05687 8.50073 2.83301 8.27688 2.83301 8.00073C2.83301 7.72459 3.05687 7.50073 3.33301 7.50073H11.4592L7.64611 3.68754C7.45085 3.49227 7.45085 3.17569 7.64611 2.98043Z"
        fill="#E3E3EA"
      />
    </g>
  </svg>
</template>
