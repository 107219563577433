<script setup lang="ts">
import { computed, h, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useInfiniteQuery } from '@tanstack/vue-query';
import { useSessionsStore } from '@/store/sessions.store';
import apiClient from '@/apiClient';
import TransactionTable from '@/components/TransactionTable.vue';
import ArrowRight from '@/components/icons/ArrowRight.vue';
import { useFundingStore } from '@/store/funding.store';
import { FundingSourceInteraction } from '@/models/FundingSourceInteraction';
import { InteractionStatus, InteractionType } from '@/apiClient/types/fundingSourceInteraction';
import ImmersveCustomToast from '@/components/ImmersveCustomToast.vue';
import { toastStyle } from '@/constants';
import { ToastOptions, toast } from 'vue3-toastify';
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';
import { useFundingInteractionsStore } from '@/store/fundingInteractions.store';

const props = defineProps<{
  pageSize?: number;
  widgetTitle: string;
  displayLimit?: number;
}>();

const sessionStore = useSessionsStore();
const { currentSession } = storeToRefs(sessionStore);
const fundingSourceId = computed(() => currentSession.value?.fundingSourceId);
const fundingSourceIdExists = computed(() => !!currentSession.value?.fundingSourceId);

const fundingInteractionsStore = useFundingInteractionsStore();
const { currentInteractions } = storeToRefs(fundingInteractionsStore);

const breakpoints = useBreakpoints(breakpointsTailwind);
const isLgOrUp = breakpoints.greaterOrEqual('lg');

const toastOptions = computed<ToastOptions>(() => ({
  autoClose: 15000,
  closeOnClick: false,
  position: isLgOrUp.value ? 'top-right' : 'top-center',
  hideProgressBar: true,
  toastStyle,
  theme: 'dark'
}));

function confirmDeposit(interaction: FundingSourceInteraction) {
  toast(
    h(ImmersveCustomToast, {
      toastName: 'Deposit',
      status: 'successful',
      msg: `${interaction.formattedAmount} deposit confirmed`
    }),
    toastOptions.value
  );
  fundingInteractionsStore.completePendingFundingSourceInteraction(interaction.id);
}

function updatePendingInteractions(items: FundingSourceInteraction[]) {
  if (currentInteractions.value?.length) {
    const pendingInteractions = currentInteractions.value;
    const clientInteractions = pendingInteractions.filter(
      (pending) => pending.channel.type == 'client'
    );
    const unconfirmedDeposits = pendingInteractions.filter(
      (pending) =>
        pending.status == InteractionStatus.Pending && pending.type == InteractionType.Deposit
    );
    items.forEach((item) => {
      // search client generated interactions
      const pendingClientInteractionFound = clientInteractions.find((pending) => {
        return item.context.ref.startsWith(pending.context.ref);
      });
      if (pendingClientInteractionFound) {
        // update client interaction with api response interaction
        fundingInteractionsStore.updatePendingFundingSourceInteraction(
          pendingClientInteractionFound.id,
          item
        );
        if (item.status == InteractionStatus.Confirmed) {
          confirmDeposit(item);
        }
      }
      // confirm interactions from api response
      const unconfirmedDepositChanged = unconfirmedDeposits.find(
        (unconfirmedDeposit) =>
          unconfirmedDeposit.id == item.id && unconfirmedDeposit.status != item.status
      );
      if (unconfirmedDepositChanged && item.status == InteractionStatus.Confirmed) {
        confirmDeposit(unconfirmedDepositChanged);
      }
    });
  }
}

const {
  data: fundingSourceInteractionsResponse,
  isFetching,
  isError,
  fetchNextPage,
  hasNextPage,
  refetch: refetchFundingSourceInteractions
} = useInfiniteQuery({
  queryKey: ['list-funding-source-interactions', fundingSourceId],
  queryFn: async ({ pageParam }) => {
    const pages = await apiClient.listFundingSourceInteractions({
      fundingSourceId: fundingSourceId.value!,
      limit: props.displayLimit ?? props.pageSize,
      cursor: pageParam?.nextCursor
    });
    updatePendingInteractions(pages.items);
    return {
      items: pages.items,
      pageParams: { nextCursor: pages.pageInfo?.nextCursor }
    };
  },
  initialData: {
    pages: [{ items: [], pageParams: { nextCursor: undefined } }],
    pageParams: [undefined]
  },
  getNextPageParam: (lastPage) => {
    if (lastPage.pageParams.nextCursor) {
      return lastPage.pageParams;
    } else {
      return undefined;
    }
  },
  enabled: fundingSourceIdExists,
  keepPreviousData: true,
  refetchInterval: 10000
});

const filter = ref('all');
const buttons: { name: string; text: string; ref?: HTMLButtonElement }[] = [
  {
    name: 'all',
    text: 'All'
  },
  {
    name: 'spending',
    text: 'Spending'
  },
  {
    name: 'depositsWithdrawals',
    text: 'Deposits & Withdrawals'
  }
];

// Add keyboard UI for filter buttons so they behave like native radio buttons
const getButtonRef = (index: number) => {
  return (element) => {
    buttons[index].ref = element;
  };
};

const handleKeyup = (event: KeyboardEvent, index: number) => {
  if (event.key === 'ArrowLeft' || event.key === 'ArrowDown') {
    focusButton(index - 1);
    return;
  }

  if (event.key === 'ArrowRight' || event.key === 'ArrowUp') {
    focusButton(index + 1);
  }
};

const focusButton = (index: number) => {
  if (index >= 0 && index < buttons.length) {
    buttons[index].ref?.focus();
  }
};
async function handleReachBottom() {
  if (hasNextPage?.value && !props.displayLimit) {
    await fetchNextPage();
  }
}

const depositWithdrawalTypes = ['Deposit', 'Withdrawal'];
const spendingTypes = ['Payment', 'Refund'];

function deduplicateItems(items, identifier) {
  return Object.values(
    items.reduce((acc, currentItem) => {
      acc[currentItem[identifier]] = currentItem;
      return acc;
    }, {})
  );
}

const fundingSourceInteractions = computed(() => {
  const apiInteractions =
    fundingSourceInteractionsResponse.value?.pages
      .flatMap((page) => page.items)
      .filter(
        (t) =>
          filter.value === 'all' ||
          (filter.value === 'spending' && spendingTypes.includes(t.type)) ||
          (filter.value === 'depositsWithdrawals' && depositWithdrawalTypes.includes(t.type))
      ) || [];
  return deduplicateItems(
    currentInteractions.value.concat(apiInteractions || []),
    'id'
  ) as FundingSourceInteraction[];
});

const fundingStore = useFundingStore();
fundingStore.setFundingSourceInteractionRefetch(refetchFundingSourceInteractions);
</script>

<template>
  <div class="mb-4 flex items-center flex-wrap">
    <h3 class="mr-4 text-2xl font-semibold basis-full md:basis-auto">{{ widgetTitle }}</h3>
    <RouterLink to="/transactions" class="flex h-fit items-center" v-if="props.displayLimit">
      <p class="mr-2 text-sm font-semibold">View all</p>
      <ArrowRight class="h-4" />
    </RouterLink>
  </div>
  <div>
    <div
      role="radiogroup"
      aria-label="filter transactions"
      aria-controls="transactions"
      class="flex gap-2"
    >
      <template v-for="(button, index) in buttons" :key="button.name">
        <button
          role="radio"
          :ref="getButtonRef(index)"
          @click="filter = button.name"
          @keyup="handleKeyup($event, index)"
          :tabindex="filter === button.name ? 0 : -1"
          :aria-checked="filter === button.name"
          :class="
            filter === button.name
              ? 'bg-primary-purple-100 text-charcoal-1000'
              : 'bg-charcoal-900 text-charcoal-400'
          "
          class="rounded-lg px-5 py-2.5 text-sm font-medium"
        >
          {{ button.text }}
        </button>
      </template>
    </div>

    <div aria-live="polite">
      <p v-if="isError" class="pt-8 lg:pl-4">
        Something went wrong while loading your transactions. Please try again or contact
        support@immersve.com if the issue persists.
      </p>

      <TransactionTable
        v-else
        :funding-source-interactions="fundingSourceInteractions!"
        id="transactions"
        aria-label="transactions"
        class="w-full"
        :loading="isFetching"
        @reachBottom="handleReachBottom()"
        :page-size="props.pageSize"
        :display-limit="props.displayLimit"
      />
    </div>
  </div>
</template>
