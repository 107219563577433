export function isExpired(ticks: number | undefined) {
  return !ticks || ticks < Date.now() / 1000;
}

export function timeToExpiryInMillis(ticks: number | undefined) {
  if (!ticks || isExpired(ticks)) {
    return 0;
  }
  const timeLeft = ticks * 1000 - Date.now();
  return Math.max(timeLeft, 0);
}
