<template>
  <svg viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 1.75C2.30964 1.75 1.75 2.30964 1.75 3V6.25H22.25V3C22.25 2.30964 21.6904 1.75 21 1.75H3ZM23.75 3C23.75 1.48122 22.5188 0.25 21 0.25H3C1.48122 0.25 0.25 1.48122 0.25 3V15C0.25 16.5188 1.48122 17.75 3 17.75H21C22.5188 17.75 23.75 16.5188 23.75 15V3ZM22.25 7.75H1.75V15C1.75 15.6904 2.30964 16.25 3 16.25H21C21.6904 16.25 22.25 15.6904 22.25 15V7.75Z"
      fill="#A4A4AA"
    />
  </svg>
</template>
