import { getWalletClient, getNetwork } from '@wagmi/core';
import {
  SmartContractWritePrerequisitesResponseItem,
  PrerequisitesResponseItemType
} from '@/apiClient/types/prerequisite';
import { Address, publicActions } from 'viem';
import { WithdrawalIntentExecution } from '@/apiClient/types/fundingSource';

async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function smartContractWrite(
  props: SmartContractWritePrerequisitesResponseItem,
  onHashReceived?: (hash: string) => void
) {
  const { contractAddress, abi, method, params } = props.params;

  const walletClient = await getWalletClient();
  if (!walletClient) {
    throw new Error('Wallet client not found');
  }
  const extendedClient = walletClient.extend(publicActions);

  let lastError: unknown;
  const maxAttempts = 5;

  for (let attempt = 1; attempt <= maxAttempts; attempt++) {
    try {
      const hash = await extendedClient.writeContract({
        address: contractAddress,
        abi,
        functionName: method,
        args: Object.values(params).map((value) => value),
        chain: getNetwork().chain
      });
      if (onHashReceived) {
        onHashReceived(hash);
      }
      const receipt = await extendedClient.waitForTransactionReceipt({ hash });
      return receipt;
    } catch (error: any) {
      lastError = error;

      // retry if RPC internal error
      if (error?.cause?.code !== -32603) {
        break;
      }
      if (attempt < maxAttempts) {
        await sleep(attempt * attempt * 1000);
      }
    }
  }
  throw lastError;
}

export async function executeWithdrawalIntent(
  execution: WithdrawalIntentExecution,
  onHashReceived?: (hash: string) => void
) {
  const { transactionHash, status } = await smartContractWrite(
    {
      type: PrerequisitesResponseItemType.SMART_CONTRACT_WRITE,
      params: {
        abi: execution.abi,
        contractAddress: execution.contractAddress as Address,
        method: execution.method,
        params: {
          amount: execution.params.amount,
          expiryDate: execution.params.expiryDate,
          nonce: execution.params.nonce,
          signature: execution.params.signature
        }
      }
    },
    onHashReceived
  );
  return { transactionHash, status };
}

export async function executeWeb3Transactions(
  requiredTransactions: Array<SmartContractWritePrerequisitesResponseItem>,
  onHashReceived?: (hash: string) => void
) {
  const actionedTransactions: Array<string> = [];

  for (const requiredTransaction of requiredTransactions) {
    switch (requiredTransaction.type) {
      case PrerequisitesResponseItemType.SMART_CONTRACT_WRITE: {
        const { transactionHash } = await smartContractWrite(requiredTransaction, onHashReceived);
        actionedTransactions.push(transactionHash);
        break;
      }
      default:
        throw new Error(`Unexpected transaction "${JSON.stringify(requiredTransaction)}"`);
    }
  }
  return actionedTransactions;
}
