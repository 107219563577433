import { createConfig, configureChains, Chain } from '@wagmi/core';
import { MockConnector } from '@wagmi/core/connectors/mock';
import { polygon, polygonMumbai } from '@wagmi/core/chains';
import { publicProvider } from '@wagmi/core/providers/public';
import { MetaMaskConnector } from '@wagmi/core/connectors/metaMask';
import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect';
import { CoinbaseWalletConnector } from '@wagmi/core/connectors/coinbaseWallet';
import { InjectedConnector } from '@wagmi/core/connectors/injected';
import { createMockWalletClient } from '@/web3/mockWalletClient';
import { config } from '@/config';

const {
  MOCK_WEB3_CONNECTOR_ENABLED,
  MOCK_WEB3_WALLET_MNEMONIC,
  CHAIN_ID,
  TEST_MODE_CHAIN_ID,
  TEST_MODE_ENABLED
} = config;

const chainId = Number(TEST_MODE_ENABLED ? TEST_MODE_CHAIN_ID : CHAIN_ID);

export const polygonAmoy = {
  id: 80_002,
  name: 'Polygon Amoy',
  nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc-amoy.polygon.technology']
    },
    public: {
      http: ['https://rpc-amoy.polygon.technology']
    }
  },
  blockExplorers: {
    default: {
      name: 'OK LINK',
      url: 'https://www.oklink.com/amoy'
    }
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 3127388
    }
  },
  testnet: true,
  network: 'maticamoy'
} satisfies Chain;

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [polygon, polygonMumbai, polygonAmoy],
  [publicProvider()]
);

function createMockConnector() {
  return new MockConnector({
    chains,
    options: {
      walletClient: createMockWalletClient(),
      chainId
    }
  });
}

// This metamask connector is dependant on Metamask to be installed
const metaMaskConnector = new MetaMaskConnector({
  chains
});

const trustWallet = new InjectedConnector({
  chains,
  options: {
    shimDisconnect: true,
    name: 'Trust Wallet',
    getProvider: () => {
      const provider = window?.trustWallet;
      return provider;
    }
  }
});

const walletConnectConnector = new WalletConnectConnector({
  chains,
  options: {
    projectId: config.WALLET_CONNECT_PROJECT_ID
  }
});

// Coinbase does not inject provider if its non-https or non-localhost page
// borax:3000 will not work -> localhost:3000 will but request will fail on WAF step
const coinbaseConnector = new CoinbaseWalletConnector({
  chains,
  options: {
    appName: 'IMMERSVE'
  }
});

export const connectors =
  MOCK_WEB3_CONNECTOR_ENABLED && MOCK_WEB3_WALLET_MNEMONIC && chainId === polygonAmoy.id
    ? [createMockConnector()]
    : [metaMaskConnector, trustWallet, walletConnectConnector, coinbaseConnector];

export const web3Config = createConfig({
  autoConnect: true,
  connectors,
  webSocketPublicClient,
  publicClient
});
