<script setup lang="ts">
import DepositWidget from '@/components/DepositWidget.vue';
import DepositProgressWidget from '@/components/DepositProgressWidget.vue';
import { computed, h, reactive, ref, watch } from 'vue';
import { useSessionsStore } from '@/store/sessions.store';
import { storeToRefs } from 'pinia';
import { useMutation, useQuery } from '@tanstack/vue-query';
import apiClient from '@/apiClient';
import { getConfigCardProgramId, getConfigFundingChannelId, config } from '@/config';
import { convertUsdcToUsd } from '@/utils/formatAmount';
import {
  PrerequisitesResponseItemType,
  SmartContractWritePrerequisitesResponseItem
} from '@/apiClient/types/prerequisite';
import { executeWeb3Transactions } from '@/web3/interactions';
import { ToastOptions, toast } from 'vue3-toastify';
import { AxiosError } from 'axios';
import DialogRetry from '@/components/DialogRetry.vue';
import router from '@/router';
import { FundingSourceInteraction } from '@/models/FundingSourceInteraction';
import {
  InteractionContextType,
  InteractionStatus,
  InteractionType
} from '@/apiClient/types/fundingSourceInteraction';
import { nanoid } from 'nanoid';
import { useFundingInteractionsStore } from '@/store/fundingInteractions.store';
import { toastStyle } from '@/constants';
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';
import ImmersveCustomToast from '@/components/ImmersveCustomToast.vue';

const isFastDepositEnabled = config.FAST_DEPOSIT_ENABLED;

const breakpoints = useBreakpoints(breakpointsTailwind);
const isLgOrUp = breakpoints.greaterOrEqual('lg');

const toastOptions = computed<ToastOptions>(() => ({
  autoClose: 15000,
  closeOnClick: false,
  position: isLgOrUp.value ? 'top-right' : 'top-center',
  hideProgressBar: true,
  toastStyle,
  theme: 'dark'
}));

const params = reactive<{
  stage: 'sign' | 'deposit' | 'success' | '';
  transactionHash?: string;
  token: string;
  amount: string;
}>({
  stage: '',
  token: '',
  amount: ''
});

function onDeposit(amount: string, token: string) {
  if (!fundingSource.value) {
    return;
  }
  if (params.stage !== '') {
    return;
  }
  params.stage = 'sign';
  params.token = token;
  params.amount = amount;
  const spendableAmount = Number(params.amount) + Number(fundingSource.value.balance);
  deposit({
    spendableAmount: spendableAmount,
    fundingSourceId: fundingSource.value.id
  });
}
const resetDeposit = () => {
  params.token = '';
  params.amount = '';
  params.stage = '';
};

const cardProgramId = getConfigCardProgramId();

const fundingChannelId = getConfigFundingChannelId();

const sessions = useSessionsStore();
const { currentSession } = storeToRefs(sessions);

const fundingInteractionsStore = useFundingInteractionsStore();

const fundingSource = computed(() =>
  fundingSources.value?.find((fs) => fs.fundingChannelId === getConfigFundingChannelId())
);
defineExpose({ fundingSource, params }); //expose fundingSource to external so test can access it

const { data: fundingSources, refetch: refetchFundingSources } = useQuery({
  queryKey: ['list-funding-sources', currentSession],
  queryFn: () => {
    if (!currentSession.value?.user.accountId) {
      return [];
    }

    return apiClient.listFundingSources(currentSession.value?.user.accountId);
  },
  select(data) {
    return data.filter((fs) => fs.fundingChannelId === fundingChannelId);
  }
});

const { mutate: createFundingSource } = useMutation({
  mutationFn: () =>
    apiClient.createFundingSource({
      accountId: currentSession.value!.user.accountId,
      fundingAddress: currentSession.value!.user.walletAddress,
      fundingChannelId
    }),
  onSuccess() {
    refetchFundingSources();
  }
});

watch(fundingSources, () => {
  if (!fundingSources.value?.[0]) {
    createFundingSource();
  }
});

const { mutate: deposit } = useMutation({
  mutationFn: async ({
    spendableAmount,
    fundingSourceId
  }: {
    spendableAmount: number;
    fundingSourceId: string;
  }) => {
    const spendableAmountInUSD = convertUsdcToUsd({ amountMinorUnits: spendableAmount });
    const prerequisitesResponse = await apiClient.listSpendingPrerequisites({
      cardProgramId,
      fundingSourceId,
      spendableAmount: spendableAmountInUSD.toString(),
      spendableCurrency: 'USD',
      kycType: 'immersve-conducted',
      kycRedirectUrl: `${config.API_BASE_URL}/dashboard`,
      kycRegion: config.KYC_REGION || undefined
    });

    const { prerequisites } = prerequisitesResponse;
    if (prerequisites.length <= 0) {
      return;
    }
    const smartContractWritePrerequisites =
      prerequisites.filter<SmartContractWritePrerequisitesResponseItem>(
        (prerequisites): prerequisites is SmartContractWritePrerequisitesResponseItem => {
          return prerequisites.type === PrerequisitesResponseItemType.SMART_CONTRACT_WRITE;
        }
      );
    if (smartContractWritePrerequisites.length < 0) {
      return toast.error(
        'Smart Contract prerequisites should not be empty. Please try again later'
      );
    }

    const web3Transactions = await executeWeb3Transactions(
      smartContractWritePrerequisites,
      (hash: string) => {
        params.transactionHash = hash;
      }
    );

    const transactionHash = web3Transactions[web3Transactions.length - 1];

    if (isFastDepositEnabled) {
      const depositTransaction = smartContractWritePrerequisites.find(
        (prerequisite) => prerequisite.type === PrerequisitesResponseItemType.SMART_CONTRACT_WRITE
      );
      const depositAmount = depositTransaction?.params.params?._value;
      const pendingDepositInteraction = new FundingSourceInteraction({
        context: {
          ref: transactionHash,
          type: InteractionContextType.SmartContractEvent
        },
        amount: depositAmount || 0,
        fundingSourceId,
        createdAt: new Date(),
        modifiedAt: new Date(),
        type: InteractionType.Deposit,
        creditDebitIndicator: 'credit',
        status: InteractionStatus.Pending,
        token: 'USDC',
        channel: {
          id: fundingChannelId,
          strategy: 'client',
          type: 'client'
        },
        description: 'Deposit',
        id: nanoid(),
        accountId: 'pending'
      });
      fundingInteractionsStore.addPendingFundingSourceInteraction(pendingDepositInteraction);
      toast(
        h(ImmersveCustomToast, {
          toastName: 'Deposit',
          status: 'pending',
          msg: 'It can take a few moments for the transaction to process'
        }),
        toastOptions.value as ToastOptions
      );
    }
  },
  mutationKey: [fundingSource],
  onSuccess() {
    if (isFastDepositEnabled) {
      router.push('/');
    } else {
      params.stage = 'deposit';
      recheckBalanceChanges();
    }
  },
  onError(error) {
    if ((error as Error).message.includes('User rejected the request')) {
      openModal();
      return;
    }

    if (error instanceof AxiosError && error.response?.data?.errorCode === 'AML_REVIEW_REQ') {
      return toast.error(
        "Sorry, we're unable to make a deposit at this time. Please contact support for help."
      );
    }

    // eslint-disable-next-line no-console
    console.error(error);
    return toast.error('Something went wrong. Please try again later');
  }
});

const { refetch: recheckBalanceChanges } = useQuery({
  queryKey: ['assure-balance-change', fundingSource],
  queryFn: async () => {
    if (!currentSession.value?.user.accountId) {
      return true;
    }

    const newFundingSource = (
      await apiClient.listFundingSources(currentSession.value?.user.accountId)
    ).find((f) => f.id === fundingSource.value?.id);
    if (newFundingSource && newFundingSource.balance !== fundingSource.value?.balance) {
      params.stage = 'success';
      return true;
    }
    throw new Error('balance not changed');
  },
  enabled: false,
  retry: 30, //usually is 2 to 3 min, so set max tolerance to 5min
  retryDelay: 10000 //10s
});

const isOpen = ref(false);

function closeModal() {
  isOpen.value = false;
}

function openModal() {
  isOpen.value = true;
}
function closeModalAndBack() {
  closeModal();
  resetDeposit();
}
function closeModalAndTryAgain() {
  closeModal();
  params.stage = '';
  onDeposit(params.amount, params.token);
}
</script>

<template>
  <div class="mt-12 max-w-xl mx-auto px-4">
    <DepositWidget v-if="params.stage === ''" @on-deposit="onDeposit" />
    <DepositProgressWidget
      v-else
      :stage="params.stage"
      @back="resetDeposit"
      :transaction-hash="params.transactionHash"
    />
  </div>
  <DialogRetry
    :open="isOpen"
    title="Transaction cancelled"
    description="Would you like to try again?"
    @close="closeModalAndTryAgain"
    @retry="closeModalAndBack"
  ></DialogRetry>
</template>
