<script setup lang="ts">
import { watchEffect } from 'vue';
import { useUiStore } from '@/store/ui.store';
import SideNav from '@/components/SideNav.vue';
import DrawerContainer from '@/components/DrawerContainer.vue';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';

const uiState = useUiStore();
const breakpoints = useBreakpoints(breakpointsTailwind);
const isMdOrUp = breakpoints.greaterOrEqual('md');

watchEffect(() => {
  if (uiState.sideNavOpen && isMdOrUp.value) {
    uiState.toggleSideNavOpen(false);
  }
});
</script>

<template>
  <div>
    <!-- Small screens -->
    <DrawerContainer :open="uiState.sideNavOpen" @close="uiState.toggleSideNavOpen(false)">
      <SideNav @close="uiState.toggleSideNavOpen" class="w-60" />
    </DrawerContainer>
    <!-- Medium and larger screens -->
    <SideNav
      v-if="isMdOrUp"
      class="fixed left-0 top-0 bg-transparent transition-all"
      :class="{
        'w-24': uiState.sideNavCollapsed,
        'w-60': !uiState.sideNavCollapsed
      }"
      :collapsed="uiState.sideNavCollapsed"
      @toggle-collapse="uiState.toggleSideNavCollapsed"
    />
  </div>
</template>
