<script setup lang="ts">
import { useLink } from 'vue-router';
import { ref } from 'vue';
import LoginBoxButton from '@/components/LoginBoxButton.vue';
import { useSessionsStore } from '@/store/sessions.store';
import { Connector, connect, disconnect, signMessage } from '@wagmi/core';
import { connectors } from '@/web3/client';
import { config } from '@/config';
import axios from 'axios';
import { User } from '@/models/User';
import jwtDecode from 'jwt-decode';
import { isMobile } from '@/utils/mobile.ts';

enum Scope {
  CardholderPartner = 'cardholder-partner'
}

const { href: metaMaskLogo } = new URL('@/assets/icons/metamask.svg', import.meta.url);
const { href: walletConnectLogo } = new URL('@/assets/icons/walletconnect.svg', import.meta.url);
const { href: coinbaseLogo } = new URL('@/assets/icons/coinbase.svg', import.meta.url);
const { href: trustWalletLogo } = new URL('@/assets/icons/trustwallet.svg', import.meta.url);
const { href: immersveLogo } = new URL('@/assets/icons/ImmersveIcon.svg', import.meta.url);

function getLogo(connectorId: string, name: string): string {
  const logos = {
    metaMask: metaMaskLogo,
    walletConnect: walletConnectLogo,
    coinbaseWallet: coinbaseLogo,
    trustWallet: trustWalletLogo
  };
  const logo = logos[connectorId];

  if (logo) {
    return logo;
  }

  switch (name) {
    case 'Trust Wallet':
      return logos.trustWallet;
    case 'MetaMask':
      return logos.metaMask;
    default:
      return immersveLogo;
  }
}

const linkToDashboard = useLink({ to: '/' });

const sessions = useSessionsStore();

const busy = ref<string>('');

const filteredConnectors = isMobile() ? connectors.filter((c) => c.ready) : connectors;

async function getSiweMessage(options: {
  address: string;
  chainId: number;
  immersveBaseUrl: string;
  url: string;
  clientApplicationId: string;
  scopes: Scope[];
}) {
  const res = await axios.request({
    url: `${options.immersveBaseUrl}/siwe/generate-challenge`,
    data: {
      walletAddress: options.address,
      chainId: options.chainId,
      url: options.url,
      scopes: options.scopes,
      clientApplicationId: options.clientApplicationId
    },
    method: 'POST'
  });
  return res.data;
}

async function signIn(walletAddress: string, chainId: number) {
  const url = window.location.origin;
  const immersveBaseUrl = config.TEST_MODE_ENABLED
    ? config.TEST_MODE_API_BASE_URL
    : config.API_BASE_URL;
  const clientApplicationId = config.TEST_MODE_ENABLED
    ? config.API_CLIENT_APPLICATION_ID_TEST
    : config.API_CLIENT_APPLICATION_ID_LIVE;
  const message = await getSiweMessage({
    address: walletAddress,
    chainId,
    immersveBaseUrl,
    url,
    clientApplicationId,
    scopes: [Scope.CardholderPartner]
  });

  let signature;
  try {
    signature = await signMessage({ message });
  } catch (err: any) {
    // sign message canceled
    if (err.code === 4001) {
      return;
    }
    throw err;
  }
  if (!signature.startsWith('0x')) {
    signature = '0x' + signature;
  }

  const res = await axios.request({
    url: `${immersveBaseUrl}/siwe/login`,
    method: 'POST',
    data: { message, signature }
  });

  const decodedToken = jwtDecode(res.data.token) as { [key: string]: string };

  const user = new User({
    id: decodedToken.sub,
    accountId: res.data.cardholderAccountId,
    walletAddress: decodedToken.walletAddress,
    createdAt: decodedToken.userCreatedAt
  });
  sessions.setSessionDetails({
    walletAddress: user.walletAddress,
    details: {
      token: res.data.token,
      accessTokenExp: Number(decodedToken.exp),
      refreshToken: res.data.refreshToken,
      user,
      pendingFundingSourceInteractions: []
    }
  });
  localStorage.setItem('currentAccount', user.walletAddress);

  await linkToDashboard.navigate();
}

async function login(connector: Connector) {
  busy.value = connector.id;
  try {
    // Disconnecting manually for wallet connect to be able to choose different wallet
    await disconnect();

    const chainIdToConnect = Number(
      config.TEST_MODE_ENABLED ? config.TEST_MODE_CHAIN_ID : config.CHAIN_ID
    );
    const { account, chain } = await connect({ connector, chainId: chainIdToConnect });
    await signIn(account, chain.id);
  } catch (err) {
    const e = err as Error & { code?: number };
    // Error is not consistent across connectors
    if (
      e.name !== 'UserRejectedRequestError' &&
      e.code !== 4001 &&
      e.message !== 'Connection request reset. Please try again.'
    ) {
      throw err;
    }
  } finally {
    busy.value = '';
  }
}
</script>

<template>
  <div
    class="lg:mt-40 lg:max-w-xl lg:bg-secondary-blue-1000 lg:px-20 lg:pb-10"
    data-testid="LoginBox"
  >
    <br />
    <p class="mb-8 mt-6 select-none font-normal md:mb-8 md:mt-11 text-xl">
      Connect your wallet and start spending your crypto everywhere Mastercard is accepted.
    </p>

    <LoginBoxButton
      v-for="connector in filteredConnectors"
      :data-testid="`sign-in-btn-${connector.id}`"
      :key="connector.id"
      :title="`${connector.name}${!connector.ready ? ' not installed' : ''}`"
      @click="() => login(connector)"
      :image="getLogo(connector.id, connector.name)"
      :disabled="busy === connector.id || !connector.ready"
      :busy="busy === connector.id"
      :class="!connector.ready ? 'disabled:bg-charcoal-800' : ''"
    />

    <p class="text-sm mt-4">
      By logging in or signing up, you agree to Immersve's
      <a href="https://immersve.com/general-terms-of-use" target="_blank" class="underline"
        >General Terms of Use</a
      >
      and
      <a href="https://immersve.com/privacy-policy" target="_blank" class="underline"
        >Privacy Policy</a
      >.
    </p>
  </div>
</template>
