<script setup lang="ts">
defineEmits<{ (e: 'click'): void }>();

defineProps<{
  leadingIconSrc?: string;
  trailingIconSrc?: string;
  text: string;
  type?: 'outlined' | 'ghost';
  size?: 'small' | 'medium' | 'large';
  customStyle?: string;
}>();

function getBoarderStyle(type?: 'outlined' | 'ghost') {
  return type === 'ghost' ? '' : 'rounded-full border border-charcoal-500';
}
function getSizeStyle(size?: 'small' | 'medium' | 'large') {
  const buttonSize = size ?? 'medium'; //default is medium
  switch (buttonSize) {
    case 'small':
      return 'h-6 w-fit';
    case 'medium':
      return 'h-10 w-[310px]';
    case 'large':
      return 'h-14 w-[400px]';
  }
}
</script>

<template>
  <button
    type="button"
    @:click="$emit('click')"
    class="flex h-10 w-[310px] px-2 py-6 justify-center items-center gap-2"
    :class="`${getBoarderStyle(type)} ${getSizeStyle(size)} ${customStyle}`"
  >
    <img v-if="leadingIconSrc" :alt="`next`" :src="leadingIconSrc" class="w-[22px]" />
    <p class="flex items-center gap-1 text-sm font-semibold font-sans text-white">{{ text }}</p>
    <img v-if="trailingIconSrc" :alt="`next`" :src="trailingIconSrc" class="w-[22px]" />
  </button>
</template>
