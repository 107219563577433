<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import jazzicon from '@metamask/jazzicon';

const props = withDefaults(defineProps<{ address: string; size: number }>(), {
  address: '',
  size: 50
});

const icon = ref<HTMLDivElement | null>(null);

function numberForAddress(address: string) {
  var addr = address.slice(2, 10);
  var seed = parseInt(addr, 16);
  return seed;
}

watchEffect(() => {
  const oldEl = icon.value?.querySelector('#jazzicon');
  if (oldEl) {
    icon.value?.removeChild(oldEl);
  }
  const el = jazzicon(props.size, numberForAddress(props.address));
  el.setAttribute('id', 'jazzicon');
  icon.value?.appendChild(el);
});
</script>

<template>
  <div ref="icon" class="flex" />
</template>
