<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Logo" opacity="0.2">
      <g id="Group">
        <g id="Clip path group">
          <mask
            id="mask0_2320_23092"
            style="mask-type: luminance"
            maskUnits="userSpaceOnUse"
            x="8"
            y="12"
            width="32"
            height="28"
          >
            <g id="SVGID_00000073697541810304243550000006018246615141209520_">
              <path
                id="Vector"
                d="M28.2756 39.9997H21.8562V25.6085C21.8562 23.1905 21.6044 20.8804 19.9951 19.1455C28.3205 22.1208 28.2666 24.5568 28.2666 27.5681L28.2756 39.9997ZM39.9906 34.2199C39.9906 31.2086 40.0265 28.7726 31.7011 25.7973C33.3014 27.5321 33.5622 29.8513 33.5622 32.2603V39.9907H39.9996L39.9906 34.2199ZM16.5786 20.8894C16.5786 17.8781 16.6145 15.4421 8.28906 12.4668C9.88942 14.2016 10.1412 16.5208 10.1412 18.9298V39.9907H16.5786V20.8894Z"
                fill="white"
              />
            </g>
          </mask>
          <g mask="url(#mask0_2320_23092)">
            <g id="Group_2">
              <g id="Group_3">
                <g id="Clip path group_2">
                  <mask
                    id="mask1_2320_23092"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="8"
                    y="12"
                    width="32"
                    height="28"
                  >
                    <g id="SVGID_00000169518940811514931530000012694653207085652155_">
                      <path
                        id="Vector_2"
                        d="M39.9816 12.4668H8.28906V39.9997H39.9816V12.4668Z"
                        fill="white"
                      />
                    </g>
                  </mask>
                  <g mask="url(#mask1_2320_23092)">
                    <g id="Group_4">
                      <g id="Group_5">
                        <g id="Clip path group_3">
                          <mask
                            id="mask2_2320_23092"
                            style="mask-type: luminance"
                            maskUnits="userSpaceOnUse"
                            x="7"
                            y="12"
                            width="34"
                            height="29"
                          >
                            <g id="SVGID_00000162332339039849082910000015155987886856761244_">
                              <path
                                id="Vector_3"
                                d="M40.4314 12.0176H7.83984V40.4494H40.4314V12.0176Z"
                                fill="white"
                              />
                            </g>
                          </mask>
                          <g mask="url(#mask2_2320_23092)">
                            <g id="Group_6">
                              <g id="Clip path group_4">
                                <mask
                                  id="mask3_2320_23092"
                                  style="mask-type: luminance"
                                  maskUnits="userSpaceOnUse"
                                  x="7"
                                  y="12"
                                  width="34"
                                  height="29"
                                >
                                  <g id="SVGID_00000031201134281760286060000006871140486887915422_">
                                    <path
                                      id="Vector_4"
                                      d="M40.4314 12.0176H7.83984V40.4494H40.4314V12.0176Z"
                                      fill="white"
                                    />
                                  </g>
                                </mask>
                                <g mask="url(#mask3_2320_23092)">
                                  <g id="Group_7">
                                    <rect
                                      id="Rectangle"
                                      x="7.81738"
                                      y="11.998"
                                      width="32.6258"
                                      height="28.4767"
                                      fill="white"
                                    />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <path
        id="Vector_5"
        d="M2.63839e-06 4.04499C2.63839e-06 7.05626 -0.0359605 9.49225 8.28951 12.4676C6.68915 10.7327 6.45539 8.41358 6.45539 6.00456L6.43741 0H0.0179842L2.63839e-06 4.04499Z"
        fill="white"
      />
    </g>
  </svg>
</template>
