<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.0805 35.3003H19.4869V22.758C19.4869 20.6507 19.2675 18.6373 17.8652 17.1254C25.1197 19.7184 25.0727 21.8414 25.0727 24.4658L25.0805 35.3003ZM35.2885 30.263C35.2885 27.6386 35.3198 25.5156 28.0653 22.9225C29.4598 24.4345 29.687 26.4557 29.687 28.5552V35.2924H35.2963L35.2885 30.263ZM14.8882 18.6452C14.8882 16.0208 14.9195 13.8977 7.66504 11.3047C9.05953 12.8167 9.27889 14.8378 9.27889 16.9373V35.2924H14.8882V18.6452Z"
      fill="white"
    />
    <path
      d="M0.432619 3.95988C0.432619 6.58501 0.401239 8.70862 7.66574 11.3024C6.26932 9.79002 6.06535 7.76828 6.06535 5.66818L6.04966 0.433594H0.44831L0.432619 3.95988Z"
      fill="white"
    />
  </svg>
</template>
