import { defineStore } from 'pinia';
import { User } from '@/models/User';
import { FundingSourceInteraction } from '@/models/FundingSourceInteraction';

export interface SessionDetails {
  user: User;
  token: string;
  refreshToken: string;
  fundingSourceId?: string;
  accessTokenExp: number;
  pendingFundingSourceInteractions: FundingSourceInteraction[];
}

export interface SessionsState {
  // TODO rename currentAccount -> currentWallet or activeWallet after legacy gone
  currentAccount: string | null;
  currentChainId: number | null;
  sessions: { [key: string]: SessionDetails };
}

export const useSessionsStore = defineStore<
  'sessions',
  SessionsState,
  {
    currentSession: (state: SessionsState) => SessionDetails | undefined;
  },
  {
    setSessionDetails: (payload: { walletAddress: string; details: SessionDetails }) => void;
    clear: () => void;
    setCurrentAccount: (walletAddress: string) => void;
    setCurrentChainId: (chainId: number) => void;
    setFundingSourceId: (value: string) => void;
  }
>('sessions', {
  state() {
    return {
      currentAccount: null,
      currentChainId: null,
      pendingFundingSourceInteractions: [],
      sessions: {}
    };
  },
  getters: {
    currentSession(state) {
      return state.currentAccount ? state.sessions[state.currentAccount] : undefined;
    }
  },

  actions: {
    setCurrentAccount(walletAddress) {
      this.currentAccount = walletAddress;
    },
    setCurrentChainId(chainId) {
      this.currentChainId = chainId;
    },
    setSessionDetails(payload) {
      this.sessions[payload.walletAddress] = payload.details;
      this.currentAccount = payload.walletAddress;
    },
    setFundingSourceId(value) {
      if (!this.currentAccount) {
        return;
      }
      this.sessions[this.currentAccount].fundingSourceId = value;
    },
    clear() {
      this.$reset();
    }
  },
  persist: true
});
