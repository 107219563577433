import jwtDecode from 'jwt-decode';
import { createRouter, createWebHistory } from 'vue-router';
import { useSessionsStore } from '@/store/sessions.store';
import LoginPage from '@/pages/LoginPage.vue';
import DashboardPage from '@/pages/DashboardPage.vue';
import CreateCardPage from '@/pages/CreateCardPage.vue';
import CreateCardSuccessPage from '@/pages/CreateCardSuccessPage.vue';
import ConfigPage from '@/pages/ConfigPage.vue';
import DepositPage from '@/pages/DepositPage.vue';
import WithdrawPage from '@/pages/WithdrawPage.vue';
import CardsPage from '@/pages/CardsPage.vue';
import SetPinPage from '@/pages/SetPinPage.vue';
import TransactionsPage from '@/pages/TransactionsPage.vue';
import LayoutSidenav from '@/components/LayoutSidenav.vue';
import LayoutHeader from '@/components/LayoutHeader.vue';
import CardDetailsPage from '@/pages/CardDetailsPage.vue';
import HeaderWithBackButton from '@/components/HeaderWithBackButton.vue';
import { beforeEnterNewCard } from './beforeEnterNewCard';
import apiClient from '@/apiClient';
import { isExpired } from '@/utils/epoch.ts';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      alias: '/dashboard',
      name: 'Dashboard',
      components: {
        default: DashboardPage,
        sidenav: LayoutSidenav,
        header: LayoutHeader
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/new-card',
      name: 'Create new card',
      components: {
        default: CreateCardPage
      },
      meta: {
        requiresAuth: true
      },
      beforeEnter: beforeEnterNewCard,
      alias: ['/create-card']
    },
    {
      path: '/card/:cardId/created',
      name: 'Card created',
      components: {
        default: CreateCardSuccessPage,
        header: HeaderWithBackButton
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/deposit',
      name: 'Deposit',
      components: {
        default: DepositPage,
        header: HeaderWithBackButton
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/withdraw',
      name: 'Withdraw',
      components: {
        default: WithdrawPage,
        header: HeaderWithBackButton
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/cards',
      name: 'Cards',
      components: {
        default: CardsPage,
        sidenav: LayoutSidenav,
        header: LayoutHeader
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/card/:cardId/set-pin',
      name: 'Set pin',
      components: {
        default: SetPinPage
      }
    },
    {
      path: '/card/:cardId',
      name: 'Card Details',
      components: {
        default: CardDetailsPage,
        sidenav: LayoutSidenav,
        header: LayoutHeader
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/transactions',
      name: 'Transactions',
      components: {
        default: TransactionsPage,
        sidenav: LayoutSidenav,
        header: LayoutHeader
      },
      meta: {
        requiresAuth: true
      }
    },

    {
      path: '/login',
      name: 'Login',
      component: LoginPage
    },
    {
      path: '/config',
      name: 'Config',
      components: { default: ConfigPage, header: HeaderWithBackButton }
    },
    {
      path: '/:pathMatch(.*)*',
      redirect() {
        return '/';
      }
    }
  ]
});

const isUnauthorized = () => {
  let expiryTime: number | undefined;

  try {
    const sessions = useSessionsStore();
    const currentSession = sessions.currentSession;
    if (!currentSession) {
      return true;
    }
    const token = currentSession.token;
    expiryTime = jwtDecode<{ exp: number }>(token).exp;
  } catch (e) {
    /* empty */
  }

  return isExpired(expiryTime);
};

router.beforeEach(async (to) => {
  if (apiClient.tokenPromise) {
    await apiClient.tokenPromise;
  }

  document.title = `Immersve ${String(to.name)}`;

  if (to.meta.requiresAuth && isUnauthorized()) {
    return {
      name: 'Login',
      replace: true
    };
  }
});

export default router;
