<script setup lang="ts">
import XMarkIcon from '@heroicons/vue/24/solid/XMarkIcon';
const immersveLogo = new URL('@/assets/icons/ImmersveLogo.svg', import.meta.url).href;
const immersveIcon = new URL('@/assets/icons/ImmersveIcon.svg', import.meta.url).href;
</script>

<template>
  <div class="container mx-auto px-4">
    <header class="flex items-baseline justify-between mt-4 sm:mt-7">
      <!-- Logo container -->
      <div>
        <img class="h-9 sm:hidden" :src="immersveIcon" alt="Immersve logo" />
        <img class="hidden sm:block h-9" :src="immersveLogo" alt="Immersve logo" />
      </div>

      <RouterLink to="/dashboard" class="flex border border-charcoal-700 h-10 rounded-lg pl-2 pr-3">
        <span class="flex items-center text-charcoal-400"> <XMarkIcon class="h-5" />Exit</span>
      </RouterLink>
    </header>
  </div>
</template>
