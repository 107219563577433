<script setup lang="ts">
defineProps({
  picUrl: { type: String, required: true },
  activeIndex: { type: Number, required: true },
  description: { type: String, required: true }
});
const activeClass = (index: number, activeIndex: number) => {
  return index === activeIndex ? 'bg-primary-purple-100' : 'border-primary-purple-100 border';
};
</script>

<template>
  <div>
    <div class="flex h-[175px] items-center justify-center self-stretch">
      <img :alt="`OnboardPic1`" :src="picUrl" class="w-30" />
    </div>
    <div class="flex items-center justify-center gap-4 p-4">
      <div
        v-for="n in 3"
        :key="n"
        class="h-2 w-2 rounded-full"
        :class="activeClass(n, activeIndex)"
      ></div>
    </div>
    <div class="flex items-center justify-center gap-4 self-stretch">
      <p
        class="flex w-[261px] content-center text-center font-sans text-2xl font-semibold text-white"
      >
        {{ description }}
      </p>
    </div>
  </div>
</template>
