import { useUiStore } from '@/store/ui.store';
import { useQuery } from '@tanstack/vue-query';
import { useSessionsStore } from '@/store/sessions.store';
import apiClient from '@/apiClient';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

export const useOnboardingShow = () => {
  const uiState = useUiStore();
  const sessions = useSessionsStore();
  const { currentSession } = storeToRefs(sessions);
  const onboardingShow = ref<boolean>();

  function setOnboardingShow(show: boolean) {
    onboardingShow.value = show;
    uiState.setOnboardingShow(show);
  }
  if (uiState.onboardingShow !== undefined) {
    onboardingShow.value = uiState.onboardingShow;
    return {
      loading: false,
      onboardingShow,
      setOnboardingShow
    };
  }
  const {
    data: cards,
    isFetching,
    isError
  } = useQuery({
    queryKey: ['list-all-cards', currentSession],
    queryFn: () => {
      if (!currentSession.value?.user.accountId) {
        return {
          items: []
        };
      }

      return apiClient.listCards(currentSession.value?.user.accountId!, {
        excludeExpired: false,
        limit: 1
      });
    },
    initialData: {
      items: []
    }
  });
  const show = !isError && cards.value.items && cards.value.items.length > 0;
  setOnboardingShow(show);
  return {
    loading: isFetching,
    onboardingShow,
    setOnboardingShow
  };
};
