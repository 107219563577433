<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_490_21698)">
      <path
        d="M5.02401 16.0428C5.02401 18.3046 5.02401 20.5678 5.02401 22.8297C5.02401 23.4317 5.13951 23.5436 5.74589 23.545C6.50214 23.545 7.2584 23.5327 8.01327 23.5491C8.8589 23.5682 9.32503 24.1961 9.06928 24.9414C8.95378 25.2785 8.69665 25.4969 8.36253 25.5024C7.18689 25.5215 6.00301 25.5761 4.83563 25.4696C3.84425 25.3782 3.14163 24.4773 3.0275 23.4262C3.00825 23.246 3 23.0631 3 22.8816C3 18.2773 3 13.6731 3.00138 9.06888C3.00275 8.15704 3.264 7.36805 4.10413 6.86436C4.56888 6.58453 5.08038 6.48215 5.61389 6.47533C6.37014 6.46577 7.12639 6.46031 7.88127 6.48079C8.1384 6.48761 8.4134 6.52993 8.64303 6.6364C9.04453 6.82204 9.22328 7.28342 9.12015 7.70385C9.00328 8.18161 8.57978 8.50648 8.02702 8.51331C7.24877 8.52287 6.46914 8.51468 5.69089 8.51604C5.16839 8.51604 5.02538 8.65937 5.02538 9.18763C5.02538 11.4727 5.02538 13.7577 5.02538 16.0428H5.02401Z"
        fill="#B9ACFF"
      />
      <path
        d="M28.9987 15.9706C28.9987 18.3252 29.0056 20.6799 28.9946 23.0346C28.9904 24.0078 28.6508 24.8269 27.7144 25.2691C27.4133 25.4111 27.0599 25.5012 26.7286 25.5107C25.7234 25.5367 24.7142 25.5408 23.7104 25.4943C23.1755 25.4698 22.8813 25.0917 22.8689 24.5893C22.8565 24.0829 23.1577 23.6871 23.6637 23.5915C23.9194 23.5437 24.1848 23.5342 24.446 23.5314C25.0992 23.5233 25.7523 23.5314 26.4054 23.5273C26.8179 23.5246 26.9582 23.3854 26.9802 22.9759C26.9843 22.8967 26.9816 22.8162 26.9816 22.737C26.9816 18.2556 26.9816 13.7742 26.9816 9.29287C26.9816 8.59807 26.8839 8.50115 26.1964 8.49979C25.4635 8.49979 24.7293 8.50661 23.9964 8.49706C23.2855 8.4875 22.8167 8.00702 22.8675 7.36409C22.9005 6.95458 23.1948 6.61469 23.6004 6.5246C23.7008 6.50139 23.8025 6.47136 23.9043 6.47273C24.9108 6.48228 25.9228 6.44679 26.9238 6.5246C28.1201 6.61879 28.9781 7.57703 28.9863 8.77143C29.0042 11.1711 28.9918 13.5709 28.9918 15.9719C28.9932 15.9719 28.9946 15.9719 28.9959 15.9719L28.9987 15.9706Z"
        fill="#B9ACFF"
      />
      <path
        d="M9.53826 13.0244C9.77476 13.0244 9.96588 13.2142 9.96588 13.4489V15.2494L11.4578 14.3608C11.6613 14.2393 11.9239 14.3048 12.0449 14.5068C12.1659 14.7089 12.1013 14.9696 11.8978 15.0897L10.3688 16.0002L11.8991 16.912C12.1026 17.0335 12.1686 17.2928 12.0463 17.4949C11.9239 17.6969 11.6626 17.7624 11.4591 17.6409L9.96588 16.7509V18.5514C9.96588 18.7862 9.77476 18.9759 9.53826 18.9759C9.30175 18.9759 9.11063 18.7862 9.11063 18.5514V16.7509L7.61875 17.6396C7.41525 17.7611 7.15262 17.6955 7.03162 17.4935C6.91062 17.2915 6.97524 17.0308 7.17874 16.9106L8.70775 16.0002L7.17737 15.0883C6.97387 14.9668 6.90787 14.7075 7.03024 14.5055C7.15262 14.3034 7.41387 14.2379 7.61737 14.3594L9.11063 15.2494V13.4489C9.11063 13.2142 9.30175 13.0244 9.53826 13.0244Z"
        fill="#917DFF"
      />
      <path
        d="M16.0158 13.0244C16.2523 13.0244 16.4434 13.2142 16.4434 13.4489V15.2494L17.9353 14.3608C18.1388 14.2393 18.4014 14.3048 18.5224 14.5068C18.6434 14.7089 18.5788 14.9696 18.3753 15.0897L16.8463 16.0002L18.3767 16.912C18.5802 17.0335 18.6462 17.2928 18.5238 17.4949C18.4014 17.6969 18.1402 17.7624 17.9367 17.6409L16.4434 16.7509V18.5514C16.4434 18.7862 16.2523 18.9759 16.0158 18.9759C15.7793 18.9759 15.5882 18.7862 15.5882 18.5514V16.7509L14.0963 17.6396C13.8928 17.7611 13.6302 17.6955 13.5092 17.4935C13.3882 17.2915 13.4528 17.0308 13.6563 16.9106L15.1853 16.0002L13.6549 15.0883C13.4514 14.9668 13.3854 14.7075 13.5078 14.5055C13.6302 14.3034 13.8914 14.2379 14.0949 14.3594L15.5882 15.2494V13.4489C15.5882 13.2142 15.7793 13.0244 16.0158 13.0244Z"
        fill="#917DFF"
      />
      <path
        d="M22.4618 13.0244C22.6983 13.0244 22.8895 13.2142 22.8895 13.4489V15.2494L24.3813 14.3608C24.5848 14.2393 24.8475 14.3048 24.9685 14.5068C25.0895 14.7089 25.0249 14.9696 24.8214 15.0897L23.2923 16.0002L24.8227 16.912C25.0262 17.0335 25.0922 17.2928 24.9699 17.4949C24.8475 17.6969 24.5862 17.7624 24.3827 17.6409L22.8895 16.7509V18.5514C22.8895 18.7862 22.6983 18.9759 22.4618 18.9759C22.2253 18.9759 22.0342 18.7862 22.0342 18.5514V16.7509L20.5423 17.6396C20.3388 17.7611 20.0762 17.6955 19.9552 17.4935C19.8342 17.2915 19.8988 17.0308 20.1023 16.9106L21.6313 16.0002L20.101 15.0883C19.8975 14.9668 19.8315 14.7075 19.9538 14.5055C20.0762 14.3034 20.3375 14.2379 20.541 14.3594L22.0342 15.2494V13.4489C22.0342 13.2142 22.2253 13.0244 22.4618 13.0244Z"
        fill="#917DFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_490_21698">
        <rect width="26" height="19.0667" fill="white" transform="translate(3 6.4668)" />
      </clipPath>
    </defs>
  </svg>
</template>
