<script setup lang="ts">
import { computed, defineProps } from 'vue';
import { CheckCircleIcon, ClockIcon, InformationCircleIcon } from '@heroicons/vue/24/outline';

type ToastStatus = 'successful' | 'failed' | 'pending';
type ToastName = 'Withdrawal' | 'Deposit';

const props = defineProps<{
  tryAgainClicked?: () => void;
  toastName: ToastName;
  status: ToastStatus;
  msg: string;
}>();

const statusIconMap = {
  successful: CheckCircleIcon,
  failed: InformationCircleIcon,
  pending: ClockIcon
};

const statusIcon = computed(() => statusIconMap[props.status!]);
</script>

<template>
  <div class="flex items-center gap-2">
    <div :data-testid="`toastIcon-${props.status}`">
      <component
        :is="statusIcon"
        class="h-5 w-5"
        :class="{
          'text-green-400': props.status === 'successful',
          'text-red-400': props.status === 'failed',
          'text-yellow-400': props.status === 'pending'
        }"
      ></component>
    </div>

    <div class="text-lg text-white">{{ props.toastName }} {{ props.status }}</div>
  </div>
  <div>
    <p class="text-charcoal-200 text-[0.67rem]">
      {{ props.msg }}
      <span
        class="text-blue-500 hover:text-blue-600 underline"
        @click="tryAgainClicked"
        v-if="props.status === 'failed'"
        >Try again</span
      >
    </p>
  </div>
</template>
