<script setup lang="ts">
import { computed, reactive, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useSessionsStore } from '@/store/sessions.store';
import { parseUnits } from 'viem';
import { useQuery } from '@tanstack/vue-query';
import InputMonetaryWithDropdown from '@/components/InputMonetaryWithDropdown.vue';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';
import ButtonRounded from '@/components/ButtonRounded.vue';
import apiClient from '@/apiClient';
import { formatUnits } from 'viem';
import { getConfigFundingChannelId } from '@/config';

const { href: iconUSDC } = new URL('@/assets/icons/USDC.svg', import.meta.url);
const emits = defineEmits<{ (e: 'onWithdraw', amount: string, token: string): void }>();

const sessions = useSessionsStore();
const { currentSession } = storeToRefs(sessions);

const { data: balance, isFetching: balanceFetching } = useQuery({
  queryKey: ['list-funding-sources', currentSession],
  queryFn: async () => {
    if (!currentSession.value?.user.accountId) {
      return [];
    }

    return apiClient.listFundingSources(currentSession.value?.user.accountId);
  },
  select(data) {
    return data.find((fs) => fs.fundingChannelId === getConfigFundingChannelId());
  }
});

const balanceFormatted = computed(() => {
  if (!balance.value || balanceFetching.value) {
    return undefined;
  }

  const formatter = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 6,
    minimumIntegerDigits: 1,
    style: 'decimal'
  });

  const majorUnits = formatUnits(BigInt(balance.value.balance), 6);

  const formattedValue = formatter.format(Number(majorUnits));

  return {
    complete: `${formattedValue} ${balance.value.balanceCurrency}`,
    symbol: balance.value.balanceCurrency,
    majorUnits,
    formattedValue
  };
});

const form = reactive({
  amount: '',
  currency: { label: 'USDC', value: 'USDC', icon: iconUSDC },
  errorMessage: ''
});

watch([() => form.amount, balanceFormatted], ([amount, bal]) => {
  form.errorMessage =
    bal?.majorUnits && Number(amount) && Number(amount) > Number(bal?.majorUnits)
      ? `Balance: ${bal.complete}`
      : '';
});

function setAmountMax() {
  if (balanceFormatted.value?.majorUnits) {
    form.amount = balanceFormatted.value.majorUnits.toString();
  }
}

function submit() {
  const amountMinor = parseUnits(form.amount, 6);
  emits('onWithdraw', amountMinor.toString(), form.currency.value);
}
</script>

<template>
  <div>
    <p class="text-3xl py-2 text-charcoal-200 select-none">Withdraw</p>
    <p class="text-charcoal-200 text-sm py-2 mt-2">
      Withdraw your funds from your Immersve account into your wallet
    </p>

    <!-- Amount -->
    <div class="mt-6">
      <label for="withdraw-amount" class="font-bold text-base">Amount</label>

      <button
        data-testid="withdraw-amount-max"
        class="text-sm text-primary-purple-100 font-semibold float-right px-2 py-1 disabled:text-charcoal-700"
        type="button"
        @click="setAmountMax"
        :disabled="!balanceFormatted?.majorUnits"
      >
        Max
      </button>

      <InputMonetaryWithDropdown
        data-testid="amount-input"
        class="mt-2"
        :dropdownOptions="[{ label: 'USDC', value: 'USDC', icon: iconUSDC }]"
        v-model:dropdown-value="form.currency"
        v-model:input-value="form.amount"
        :class="{
          'border-utility-error-red-200': form.errorMessage
        }"
      />

      <p v-if="form.errorMessage" class="text-sm text-utility-error-red-200 mt-3">
        {{ form.errorMessage }}
        <br />
        Insufficient balance
      </p>

      <p v-else class="text-sm text-charcoal-500 mt-3">
        Balance:
        <span v-if="balanceFormatted?.complete && !balanceFetching">
          {{ balanceFormatted.complete }}
        </span>

        <LoadingSpinner v-else-if="balanceFetching" class="inline-block ml-1 h-3" />
      </p>
    </div>
    <!-- Withdraw button -->
    <ButtonRounded
      type="button"
      class="w-full mt-10"
      data-testid="withdraw-button"
      @click="submit"
      :disabled="!form.amount || !!form.errorMessage"
    >
      Confirm and sign transaction
    </ButtonRounded>
  </div>
</template>
