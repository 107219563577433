export const USDC_CONTRACT_ADDRESSES: { [key: number]: string } = {
  1: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  3: '0x70cDFb73f78c51BF8a77b36c911d1F8c305d48E6',
  137: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  // 80001: '0x0fa8781a83e46826621b3bc094ea2a0212e71b23', // USDC on Mumbai testnet
  80001: '0x2FaC06acFAeB42CC3B5327fcF53F48D9Da72749d', // IMMUSDC on Mumbai testnet
  80002: '0x41e94eb019c0762f9bfcf9fb1e58725bfb0e7582' // USDC on Amoy testnet
};

export const CHAIN_ID_NAME: { [key: string]: string } = {
  '1': 'Ethereum',
  '3': 'Ethereum (Ropsten)',
  '137': 'Polygon',
  '80001': 'Polygon (Mumbai)',
  '80002': 'Polygon (Amoy)'
};

// export const MINT_DATE = 1656590400000;
export const MINT_DATE = 0;

//ISO 4217 currency decimals and symbol
export const CURRENCY_DETAILS = {
  AED: { decimals: '2', symbol: 'د.إ.' },
  AFN: { decimals: '2', symbol: '؋' },
  ALL: { decimals: '2', symbol: 'L' },
  AMD: { decimals: '2', symbol: 'դր' },
  ANG: { decimals: '2', symbol: 'ƒ' },
  AOA: { decimals: '2', symbol: 'Kz' },
  ARS: { decimals: '2', symbol: '$' },
  AUD: { decimals: '2', symbol: '$' },
  AWG: { decimals: '2', symbol: 'ƒ' },
  AZN: { decimals: '2', symbol: '₼' },
  BAM: { decimals: '2', symbol: 'КМ' },
  BBD: { decimals: '2', symbol: '$' },
  BDT: { decimals: '2', symbol: '৳' },
  BGN: { decimals: '2', symbol: 'лв.' },
  BHD: { decimals: '3', symbol: 'د.ب.' },
  BIF: { decimals: '0', symbol: 'FBu' },
  BMD: { decimals: '2', symbol: '$' },
  BND: { decimals: '2', symbol: '$' },
  BOB: { decimals: '2', symbol: 'Bs.' },
  BRL: { decimals: '2', symbol: 'R$' },
  BSD: { decimals: '2', symbol: '$' },
  BTN: { decimals: '2', symbol: 'Nu.' },
  BWP: { decimals: '2', symbol: 'P' },
  BYN: { decimals: '2', symbol: 'руб.' },
  BZD: { decimals: '2', symbol: '$' },
  CAD: { decimals: '2', symbol: '$' },
  CDF: { decimals: '2', symbol: '₣' },
  CHF: { decimals: '2', symbol: '₣' },
  CKD: { decimals: '2', symbol: '$' },
  CLP: { decimals: '0', symbol: '$' },
  CNY: { decimals: '2', symbol: '¥元' },
  COP: { decimals: '2', symbol: '$' },
  CRC: { decimals: '2', symbol: '₡' },
  CUC: { decimals: '2', symbol: '$' },
  CUP: { decimals: '2', symbol: '₱' },
  CVE: { decimals: '2', symbol: '$' },
  CZK: { decimals: '2', symbol: 'Kč' },
  DJF: { decimals: '0', symbol: 'ف.ج.' },
  DKK: { decimals: '2', symbol: 'kr.' },
  DOP: { decimals: '2', symbol: '$' },
  DZD: { decimals: '2', symbol: 'د.ج.' },
  EGP: { decimals: '2', symbol: 'ج.م.' },
  EHP: { decimals: '2', symbol: 'Ptas.' },
  ERN: { decimals: '2', symbol: 'ناكفا' },
  ETB: { decimals: '2', symbol: 'ብር' },
  EUR: { decimals: '2', symbol: '€' },
  FJD: { decimals: '2', symbol: '$' },
  FKP: { decimals: '2', symbol: '£' },
  FOK: { decimals: '2', symbol: 'kr' },
  GBP: { decimals: '2', symbol: '£' },
  GEL: { decimals: '2', symbol: '₾' },
  GGP: { decimals: '2', symbol: '£' },
  GHS: { decimals: '2', symbol: '₵' },
  GIP: { decimals: '2', symbol: '£' },
  GMD: { decimals: '2', symbol: 'D' },
  GNF: { decimals: '0', symbol: 'FG' },
  GTQ: { decimals: '2', symbol: '$' },
  GYD: { decimals: '2', symbol: '$' },
  HKD: { decimals: '2', symbol: '$' },
  HNL: { decimals: '2', symbol: 'L' },
  HRK: { decimals: '2', symbol: 'kn' },
  HTG: { decimals: '2', symbol: 'G' },
  HUF: { decimals: '2', symbol: 'Ft' },
  IDR: { decimals: '2', symbol: 'Rp' },
  ILS: { decimals: '2', symbol: '₪' },
  IMP: { decimals: '2', symbol: '£' },
  INR: { decimals: '2', symbol: '₹' },
  IQD: { decimals: '3', symbol: 'د.ع.' },
  IRR: { decimals: '2', symbol: '﷼' },
  ISK: { decimals: '0', symbol: 'kr' },
  JEP: { decimals: '2', symbol: '£' },
  JMD: { decimals: '2', symbol: '$' },
  JOD: { decimals: '3', symbol: 'د.أ.' },
  JPY: { decimals: '0', symbol: '¥' },
  KES: { decimals: '2', symbol: 'KSh' },
  KGS: { decimals: '2', symbol: 'с' },
  KHR: { decimals: '2', symbol: '៛' },
  KID: { decimals: '2', symbol: '$' },
  KMF: { decimals: '0', symbol: 'CF' },
  KPW: { decimals: '2', symbol: '₩' },
  KRW: { decimals: '0', symbol: '₩' },
  KWD: { decimals: '3', symbol: 'د.ك.' },
  KYD: { decimals: '2', symbol: '$' },
  KZT: { decimals: '2', symbol: '₸' },
  LAK: { decimals: '2', symbol: '₭' },
  LBP: { decimals: '2', symbol: 'ل.ل.' },
  LKR: { decimals: '2', symbol: 'රු' },
  LRD: { decimals: '2', symbol: '$' },
  LSL: { decimals: '2', symbol: 'L' },
  LYD: { decimals: '3', symbol: 'ل.د.' },
  MAD: { decimals: '2', symbol: 'د.م.' },
  MDL: { decimals: '2', symbol: 'L' },
  MGA: { decimals: '2', symbol: 'Ar' },
  MKD: { decimals: '2', symbol: 'ден' },
  MMK: { decimals: '2', symbol: 'Ks' },
  MNT: { decimals: '2', symbol: '₮' },
  MOP: { decimals: '2', symbol: 'MOP$' },
  MRU: { decimals: '2', symbol: 'أ.م.' },
  MUR: { decimals: '2', symbol: 'रु ' },
  MVR: { decimals: '2', symbol: '.ރ' },
  MWK: { decimals: '2', symbol: 'MK' },
  MXN: { decimals: '2', symbol: '$' },
  MYR: { decimals: '2', symbol: 'RM' },
  MZN: { decimals: '2', symbol: 'MT' },
  NAD: { decimals: '2', symbol: '$' },
  NGN: { decimals: '2', symbol: '₦' },
  NIO: { decimals: '2', symbol: 'C$' },
  NOK: { decimals: '2', symbol: 'kr' },
  NPR: { decimals: '2', symbol: 'रू' },
  NZD: { decimals: '2', symbol: '$' },
  OMR: { decimals: '3', symbol: 'ر.ع.' },
  PAB: { decimals: '2', symbol: 'B/.' },
  PEN: { decimals: '2', symbol: 'S/.' },
  PGK: { decimals: '2', symbol: 'K' },
  PHP: { decimals: '2', symbol: '₱' },
  PKR: { decimals: '2', symbol: 'Rs' },
  PLN: { decimals: '2', symbol: 'zł' },
  PND: { decimals: '2', symbol: '$' },
  PRB: { decimals: '2', symbol: 'р.' },
  PYG: { decimals: '0', symbol: '₲' },
  QAR: { decimals: '2', symbol: 'ر.ق.' },
  RON: { decimals: '2', symbol: 'L' },
  RSD: { decimals: '2', symbol: 'дин' },
  RUB: { decimals: '2', symbol: '₽' },
  RWF: { decimals: '0', symbol: 'R₣' },
  SAR: { decimals: '2', symbol: 'ر.س.' },
  SBD: { decimals: '2', symbol: '$' },
  SCR: { decimals: '2', symbol: 'Rs' },
  SDG: { decimals: '2', symbol: 'ج.س.' },
  SEK: { decimals: '2', symbol: 'kr' },
  SGD: { decimals: '2', symbol: '$' },
  SHP: { decimals: '2', symbol: '£' },
  SLL: { decimals: '2', symbol: 'Le' },
  SLE: { decimals: '2', symbol: 'Le' },
  SLS: { decimals: '2', symbol: 'Sl' },
  SOS: { decimals: '2', symbol: 'Ssh' },
  SRD: { decimals: '2', symbol: '$' },
  SSP: { decimals: '2', symbol: 'SS£' },
  STN: { decimals: '2', symbol: 'Db' },
  SVC: { decimals: '2', symbol: '₡' },
  SYP: { decimals: '2', symbol: 'ل.س.' },
  SZL: { decimals: '2', symbol: 'L' },
  THB: { decimals: '2', symbol: '฿' },
  TJS: { decimals: '2', symbol: 'SM' },
  TMT: { decimals: '2', symbol: 'T' },
  TND: { decimals: '3', symbol: 'د.ت.' },
  TOP: { decimals: '2', symbol: 'PT' },
  TRY: { decimals: '2', symbol: '₺' },
  TTD: { decimals: '2', symbol: '$' },
  TVD: { decimals: '2', symbol: '$' },
  TWD: { decimals: '2', symbol: '圓' },
  TZS: { decimals: '2', symbol: 'TSh' },
  UAH: { decimals: '2', symbol: 'грн' },
  UGX: { decimals: '0', symbol: 'Sh' },
  USD: { decimals: '2', symbol: '$' },
  UYU: { decimals: '2', symbol: '$' },
  UZS: { decimals: '2', symbol: 'сум' },
  VED: { decimals: '2', symbol: 'Bs.' },
  VES: { decimals: '2', symbol: 'Bs.F' },
  VND: { decimals: '0', symbol: '₫' },
  VUV: { decimals: '0', symbol: 'VT' },
  WST: { decimals: '2', symbol: 'ST' },
  XAF: { decimals: '0', symbol: 'Fr.' },
  XCD: { decimals: '2', symbol: '$' },
  XOF: { decimals: '0', symbol: '₣' },
  XPF: { decimals: '0', symbol: '₣' },
  YER: { decimals: '2', symbol: 'ر.ي.' },
  ZAR: { decimals: '2', symbol: 'R' },
  ZMW: { decimals: '2', symbol: 'ZK' },
  ZWB: { decimals: '0', symbol: '' },
  ZWL: { decimals: '2', symbol: '$' },
  Abkhazia: { decimals: '0', symbol: '' },
  Artsakh: { decimals: '2', symbol: 'դր.' },
  USDC: { decimals: '6', symbol: '' }
};

export const toastStyle = {
  background: '#20315C',
  borderRadius: '1rem',
  width: '100%',
  display: 'flex'
};
