<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.56105e-06 3.97005C2.56105e-06 6.92342 -0.0351562 9.31425 8.13349 12.2325C6.56305 10.5302 6.33451 8.25361 6.33451 5.89209L6.314 0H0.0175821L2.56105e-06 3.97005Z"
      fill="#FC34FF"
    />
    <path
      d="M27.7321 39.2493H21.4357V25.127C21.4357 22.7538 21.1896 20.489 19.6103 18.7867C27.779 21.7049 27.7262 24.0957 27.7262 27.052L27.735 39.2493H27.7321ZM39.2262 33.577C39.2262 30.6236 39.2614 28.2328 31.0927 25.3116C32.6632 27.0139 32.9181 29.2905 32.9181 31.652V39.2376H39.2321L39.2233 33.574L39.2262 33.577ZM16.2585 20.4978C16.2585 17.5444 16.2936 15.1536 8.125 12.2324C9.69544 13.9347 9.94156 16.2113 9.94156 18.5728V39.2376H16.2556V20.4948L16.2585 20.4978Z"
      fill="url(#paint0_linear_1_45)"
    />
    <path
      d="M27.7321 39.2493H21.4357V25.127C21.4357 22.7538 21.1896 20.489 19.6103 18.7867C27.779 21.7049 27.7262 24.0957 27.7262 27.052L27.735 39.2493H27.7321ZM39.2262 33.577C39.2262 30.6236 39.2614 28.2328 31.0927 25.3116C32.6632 27.0139 32.9181 29.2905 32.9181 31.652V39.2376H39.2321L39.2233 33.574L39.2262 33.577ZM16.2585 20.4978C16.2585 17.5444 16.2936 15.1536 8.125 12.2324C9.69544 13.9347 9.94156 16.2113 9.94156 18.5728V39.2376H16.2556V20.4948L16.2585 20.4978Z"
      fill="url(#paint1_linear_1_45)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_45"
        x1="10.3488"
        y1="13.0059"
        x2="31.5381"
        y2="45.9002"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FC34FF" />
        <stop offset="0.08" stop-color="#F434FF" />
        <stop offset="0.2" stop-color="#E134FF" />
        <stop offset="0.34" stop-color="#C134FF" />
        <stop offset="0.43" stop-color="#A834FF" />
        <stop offset="0.51" stop-color="#9434FF" />
        <stop offset="0.59" stop-color="#7B34FF" />
        <stop offset="0.7" stop-color="#5434FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_45"
        x1="13.4458"
        y1="36.9698"
        x2="36.3139"
        y2="11.8633"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.4" stop-color="#4BA5FF" stop-opacity="0" />
        <stop offset="0.43" stop-color="#4BA5FF" stop-opacity="0.03" />
        <stop offset="0.47" stop-color="#4BA5FF" stop-opacity="0.1" />
        <stop offset="0.51" stop-color="#4BA5FF" stop-opacity="0.23" />
        <stop offset="0.57" stop-color="#4BA5FF" stop-opacity="0.4" />
        <stop offset="0.62" stop-color="#4BA5FF" stop-opacity="0.63" />
        <stop offset="0.69" stop-color="#4BA5FF" stop-opacity="0.9" />
        <stop offset="0.71" stop-color="#4BA5FF" />
      </linearGradient>
    </defs>
  </svg>
</template>
