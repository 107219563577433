<script setup lang="ts">
import { watchEffect } from 'vue';
import { useUiStore } from '@/store/ui.store';
import ButtonHamburger from '@/components/ButtonHamburger.vue';
import MarketingBar from '@/components/MarketingBar.vue';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { useSessionsStore } from '@/store/sessions.store';
import ProfileDropDown from '@/components/ProfileDropDown.vue';
import { useLink } from 'vue-router';
import { disconnect } from '@wagmi/core';
import apiClient from '@/apiClient';

const immersveLogo = new URL('@/assets/icons/ImmersveLogo.svg', import.meta.url).href;
const immersveIcon = new URL('@/assets/icons/ImmersveIcon.svg', import.meta.url).href;

const sessionsState = useSessionsStore();
const uiState = useUiStore();
const breakpoints = useBreakpoints(breakpointsTailwind);
const isMdOrUp = breakpoints.greaterOrEqual('md');

const linkToLogin = useLink({ to: '/login' });

async function logout() {
  apiClient.logout().catch(() => {});
  await disconnect().catch(() => {
    // eslint-disable-next-line no-console
    console.error('Disconnect failed');
  });
  sessionsState.clear();
  localStorage.removeItem('currentAccount');
  linkToLogin.navigate();
}
watchEffect(() => {
  if (uiState.sideNavOpen && isMdOrUp.value) {
    uiState.toggleSideNavOpen(false);
  }
});
</script>

<template>
  <header
    class="px-4 py-5 transition-all"
    :class="{
      'md:ml-24': uiState.sideNavCollapsed,
      'md:ml-60': !uiState.sideNavCollapsed
    }"
  >
    <div class="flex items-center">
      <ButtonHamburger @click="uiState.toggleSideNavOpen" class="-m-2 md:hidden" />

      <!-- Logo container -->
      <div class="ml-4 md:hidden">
        <img class="h-9 sm:hidden" :src="immersveIcon" alt="Immersve logo" />
        <img class="hidden h-9 sm:block md:hidden" :src="immersveLogo" alt="Immersve logo" />
      </div>

      <!-- Spend your crypto bar for medium screens -->
      <MarketingBar v-if="isMdOrUp && uiState.marketingBarVisible" class="mr-6 hidden md:flex" />

      <ProfileDropDown :current-account="sessionsState.currentAccount" @logout="logout" />
    </div>
    <!-- Spend your crypto bar for small screens -->
    <MarketingBar v-if="uiState.marketingBarVisible" class="mt-6 hidden sm:flex md:hidden" />
  </header>
</template>
