<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.0304 1.46967C22.3233 1.76256 22.3233 2.23744 22.0304 2.53033L20.5607 4L23.0304 6.46967C23.3233 6.76256 23.3233 7.23744 23.0304 7.53033L19.5304 11.0303C19.2375 11.3232 18.7626 11.3232 18.4697 11.0303L16 8.56066L12.9219 11.6388C13.2758 12.0845 13.5679 12.5774 13.7892 13.1043C14.1085 13.8642 14.2743 14.6798 14.2771 15.5041C14.2798 16.3284 14.1195 17.1451 13.8053 17.9072C13.4912 18.6692 13.0294 19.3616 12.4465 19.9445C11.8636 20.5273 11.1713 20.9891 10.4092 21.3033C9.64712 21.6175 8.83043 21.7778 8.00615 21.775C7.18187 21.7723 6.36628 21.6065 5.60633 21.2872C4.84638 20.968 4.1571 20.5015 3.57816 19.9148L3.57253 19.9091C2.43405 18.7303 1.80411 17.1515 1.81835 15.5128C1.83259 13.874 2.4899 12.3065 3.6487 11.1477C4.8075 9.98887 6.37508 9.33156 8.01381 9.31732C9.40471 9.30523 10.7524 9.75724 11.849 10.5904L20.9697 1.46967C21.2626 1.17678 21.7375 1.17678 22.0304 1.46967ZM11.3937 12.1742C11.385 12.1665 11.3764 12.1586 11.368 12.1505C10.4721 11.2852 9.27228 10.8064 8.02684 10.8173C6.78141 10.8281 5.59005 11.3276 4.70936 12.2083C3.82867 13.089 3.32912 14.2804 3.31829 15.5258C3.30748 16.7699 3.78521 17.9685 4.6487 18.8641C5.08811 19.3087 5.61098 19.6622 6.18731 19.9043C6.76487 20.1469 7.38472 20.2729 8.01118 20.275C8.63763 20.2771 9.25831 20.1553 9.83748 19.9165C10.4167 19.6778 10.9429 19.3268 11.3858 18.8838C11.8288 18.4408 12.1798 17.9146 12.4186 17.3354C12.6573 16.7563 12.7792 16.1356 12.7771 15.5091C12.775 14.8827 12.649 14.2628 12.4063 13.6853C12.1692 13.1209 11.8254 12.6079 11.3937 12.1742ZM17.0607 7.5L19 9.43934L21.4394 7L19.5 5.06066L17.0607 7.5Z"
      fill="#071122"
    />
  </svg>
</template>
