<script setup lang="ts">
import { Card } from '@/models/Card';
import ImmersveCardUnbrandedSecureDetails from '@/components/ImmersveCardUnbrandedSecureDetails.vue';
import ImmersveCardUnbranded from '@/components/ImmersveCardUnbranded.vue';
import KeyIcon from '@/components/icons/KeyIcon.vue';
import RevealIcon from '@/components/icons/RevealIcon.vue';
import HideIcon from '@/components/icons/HideIcon.vue';
import Pan4Badge from '@/components/Pan4Badge.vue';
import CardStatusBadge from '@/components/CardStatusBadge.vue';
import ButtonVertical from '@/components/ButtonVertical.vue';
import { useMutation } from '@tanstack/vue-query';
import apiClient from '@/apiClient';
import { CardSecureDetails } from '@/apiClient/types/card';
import { reactive } from 'vue';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';
import ButtonManage from '@/components/ButtonManage.vue';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { toast } from 'vue3-toastify';

const breakpoints = useBreakpoints(breakpointsTailwind);
const isSmOrUp = breakpoints.greaterOrEqual('sm');

const props = defineProps<{
  card: Card;
}>();

async function onReveal() {
  if (!secure.secureData) {
    secure.secureData = await fetchSecureDetail();
  }
  secure.showSecureDetails = true;
}

function onHide() {
  secure.showSecureDetails = false;
}

const secure = reactive({
  showSecureDetails: false,
  secureData: null as CardSecureDetails | null
});
defineExpose({
  secure
});
const { mutateAsync: fetchSecureDetail, isLoading: isSecureCardLoading } = useMutation({
  mutationFn: async () => {
    const panToken = await apiClient.createCardPanToken(props.card.id);
    const secureDetail = await apiClient.getCardSecureDetails(panToken.callbackUrl);
    return secureDetail;
  },
  onError(error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return toast.error('Something went wrong. Please try again later');
  }
});

defineEmits<{
  (e: 'onCancelClicked', cardId: string): void;
  (e: 'onSetPinClicked', cardId: string): void;
}>();

function enableSetPin() {
  return props.card.status === 'active' && !props.card.isExpired && !props.card.isBlocked;
}

function enableReveal() {
  return props.card.status !== 'cancelled' && !props.card.isExpired && !props.card.isBlocked;
}

function enableCardManage() {
  return props.card.status !== 'cancelled' && !props.card.isExpired;
}
</script>

<template>
  <div
    data-testid="card-manage-widget-header"
    class="flex h-12 p-8 justify-between items-center bg-secondary-blue-1000 w-full md:w-10/12 border-b border-divider-transparent-white rounded-t-2xl"
  >
    <Pan4Badge class="text-base font-semibold" :pan-last4="card.panLast4"></Pan4Badge>
    <CardStatusBadge :card="card" />
    <div
      data-testid="card-manage-widget-header-manage-button-container"
      class="flex"
      :class="{
        collapse: !enableCardManage()
      }"
    >
      <ButtonManage
        :is-expand="isSmOrUp"
        @on-cancel-clicked="$emit('onCancelClicked', props.card.id)"
      />
    </div>
  </div>
  <div
    class="flex flex-col p-8 gap-4 justify-center items-center bg-secondary-blue-1000 w-full md:w-10/12 rounded-b-2xl"
  >
    <ImmersveCardUnbranded
      v-if="!secure.showSecureDetails"
      :card="card"
      :hide-remaining-time="true"
    />
    <ImmersveCardUnbrandedSecureDetails
      v-if="secure.showSecureDetails && secure.secureData"
      :secure-details="secure.secureData"
    />

    <div class="gap-3 flex w-56 justify-center">
      <div v-if="enableSetPin()" class="flex basis-24 flex-shrink-0 flex-grow-0">
        <ButtonVertical text="Set Pin" @click="$emit('onSetPinClicked', props.card.id)">
          <KeyIcon />
        </ButtonVertical>
      </div>
      <div v-if="enableReveal()" class="flex basis-24 flex-shrink-0 flex-grow-0 justify-center">
        <ButtonVertical
          data-testid="reveal-secure-card-details-button"
          :disabled="isSecureCardLoading"
          v-if="!secure.showSecureDetails"
          :text="secure.showSecureDetails ? 'Hide' : 'Reveal'"
          @click="onReveal"
        >
          <LoadingSpinner v-if="isSecureCardLoading"></LoadingSpinner>
          <RevealIcon v-else />
        </ButtonVertical>
        <ButtonVertical
          data-testid="hide-secure-card-details-button"
          v-else
          text="Hide"
          @click="onHide"
        >
          <HideIcon />
        </ButtonVertical>
      </div>
    </div>
  </div>
</template>
