<script setup lang="ts">
import { CardSecureDetails } from '@/apiClient/types/card';
import ImmersveCardUnbrandedSecureDetailBackground from '@/components/ImmersveCardUnbrandedSecureDetailBackground.vue';
import CopyFieldWidget from '@/components/CopyFieldWidget.vue';
import { reactive } from 'vue';
import { useClipboard } from '@vueuse/core';
import { formatPan, formatExpiry } from '@/utils/formatString';

const clipboard = useClipboard({
  read: true,
  legacy: true
});

defineProps<{
  secureDetails: CardSecureDetails;
}>();

type secureDetailsCopyState = {
  [key in keyof CardSecureDetails]: boolean;
};
const copiedState = reactive<secureDetailsCopyState>({
  pan: false,
  embossedName: false,
  expiry: false,
  cvv2: false
});

function onCopyClicked(
  filed: keyof CardSecureDetails,
  value: CardSecureDetails[keyof CardSecureDetails]
) {
  copiedState[filed] = true;
  clipboard.copy(value);
}

const tooltips = {
  pan: 'copy card number',
  embossedName: 'copy name',
  expiry: 'copy expiry date',
  cvv2: 'copy CVC'
};

const getTooltips = (filed: keyof CardSecureDetails) => {
  if (copiedState[filed]) {
    return 'copied';
  }

  return tooltips[filed];
};
</script>

<template>
  <ImmersveCardUnbrandedSecureDetailBackground v-if="clipboard.isSupported">
    <div class="flex h-full w-full flex-col relative p-2">
      <div class="flex flex-grow"></div>
      <div class="mb-2 w-full">
        <CopyFieldWidget
          title="CARD NUMBER"
          :value="formatPan(secureDetails.pan)"
          :tooltips="getTooltips('pan')"
          highlight
          @on-copy-click="onCopyClicked('pan', secureDetails.pan)"
        />
      </div>

      <div class="mb-2 w-full">
        <CopyFieldWidget
          title="NAME"
          :value="secureDetails.embossedName"
          :tooltips="getTooltips('embossedName')"
          @on-copy-click="onCopyClicked('embossedName', secureDetails.embossedName)"
        />
      </div>

      <div class="flex w-full">
        <div class="mr-1 flex-1">
          <CopyFieldWidget
            title="VALID THRU"
            :value="formatExpiry(secureDetails.expiry, 'short-year')"
            :tooltips="getTooltips('expiry')"
            @on-copy-click="onCopyClicked('expiry', secureDetails.expiry)"
          />
        </div>

        <div class="ml-1 flex-1">
          <CopyFieldWidget
            title="CVC"
            :value="secureDetails.cvv2"
            :tooltips="getTooltips('cvv2')"
            @on-copy-click="onCopyClicked('cvv2', secureDetails.cvv2)"
          />
        </div>
      </div>
    </div>
  </ImmersveCardUnbrandedSecureDetailBackground>
</template>
